import { NgModule,  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SatPopoverModule } from '@zerops/fe/popover';
import { MatCardModule } from '@angular/material/card';
import { FilterPopPopoverComponent } from './filter-pop-popover.component';

@NgModule({
  declarations: [ FilterPopPopoverComponent ],
  imports: [
    CommonModule,
    SatPopoverModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule
  ],
  exports: [ FilterPopPopoverComponent ]
})
export class FilterPopSatPopoverModule {

}
