import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  map,
  switchMap,
  withLatestFrom,
  catchError,
  filter,
  take
} from 'rxjs/operators';
import { of } from 'rxjs';
import omit from 'lodash-es/omit';
import { RemoveError } from '@zerops/fe/ngrx';
import { DialogOpen } from '@zerops/fe/dialog';
import { State } from '@app/models';
import {
  AuthorizedRunEffect,
  authActiveUserClientId
} from '@app/base/auth-base';
import { SnackService } from '@app/common/snack';
import { DomainRoutingInfoDialogKey } from '@app/common/domain-routing-info-dialog';
import { ErrorTranslationService } from '@app/services';
import { ofWsType } from '@app/common/websockets';
import { CloudDnsWebsocketActionTypes } from '@app/common/clouddns-websockets';
import { DomainsBaseApi } from './domains-base.api';
import {
  ListRequest,
  ListLocalSuccess,
  ListFail,
  ActionTypes,
  EntityRequest,
  EntityFail,
  EntityLocalSuccess,
  PublishRequest,
  PublishLocalSuccess,
  PublishFail,
  ResetChangesRequest,
  ResetChangesLocalSuccess,
  ResetChangesFail,
  AddRequest,
  AddLocalSuccess,
  AddFail,
  RestoreRequest,
  DeleteLocalSuccess,
  DeleteFail,
  RestoreFail,
  DeleteRequest,
  RestoreLocalSuccess,
  DeactivateRequest,
  DeactivateLocalSuccess,
  DeactivateFail,
  ActivateRequest,
  ActivateLocalSuccess,
  ActivateFail,
  SoaRequest,
  SoaRequestLocalSuccess,
  SoaRequestFail,
  SoaUpdateRequest,
  SoaUpdateLocalSuccess,
  SoaUpdateFail,
  DnssecKeyRequest,
  DnssecKeyLocalSuccess,
  DnssecKeyFail
} from './domains-base.action';
import { getDomainEntityById } from './domains-base.selector';
import { DomainStatuses } from './domains-base.constant';

@Injectable()
export class DomainsBaseEffect extends AuthorizedRunEffect {

  // list request
  private _onListRequest$ = createEffect(() => this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, clientId ]) => this._api
      .list$(clientId)
      .pipe(
        map((response) => new ListLocalSuccess(response.items)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ListFail(data)))
        )
      )
    )
  ));

  // entity request
  private _onEntityRequest$ = this._actions$.pipe(
    ofType<EntityRequest>(ActionTypes.EntityRequest)
  );

  private _onDetailRequest$ = createEffect(() => this._onEntityRequest$.pipe(
    switchMap((({ payload }) => this._api
      .entity$(payload)
      .pipe(
        map((response) => new EntityLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new EntityFail(data, payload)))
        )
      )
    ))
  ));

  private _onEntityRequestRegisterSearch$ = createEffect(() => this._onEntityRequest$.pipe(
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ { payload }, clientId ]) => this._api
      .registerSearchForEntity$(payload, clientId)
      .pipe(
        map(() => of(undefined)),
        catchError(() => of(undefined))
      )
    )
  ), { dispatch: false });

  // add request
  private _onAddRequest$ = createEffect(() => this._actions$.pipe(
    ofType<AddRequest>(ActionTypes.AddRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap((([ { payload }, clientId ]) => this._api
      .add$(this._normalizeDomainNamePayload(payload), clientId)
      .pipe(
        map((response) => new AddLocalSuccess(response)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new AddFail(data)))
        )
      )
    ))
  ));

  // publish request
  private _onPublishRequest$ = createEffect(() => this._actions$.pipe(
    ofType<PublishRequest>(ActionTypes.PublishRequest),
    map(({ payload, meta }) => ({ payload, meta })),
    switchMap(({ payload, meta }) => this._store.pipe(select(getDomainEntityById(payload.id))).pipe(
      take(1),
      map((domain) => ({ payload, meta, domain }))
    )),
    switchMap((({ payload, meta, domain }) => this._api
      .publish$(payload.id, payload.soaTtl)
      .pipe(
        map((response) => new PublishLocalSuccess(response, meta, domain.status, domain.domainName)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new PublishFail(data)))
        )
      )
    ))
  ));

  private _onFirstPublishRequestOpenDomainRoutingInfoDialog$ = createEffect(() => this._actions$.pipe(
    ofType<PublishLocalSuccess>(ActionTypes.PublishLocalSuccess),
    filter(({ originalDomainStatus }) => originalDomainStatus === DomainStatuses.New),
    map(({ originalDomainName }) => new DialogOpen(DomainRoutingInfoDialogKey, originalDomainName))
  ));

  // reset changes request
  private _onResetChangesRequest$ = createEffect(() => this._actions$.pipe(
    ofType<ResetChangesRequest>(ActionTypes.ResetChangesRequest),
    map(({ payload }) => payload),
    switchMap(((id) => this._api
      .resetChanges$(id)
      .pipe(
        map((response) => new ResetChangesLocalSuccess(response)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ResetChangesFail(data)))
        )
      )
    ))
  ));

  // reset changes fail snack
  private _onResetChangesFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<ResetChangesFail>(ActionTypes.ResetChangesFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ResetChangesFail))
  ));

  // delete
  private _onDeleteRequest$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteRequest>(ActionTypes.DeleteRequest),
    map((action) => action.payload),
    switchMap((id) => this._api
      .delete$(id)
      .pipe(
        map(() => new DeleteLocalSuccess(id)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new DeleteFail(errData, id)))
        )
      )
    )
  ));

  // delete fail snack
  private _onDeleteFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteFail>(ActionTypes.DeleteFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.DeleteFail))
  ));

  // delete success snack
  private _onDeleteSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteLocalSuccess>(ActionTypes.DeleteLocalSuccess),
    switchMap(() => this._snack.translatedWarning$(
      'domainsBase.deleteSuccessSnack',
      'common.close'
    ))
  ), { dispatch: false });

  // restore request
  private _onRestoreRequest$ = createEffect(() => this._actions$.pipe(
    ofType<RestoreRequest>(ActionTypes.RestoreRequest),
    map((action) => action.payload),
    switchMap((id) => this._api
      .restore$(id)
      .pipe(
        map(() => new RestoreLocalSuccess(id)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new RestoreFail(errData, id)))
        )
      )
    )
  ));

  // restore fail
  private _onRestoreFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<RestoreFail>(ActionTypes.RestoreFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.RestoreFail))
  ));

  // restore success snack
  private _onRestoreSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<RestoreLocalSuccess>(ActionTypes.RestoreLocalSuccess),
    switchMap(() => this._snack.translatedSuccess$(
      'domainsBase.restoreSuccessSnack',
      'common.close'
    ))
  ), { dispatch: false });

  // deactivate request
  private _onDeactivateRequest$ = createEffect(() => this._actions$.pipe(
    ofType<DeactivateRequest>(ActionTypes.DeactivateRequest),
    map((action) => action.payload),
    switchMap((id) => this._api
      .deactivate$(id)
      .pipe(
        map(() => new DeactivateLocalSuccess(id)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new DeactivateFail(errData, id)))
        )
      )
    )
  ));

  // deactivate fail
  private _onDeactivateFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DeactivateFail>(ActionTypes.DeactivateFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.DeactivateFail))
  ));

  // deactivate success snack
  private _onDeactivateSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DeactivateLocalSuccess>(ActionTypes.DeactivateLocalSuccess),
    switchMap(() => this._snack.translatedWarning$(
      'domainsBase.deactivateDomainSuccessSnack',
      'common.close'
    ))
  ), { dispatch: false });

  // activate
  private _onActivateRequest$ = createEffect(() => this._actions$.pipe(
    ofType<ActivateRequest>(ActionTypes.ActivateRequest),
    map((action) => action.payload),
    switchMap((id) => this._api
      .activate$(id)
      .pipe(
        map(() => new ActivateLocalSuccess(id)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new ActivateFail(errData, id)))
        )
      )
    )
  ));

  // activate fail snack
  private _onActivateFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<ActivateFail>(ActionTypes.ActivateFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.ActivateFail))
  ));

  // activate success snack
  private _onActivateSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<ActivateLocalSuccess>(ActionTypes.ActivateLocalSuccess),
    switchMap(() => this._snack.translatedSuccess$(
      'domainsBase.activateDomainSuccessSnack',
      'common.close'
    ))
  ), { dispatch: false });

  // soa request
  private _onSoaRequest$ = createEffect(() => this._actions$.pipe(
    ofType<SoaRequest>(ActionTypes.SoaRequest),
    map((action) => action.payload),
    switchMap((id) => this._api
      .soaRequest$(id)
      .pipe(
        map((response) => new SoaRequestLocalSuccess(response)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new SoaRequestFail(errData, id)))
        )
      )
    )
  ));

  // soa update request
  private _onSoaUpdateRequest$ = createEffect(() => this._actions$.pipe(
    ofType<SoaUpdateRequest>(ActionTypes.SoaUpdateRequest),
    map((action) => action.payload),
    switchMap((payload) => this._api
      .soaUpdate$(payload.id, omit(payload, 'id'))
      .pipe(
        map((response) => new SoaUpdateLocalSuccess(response)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new SoaUpdateFail(errData)))
        )
      )
    )
  ));

  // websocket update
  private _websocketUpdate$ = createEffect(() => this._actions$.pipe(
    ofWsType('search', CloudDnsWebsocketActionTypes.Message),
    map((data) => new EntityLocalSuccess(data.items[0]))
  ));

  // -- dnssec key
  private _onDnssecKeyRequest$ = createEffect(() => this._actions$.pipe(
    ofType<DnssecKeyRequest>(ActionTypes.DnssecKeyRequest),
    switchMap(({ payload }) => this._api
      .getDnssecKey$(payload)
      .pipe(
        map((response) => new DnssecKeyLocalSuccess(response.cryptokey[0], payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new DnssecKeyFail(errData, payload)))
        )
      )
    )
  ));

  private _onDnssecKeyFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DnssecKeyFail>(ActionTypes.DnssecKeyFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.DnssecKeyFail))
  ));

  private _normalizeDomainNamePayload({ domainName }: { domainName: string; }) {
    domainName = domainName.endsWith('.')
      ? domainName
      : `${domainName}.`;

    return { domainName };
  }

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: DomainsBaseApi,
    private _errorTranslation: ErrorTranslationService,
    private _snack: SnackService
  ) {
    super(_actions$, _store);
  }
}
