import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RemoveError } from '@zerops/fe/ngrx';
import { SnackService } from '@app/common/snack';
import {
  switchMap,
  map,
  catchError,
  filter,
  first
} from 'rxjs/operators';
import omit from 'lodash-es/omit';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import { DomainRecordBaseApi } from './domain-record-base.api';
import { getDomainEntityById } from './domains-base.selector';
import {
  ActionTypes,
  AddRequest,
  AddFail,
  AddLocalSuccess,
  EntityRequest,
  EntityLocalSuccess,
  EntityFail,
  UpdateRequest,
  UpdateLocalSuccess,
  UpdateFail,
  DeleteRequest,
  DeleteLocalSuccess,
  DeleteFail,
  RestoreRequest,
  RestoreLocalSuccess,
  RestoreFail
} from './domain-record-base.action';
import { DomainEntity } from './domains-base.model';

@Injectable()
export class DomainRecordBaseEffect {

  private _onAddRequest$ = createEffect(() => this._actions$.pipe(
    ofType<AddRequest>(ActionTypes.AddRequest),
    map((action) => action.payload),
    switchMap(({ type, domainId, data }) => this._store.pipe(
      select(
        getDomainEntityById(domainId)),
        filter((d) => !!d),
        first(),
        map((domain) => ({ domain, type, data }))
      )
    ),
    switchMap(({ type, domain, data }) => this._api
      .add$(
        type,
        domain.id,
        this._normalizeFormData(data, domain)
      )
      .pipe(
        map((result) => new AddLocalSuccess(result.id)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new AddFail(errData)))
        )
      )
    )
  ));

  private _onUpdateRequest$ = createEffect(() => this._actions$.pipe(
    ofType<UpdateRequest>(ActionTypes.UpdateRequest),
    map((action) => action.payload),
    switchMap(({ type, id, domainId, data }) => this._store.pipe(
      select(
        getDomainEntityById(domainId)),
        filter((d) => !!d),
        first(),
        map((domain) => ({ domain, id, type, data }))
      )
    ),
    switchMap(({ type, id, data, domain }) => {
      return this._api
        .update$(type, id, this._normalizeFormData(data, domain))
        .pipe(
          map(() => new UpdateLocalSuccess(id)),
          catchError((err) => this._errorTranslation
            .get$(err)
            .pipe(map((errData) => new UpdateFail(errData)))
          )
        );
    })
  ));

  // delete request
  private _onDeleteRequest$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteRequest>(ActionTypes.DeleteRequest),
    map((action) => action.payload),
    switchMap((id) => {
      return this._api
        .delete$(id)
        .pipe(
          map(() => new DeleteLocalSuccess(id)),
          catchError((err) => this._errorTranslation
            .get$(err)
            .pipe(map((errData) => new DeleteFail(errData, id)))
          )
        );
    })
  ));

  // delete fail snack
  private _onDeleteFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteFail>(ActionTypes.DeleteFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.DeleteFail))
  ));

  // delete success snack
  private _onDeleteSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteLocalSuccess>(ActionTypes.DeleteLocalSuccess),
    switchMap(() => this._snack.translatedWarning$(
      'domainsBase.deleteRecordSuccessSnack',
      'common.close'
    ))
  ), { dispatch: false });

  // restore request
  private _onRestoreRequest$ = createEffect(() => this._actions$.pipe(
    ofType<RestoreRequest>(ActionTypes.RestoreRequest),
    map((action) => action.payload),
    switchMap((id) => {
      return this._api
        .restore$(id)
        .pipe(
          map(() => new RestoreLocalSuccess(id)),
          catchError((err) => this._errorTranslation
            .get$(err)
            .pipe(map((errData) => new RestoreFail(errData, id)))
          )
        );
    })
  ));

  // restore fail snack
  private _onRestoreFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<RestoreFail>(ActionTypes.RestoreFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.RestoreFail))
  ));

  // restore success snack
  private _onRestoreSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<RestoreLocalSuccess>(ActionTypes.RestoreLocalSuccess),
    switchMap(() => this._snack.translatedSuccess$(
      'domainsBase.restoreRecordSuccessSnack',
      'common.close'
    ))
  ), { dispatch: false });

  // entity request
  private _onEntityRequest$ = createEffect(() => this._actions$.pipe(
    ofType<EntityRequest>(ActionTypes.EntityRequest),
    switchMap(({ payload }) => this._api
      .entity$(payload.id, payload.type)
      .pipe(
        map((data) => new EntityLocalSuccess(data)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((errData) => new EntityFail(errData, payload.id)))
        )
      )
    )
  ));

  // data.name is filled only if we are adding a subdomain
  // otherwise we can use domain name
  private _normalizeFormData(
    // TODO: shared interface with form payload base
    formData: { name: string; subdomain: boolean; },
    domain: DomainEntity
  ) {

    const trimmedName = formData.name ? formData.name.trim() : undefined;

    return {
      ...omit(formData, 'subdomain'),
      name: trimmedName
        ? `${trimmedName.endsWith('.') ? trimmedName : (trimmedName + '.')}${domain.domainName}`
        : domain.domainName
    };
  }

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: DomainRecordBaseApi,
    private _errorTranslation: ErrorTranslationService,
    private _snack: SnackService
  ) { }

}
