import {
  createFormGroupState,
  FormGroupState,
  SetValueAction,
  updateGroup,
  StateUpdateFns,
  createFormStateReducerWithUpdate,
  markAsSubmitted,
  markAsUnsubmitted,
  formStateReducer
} from 'ngrx-forms';
import { Action } from '@ngrx/store';

export interface NgrxForm<V> {
  [key: string]: FormGroupState<V>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface HasNgrxForm<V = any> {
  forms: NgrxForm<V>;
}

export const ngrxFormsExtensionFormState = <V>(
  key: string,
  values = {} as V,
  updateFns: StateUpdateFns<V> = {}
) => {
  return updateGroup(
    createFormGroupState<V>(
      key,
      values
    ),
    updateFns
  );
};

export const applyNgrxFormsExtensionsReducer = <S extends HasNgrxForm, V>(
  formKey: string,
  state: S,
  action: Action,
  defaultSettings: StateUpdateFns<V> = {}
): S => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formReducer = createFormStateReducerWithUpdate<any>(
    updateGroup<V>(defaultSettings)
  );

  const form = formReducer(state.forms[formKey], action) as FormGroupState<V>;

  if (form !== state.forms[formKey]) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...state as any,
      forms: {
        ...state.forms,
        [formKey]: form
      }
    };
  }

  return state;
};

export const applyNgrxFormsExtensionsUnsafeReducer = <S extends HasNgrxForm, V>(
  formKey: string,
  state: S,
  action: Action,
  defaultSettings: StateUpdateFns<V> = {}
): S => {

  const updateFn = updateGroup<V>(defaultSettings);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const form = updateFn(formStateReducer(state.forms[formKey], action) as any);

  if (form !== state.forms[formKey]) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...state as any,
      forms: {
        ...state.forms,
        [formKey]: form
      }
    };
  }

  return state;
};

const ngrxFormExtensionsSelector = <V>(key: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (s: any): V => s.forms[key];
};

const ngrxFormExtensionsValueSelector = <V>(key: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (s: any): V => s.forms[key].value;
};

export class NgrxFormExtensionModel<V> {
  constructor(public formKey: string) { }

  createFormState(
    id: string,
    defaultValues = {} as V,
    userValues = {} as Partial<V>,
    updateFns: StateUpdateFns<{}> = {}
  ) {
    return ngrxFormsExtensionFormState(
      this.getKey(id),
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...defaultValues as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...userValues as any
      },
      updateFns
    );
  }

  reduceAction<S extends HasNgrxForm<V>>(
    state: S,
    action: Action,
    id: string,
    defaultSettings?: StateUpdateFns<V>
  ) {
    return applyNgrxFormsExtensionsReducer(
      this.getKey(id),
      state,
      action,
      defaultSettings
    );
  }

  reduceUnsafeAction<S extends HasNgrxForm<V>>(
    state: S,
    action: Action,
    id: string,
    defaultSettings?: StateUpdateFns<V>
  ) {
    return applyNgrxFormsExtensionsUnsafeReducer(
      this.getKey(id),
      state,
      action,
      defaultSettings
    );
  }

  selectState<S>(id: string) {
    return ngrxFormExtensionsSelector<S>(this.getKey(id));
  }

  selectValue(id: string) {
    return ngrxFormExtensionsValueSelector<V>(this.getKey(id));
  }

  markAsSubmitted(id: string, state: HasNgrxForm) {
    const key = this.getKey(id);
    return {
      [key]: markAsSubmitted<V>(state.forms[key])
    };
  }

  markAsUnsubmitted(id: string, state: HasNgrxForm) {
    const key = this.getKey(id);
    return {
      [key]: markAsUnsubmitted<V>(state.forms[key])
    };
  }

  updateGroupValue(id: string, value: Partial<V>) {
    return new SetValueAction(
      `${this.formKey}__${id}`,
      value
    );
  }

  updateGroupState(
    state: HasNgrxForm,
    id: string,
    updateFns: StateUpdateFns<V>
  ) {
    const key = this.getKey(id);
    return {
      [key]: updateGroup<V>(
        state.forms[key],
        updateFns
      )
    };
  }

  getKey(id: string) {
    return `${this.formKey}__${id}`;
  }
}
