import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { TicketMessageModule } from '@app/common/ticket-message';
import { SatPopoverModule, SatPopoverAnchoringService } from '@zerops/fe/popover';
import { ScrollableModule } from '@app/common/scrollable';
import { TicketDetailContainer } from './ticket-detail.container';

@NgModule({
  declarations: [ TicketDetailContainer ],
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forChild(),
    TicketMessageModule,
    ScrollableModule,
    SatPopoverModule
  ],
  exports: [ TicketDetailContainer ],
  providers: [ SatPopoverAnchoringService ]
})
export class TicketDetailModule {

}
