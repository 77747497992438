import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import {
  Observable,
  EMPTY,
  throwError,
  BehaviorSubject
} from 'rxjs';
import {
  catchError,
  tap,
  filter,
  first,
  concatMap
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@app/models';
import { HttpStatusCodes } from '@app/constants';
import { getStoredAuth, updateStoredTokens } from '../auth-base.utils';
import { AuthBaseApi } from '../auth-base.api';
import { InvalidateAuthorization, RefreshTokenLocalSuccess, SetReferer } from '../auth-base.action';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private _refreshToken$ = new BehaviorSubject<string>(null);
  private _refreshing = false;

  constructor(
    private _store: Store<State>,
    private _authApi: AuthBaseApi,
    private _http: HttpClient,
    private _router: Router
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
    const data = getStoredAuth();

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error.error.code === HttpStatusCodes.UNAUTHORIZED && data && data.auth.refreshToken) {

          if (!this._refreshing) {
            this._refreshing = true;
            this._refreshToken$.next(null);

            return this._authApi
              .refresh$(data.auth.refreshToken)
              .pipe(
                tap((payload) => {
                  updateStoredTokens(payload);
                  this._refreshToken$.next(payload.accessToken);
                }),
                concatMap(() => this._http.request(request)),
                catchError((innerErr: HttpErrorResponse) => {
                  if (innerErr.error.error.code === HttpStatusCodes.UNAUTHORIZED
                    || innerErr.error.error.code === HttpStatusCodes.FORBIDDEN) {
                    // invalidate authorization
                    this._store.dispatch(new InvalidateAuthorization(true));
                    // set referer
                    this._store.dispatch(new SetReferer(this._router.url));
                  }

                  this._refreshing = false;

                  return EMPTY;
                })
              );

          } else {
            return this._refreshToken$.pipe(
              filter((token) => !!token),
              first(),
              tap((token) => {
                this._store.dispatch(new RefreshTokenLocalSuccess(token));
                this._refreshing = false;
              }),
              concatMap(() => this._http.request(request))
            );
          }

        } else {
          return throwError(err);
        }
      })
    );
  }
}

export const refreshTokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RefreshTokenInterceptor,
  multi: true
};
