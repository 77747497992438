<div
  class="__edge"
  [class.has-top-edge]="enableEdges && onTopEdge"
  [class.has-bottom-edge]="enableEdges && onBottomEdge">
  <div
    class="__area"
    cdkScrollable
    [ngStyle]="{
      'height': heightFormatted,
      'maxHeight': maxHeightFormatted,
      'minHeight': minHeightFormatted
    }"
    #areaRef>
    <ng-content></ng-content>
  </div>
</div>
