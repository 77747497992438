import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollableComponent } from './scrollable.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [ ScrollableComponent ],
  imports: [
    CommonModule,
    ScrollingModule
  ],
  exports: [ ScrollableComponent ]
})
export class ScrollableModule {}
