import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { PermissionService } from '@zerops/fe/permission';
import {
  map,
  withLatestFrom,
  switchMap,
  catchError,
  delay,
  filter,
  first
} from 'rxjs/operators';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import {
  authActiveUserClientId,
  AuthorizedRunEffect,
  Roles
} from '@app/base/auth-base';
import { InvoicesBaseApi } from './invoices-base.api';
import {
  ListRequest,
  ActionTypes,
  ListLocalSuccess,
  ListFail,
  EntityRequest,
  EntityLocalSuccess,
  EntityFail,
  LiabilityInvoiceRequest,
  LiabilityInvoiceLocalSuccess,
  LiabilityInvoiceFail,
  LiabilityFeeRequest,
  LiabilityFeeLocalSuccess,
  LiabilityFeeFail,
  PaymentIntentRequest,
  PaymentIntentLocalSuccess,
  PaymentIntentFail,
  ConfirmPaymentRequest,
  ConfirmPaymentLocalSuccess
} from './invoices-base.action';
import { SnackService } from '@app/common/snack';
import { identity } from '@app/base/auth-base/auth-base.selector';

@Injectable()
export class InvoicesBaseEffect extends AuthorizedRunEffect {

  private _onInit$ = this.onAuthorizedInit$.pipe(
    delay(1),
    switchMap(() => this._permission
      .authorize({
        only: [ Roles.Manager, Roles.Financial ]
      })
      .pipe(
        first()
      )
    ),
    filter((authorized) => !!authorized)
  );

  // trigger liability invoice request on load
  onInitLiabilityInvoice$ = createEffect(() => this._onInit$.pipe(
    map(() => new LiabilityInvoiceRequest())
  ));

  // trigger liability fee request on load
  onInitLiabilityFee$ = createEffect(() => this._onInit$.pipe(
    map(() => new LiabilityFeeRequest())
  ));

  // trigger list request on load
  onInitList$ = createEffect(() => this._onInit$.pipe(
    map(() => new ListRequest())
  ));

  onLiabilityInvoiceRequest$ = createEffect(() => this._actions$.pipe(
    ofType<LiabilityInvoiceRequest>(ActionTypes.LiabilityInvoiceRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, id ]) => this._api
      .liabilityInvoice$(id)
      .pipe(
        map((liabilityInvoice) => new LiabilityInvoiceLocalSuccess(
          liabilityInvoice
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new LiabilityInvoiceFail(data)))
        )
      )
    )
  ));

  onLiabilityFeeRequest$ = createEffect(() => this._actions$.pipe(
    ofType<LiabilityFeeRequest>(ActionTypes.LiabilityFeeRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, id ]) => this._api
      .liabilityFee$(id)
      .pipe(
        map((liabilityFee) => new LiabilityFeeLocalSuccess(
          liabilityFee
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new LiabilityFeeFail(data)))
        )
      )
    )
  ));

  onPaymentIntentRequest$ = createEffect(() => this._actions$.pipe(
    ofType<PaymentIntentRequest>(ActionTypes.PaymentIntentRequest),
    switchMap(({ payload }) => this._api
      .paymentStripeIntent$(payload.clientId, payload.type, payload.amount)
      .pipe(
        map(({ secret }) => new PaymentIntentLocalSuccess(
          secret
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new PaymentIntentFail(data)))
        )
      )
    )
  ));

  onPaymentIntentRequestLocalSuccess$ = createEffect(() => this._actions$.pipe(
    ofType<PaymentIntentRequest>(ActionTypes.PaymentIntentLocalSuccess),
    map(() => new ConfirmPaymentRequest())
  ));

  onConfirmPaymentLocalSuccessShowSnack$ = createEffect(() => this._actions$.pipe(
    ofType<ConfirmPaymentLocalSuccess>(ActionTypes.ConfirmPaymentLocalSuccess),
    withLatestFrom(this._store.pipe(select(identity))),
    switchMap(([ { payload }, user ]) => {
      const currencyPipe = new CurrencyPipe(user.language.id);
      const { amount, currency } = payload.paymentIntent;
      return this._snack.translatedSuccess$(
        'bulkPayment.paymentOnlineSuccessSnack',
        'common.close',
        { amount: currencyPipe.transform(amount / 100, currency.toUpperCase(), 'symbol', '1.2-2') }
      );
    })
  ), { dispatch: false });

  onListRequest$ = createEffect(() => this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, id ]) => this._api
      .list$(id)
      .pipe(
        map(({ invoiceList }) => new ListLocalSuccess(
          invoiceList
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ListFail(data)))
        )
      )
    )
  ));

  private _onEntityRequest$ = createEffect(() => this._actions$.pipe(
    ofType<EntityRequest>(ActionTypes.EntityRequest),
    switchMap(({ payload }) => this._api
      .entity$(payload)
      .pipe(
        map((response) => new EntityLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new EntityFail(data, payload)))
        )
      )
    )
  ));

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: InvoicesBaseApi,
    private _snack: SnackService,
    private _errorTranslation: ErrorTranslationService,
    private _permission: PermissionService
  ) {
    super(_actions$, _store);
  }
}
