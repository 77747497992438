import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  PhoneFormFieldComponent,
  PhoneFormFieldSelectDirective,
  PhoneFormFieldInputDirective
} from './phone-form-field.component';
import { PhoneFormSelectOptionModule } from './modules';

@NgModule({
  declarations: [
    PhoneFormFieldComponent,
    PhoneFormFieldSelectDirective,
    PhoneFormFieldInputDirective
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    PhoneFormSelectOptionModule
  ],
  exports: [
    PhoneFormFieldComponent,
    PhoneFormFieldSelectDirective,
    PhoneFormFieldInputDirective,
    PhoneFormSelectOptionModule
  ]
})
export class PhoneFormFieldModule {

}
