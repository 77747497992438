import {
  StandardService,
  BillingPeriod
} from '@app/common/settings';
import { ModuleTokens } from './client-services-base.constant';

export class ClientServicesBaseState {
  entities: ClientServicesEntityEntities;
  list: string[];

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: {}
    };
    this.list = [];
  }
}

export class ClientServicesEntityEntities {
  [ModuleTokens.Name]: { [id: string]: ClientServiceEntityBase; };
}

export class ClientServiceEntityNormalizedData {
  entities: ClientServicesEntityEntities;
  result: string[];
}

export class ClientServiceEntityLight {
  id: string;
  clientId: string;
  clientZoneStatus: string;
  standardService: StandardService;
  currencyId: string;
}

export class ClientServiceEntityBase extends ClientServiceEntityLight {
  serviceType: string;
  serviceName: string;
  serviceIdentification: string;
  oneTimePrice: number;
  monthPriceDiscounted: number;
  activationDate: string;
  billingPeriod: BillingPeriod;
}
