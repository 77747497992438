import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { ClientUserEntity } from '@app/base/client-user-base';

@Component({
  selector: 'vshcz-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: [ './company-select.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySelectComponent {

  @Input()
  activeId: string;

  @Input()
  set clientUserList(v: ClientUserEntity[]) {
    if (v) {
      this._clientUserList = orderBy(v, [ (itm) => itm.client.companyName ], [ 'asc' ]);
    } else {
      this._clientUserList = undefined;
    }
  }
  get clientUserList() {
    return this._clientUserList;
  }

  @Output()
  companySelect = new EventEmitter<string>();

  private _clientUserList: ClientUserEntity[];
}
