import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, select, StoreModule } from '@ngrx/store';
import { PermissionService } from '@zerops/fe/permission';
import { map } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { State } from '@app/models';
import { NoClientUserDialogModule } from './modules/no-client-user-dialog';
import {
  authHeaderInterceptorProvider,
  refreshTokenInterceptorProvider
} from './interceptors';
import { Roles, ModuleTokens } from './auth-base.constant';
import { reducer } from './auth-base.reducer';
import { isAuthenticated, activeUserClientId } from './auth-base.selector';
import { AuthBaseEffect } from './auth-base.effect';

@NgModule({
  imports: [
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ AuthBaseEffect ]),
    NoClientUserDialogModule
  ],
  exports: [ NoClientUserDialogModule ],
  providers: [
    authHeaderInterceptorProvider,
    refreshTokenInterceptorProvider
  ]
})
export class AuthBaseModule {
  constructor(
    private _store: Store<State>,
    private _permission: PermissionService
  ) {

    this._permission.define(
      Roles.Authorized,
      () => combineLatest([
        this._store.pipe(select(isAuthenticated)),
        this._store.pipe(select(activeUserClientId))
      ]).pipe(
        map(([ isAuth, id ]) => (isAuth && id !== undefined))
      )
    );

    this._permission.define(
      Roles.Manager,
      () => of(false)
    );

    this._permission.define(
      Roles.Financial,
      () => of(false)
    );

    this._permission.define(
      Roles.Technical,
      () => of(false)
    );

    this._permission.define(
      Roles.Unauthorized,
      () => this._store.pipe(
        select(isAuthenticated),
        map((state) => !state)
      )
    );

  }
}
