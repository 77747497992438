import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DomainRecordBaseApi {
  constructor(private _http: HttpClient) { }

  // TODO: interface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add$(type: string, domainId: string, data: any) {
    // TODO: interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(
      `/clouddns/record-${type.toLowerCase()}`,
      {
        ...data,
        domainId,
        type: type.toUpperCase()
      }
    );
  }

  // TODO: interface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update$(type: string, id: string, data: any) {
    // TODO: interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.put<any>(
      `/clouddns/record-${type.toLowerCase()}/${id}`,
      data
    );
  }

  delete$(id: string) {
    return this._http.delete(
      `/clouddns/record/${id}`
    );
  }

  restore$(id: string) {
    return this._http.put(
      `/clouddns/record/${id}/recover`,
      {}
    );
  }

  // TODO: interface
  entity$(id: string, type: string) {
    // TODO: interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.get<any>(
      `/clouddns/record-${type.toLowerCase()}/${id}`
    );
  }

}
