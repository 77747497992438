import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import {
  ClientUserEntity,
  ClientUserEntityEntities,
  ClientUserEntityNormalizedData
} from './client-user-base.model';
import { normalizeList, sortList } from './client-user-base.utils';

export enum ActionTypes {
  EntityRequest = '[Client User Base] Entity Request',
  EntityFail = '[Client User Base] Entity Fail',
  EntityLocalSuccess = '[Client User Base] Entity Local Success',

  ListRequest = '[Client User Base] List Request',
  ListFail = '[Client User Base] List Fail',
  ListLocalSuccess = '[Client User Base] List Local Success',

  AddToList = '[Client User Base] Add To List'
}

export class EntityRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.EntityFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.EntityRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.EntityRequest
      }
    };
  }
}

export class EntityFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.EntityFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }

}

export class EntityLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.EntityLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: ClientUserEntityEntities };

  constructor(data: ClientUserEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }
}

export class ListRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ListFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ListRequest,
      type: 'local'
    }
  };
}

export class ListFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ListFail,
        type: 'global',
        data
      }
    };
  }

}

export class ListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ListLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };
  payload: ClientUserEntityNormalizedData;

  constructor(data: ClientUserEntity[]) {
    this.payload = normalizeList(sortList(data));
  }

}

export class AddToList implements Action {
  readonly type = ActionTypes.AddToList;

  constructor(public payload: string) { }
}

export type Actions
  = EntityRequest
  | EntityFail
  | EntityLocalSuccess
  | ListRequest
  | ListFail
  | ListLocalSuccess
  | AddToList;
