import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { SatPopover } from '@zerops/fe/popover';

@Component({
  selector: 'vshcz-filter-pop-popover',
  templateUrl: './filter-pop-popover.component.html',
  styleUrls: [ './filter-pop-popover.component.scss' ]
})
export class FilterPopPopoverComponent {
  @Input()
  heading: string;

  @Input()
  horAlignment = 'center';

  @Input()
  lockAlignment = true;

  @Input()
  forceAlignment = true;

  @ViewChild(SatPopover, { static: true })
  popoverRef: SatPopover;

  @Output()
  closed = new EventEmitter<void>();

  close() {
    this.popoverRef.close();
  }

  open() {
    this.popoverRef.open();
  }

  toggle() {
    this.popoverRef.toggle();
  }

}
