import {
  Component,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import {
  AttachedPriceOfferStates,
  getTicketEntityById,
  TicketsBaseActionTypes,
  TicketsBaseApprovePriceOfferRequest,
  TicketsBaseRejectPriceOfferRequest
} from '@app/base/tickets-base';
import { select, Store } from '@ngrx/store';
import { State } from '@app/models';
import { ObservableInput } from 'observable-input';
import { merge, Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { progressByKey } from '@zerops/fe/ngrx';
import {
  ticketPriceOferRejectFormState,
  ticketPriceOferRejectFormValue
} from './ticket-price-offer.selector';
import { activeUserClientId } from '@app/base/auth-base';
import { BaseClass } from '@zerops/fe/core';

@Component({
  selector: 'vshcz-ticket-price-offer',
  templateUrl: './ticket-price-offer.container.html',
  styleUrls: [ './ticket-price-offer.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketPriceOfferContainer extends BaseClass {

  // # Event Streams
  onApprovePriceOffer$ = new Subject<void>();
  onRejectPriceOffer$ = new Subject<void>();

  // # Form States
  priceOfferRejectFormState$ = this._store.pipe(select(ticketPriceOferRejectFormState));

  // # Data
  // -- sync
  attachedPriceOfferStates = AttachedPriceOfferStates;
  id: string;

  @Input()
  active: boolean;

  // -- angular
  @ObservableInput()
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('id')
  id$!: Observable<string>;

  // -- aync
  rejectPriceOfferRequestKey$ = this.id$.pipe(
    map((id) => `${TicketsBaseActionTypes.RejectPriceOfferRequest}:${id}`)
  );
  rejectPriceOfferFailKey$ = this.id$.pipe(
    map((id) => `${TicketsBaseActionTypes.RejectPriceOfferFail}:${id}`)
  );
  activeClientId$ = this._store.pipe(select(activeUserClientId));
  priceOfferApproveLoading$ = this.id$.pipe(
    switchMap((id) => this._store.pipe(select(
      progressByKey(`${TicketsBaseActionTypes.ApprovePriceOfferRequest}:${id}`))
    )),
    map((loading) => !!loading)
  );
  priceOfferRejectLoading$ = this.id$.pipe(
    switchMap((id) => this._store.pipe(select(
      progressByKey(`${TicketsBaseActionTypes.RejectPriceOfferRequest}:${id}`))
    )),
    map((loading) => !!loading)
  );
  ticket$ = this.id$.pipe(switchMap((id) => this._store.pipe(
    select(getTicketEntityById(id)),
    filter((t) => !!t)
  )));

  // # Action Streams
  private _approvePriceOfferAction$ = this.onApprovePriceOffer$.pipe(
    withLatestFrom(
      this.ticket$,
      this.activeClientId$
    ),
    map(([ _, { ticketMessageList, id }, activeClientId ]) => {
      return new TicketsBaseApprovePriceOfferRequest(
        id,
        {
          lastAdminTicketMessageId: ticketMessageList.reverse().find(((d) => d.display === 'ADMIN')).id,
          clientId: activeClientId
        }
      );
    })
  );
  private _rejectPriceOfferAction$ = this.onRejectPriceOffer$.pipe(
    withLatestFrom(
      this.ticket$,
      this.activeClientId$,
      this._store.pipe(select(ticketPriceOferRejectFormValue))
    ),
    map(([ _, { id }, activeClientId, { messageText } ]) => new TicketsBaseRejectPriceOfferRequest(
        id,
        {
          clientId: activeClientId,
          messageText
        }
    ))
  );

  constructor(private _store: Store<State>) {

    super();

    // # Action Dispatcher
    merge(
      this._approvePriceOfferAction$,
      this._rejectPriceOfferAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }

}
