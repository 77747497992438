import { entitiesMerger } from '@zerops/fe/core';
import { AuthEntity } from '@app/base/auth-base/auth-base.model';

const _KEY = 'vsh:client_zone:';

interface Auth {
  auth: AuthEntity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entities: any;
  userId: string;
}

export function saveAuth(
  auth: AuthEntity,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entities: any,
  userId: string
) {
  const tokens: Auth = { auth, entities, userId };

  try {
    localStorage.setItem(_KEY + 'user_auth', JSON.stringify(tokens));
  } catch (error) {
    console.warn(`Could not update localStorage.`);
  }
}

export function getStoredAuth(): Auth {
  const tokenStrings = localStorage.getItem(_KEY + 'user_auth');

  return JSON.parse(tokenStrings);
}

export function updateStoredTokens(auth: AuthEntity) {
  const { entities, userId } = getStoredAuth();

  saveAuth(auth, entities, userId);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateStoredEntities(newEntities: any) {
  const { auth, userId, entities } = getStoredAuth() || { auth: undefined, userId: undefined, entities: {} };

  saveAuth(
    auth,
    entitiesMerger(entities, newEntities),
    userId
  );
}

export function saveSelectedClient(id: string) {
  localStorage.setItem(_KEY + 'client_id', id);
}

export function getSelectedClient() {
  return localStorage.getItem(_KEY + 'client_id');
}

export function saveReferralClient(id: string) {
  localStorage.setItem(_KEY + 'referral_client_id', id);
}

export function getReferralClient() {
  return localStorage.getItem(_KEY + 'referral_client_id');
}

export function deleteReferralClient() {
  localStorage.removeItem(_KEY + 'referral_client_id');
}

export function removeStoredAuthAndClient() {
  localStorage.removeItem(_KEY + 'user_auth');
  localStorage.removeItem(_KEY + 'client_id');
}
