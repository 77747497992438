import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// type ObserveTypes = 'events' | 'body' | 'response';

@Injectable({
  providedIn: 'root'
})
export class FilesApi {
  constructor(private _http: HttpClient) { }

  upload$(
    file: File,
    clientId: string,
    reportProgress = false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    observe: any = 'events'
  ) {
    const formData = new FormData();
    formData.append('file', file);

    return this._http.post<{ fileId: string; }>(
      `/api/public/client/${clientId}/file`,
      formData,
      {
        reportProgress,
        observe
      }
    );
  }
}
