import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { PermissionService } from '@zerops/fe/permission';
import {
  map,
  withLatestFrom,
  switchMap,
  catchError,
  delay,
  filter,
  first
} from 'rxjs/operators';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import {
  authActiveUserClientId,
  AuthorizedRunEffect,
  Roles
} from '@app/base/auth-base';
import { ClientServicesBaseApi } from './client-services-base.api';
import {
  ListRequest,
  ActionTypes,
  ListLocalSuccess,
  ListFail
} from './client-services-base.action';

@Injectable()
export class ClientServicesBaseEffect extends AuthorizedRunEffect {

  // trigger list request on load
  private _onInitList$ = createEffect(() => this.onAuthorizedInit$.pipe(
    delay(1),
    switchMap(() => this._permission
      .authorize({
        only: [ Roles.Authorized ]
      })
      .pipe(
        first()
      )
    ),
    filter((authorized) => !!authorized),
    map(() => new ListRequest())
  ));

  private _onListRequest$ = createEffect(() => this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, id ]) => this._api
      .list$(id)
      .pipe(
        map(({ clientServiceList }) => new ListLocalSuccess(
          clientServiceList
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ListFail(data)))
        )
      )
    )
  ));

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: ClientServicesBaseApi,
    private _errorTranslation: ErrorTranslationService,
    private _permission: PermissionService
  ) {
    super(_actions$, _store);
  }
}
