import { OnInit, AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { combineLatest, merge, of, Subject } from 'rxjs';
import {
  shareReplay,
  map,
  startWith,
  withLatestFrom,
  takeUntil,
  filter,
  switchMap,
  distinctUntilChanged,
  catchError,
  delay
} from 'rxjs/operators';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  StripeCardElementChangeEvent,
  StripeCardElement,
  ConfirmCardPaymentData
} from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { State } from '@app/models';
import { PaymentIntentTypes, orderInvoices } from '@app/base/invoices-base';
import { activeUserClient } from '@app/base/auth-base/auth-base.selector';
import { getPaymentQR } from '@app/base/payments-base/payments-base.utils';
import { currencyMap } from '../settings';
import { TranslateService } from '@ngx-translate/core';
import { clientInvoiceLiabilities, getActivePayment } from '@app/base/invoices-base/invoices-base.selector';
import { PaymentKinds } from '@app/base/payments-base/payments-base.constant';
import {
  PaymentIntentRequest,
  ActionTypes,
  ConfirmPaymentLocalSuccess,
  ConfirmPaymentFail
} from '@app/base/invoices-base/invoices-base.action';
import { DialogKey } from './bulk-payment-invoices-dialog.constant';
import { StripeDeclineCodesCustom, StripePaymentStatuses } from '@app/base/invoices-base/invoices-base.constant';
import { ErrorTranslationService } from 'app/services';
import { RemoveError } from '@zerops/fe/ngrx';

@Component({
  selector: 'vshcz-bulk-payment-invoices-dialog',
  templateUrl: './bulk-payment-invoices-dialog.container.html',
  styleUrls: [ './bulk-payment-invoices-dialog.container.scss' ]
})
export class BulkPaymentInvoicesDialogContainer extends BaseClass implements OnInit, AfterViewInit {

  private _stripeCardElement: StripeCardElement;

  paymentKinds = PaymentKinds;
  dialogKey = DialogKey;
  isCardEntered = false;
  declineCodeOthers = 'decline_code_others';

  paymentIntentRequestKey = ActionTypes.PaymentIntentRequest;
  confirmPaymentRequestKey = ActionTypes.ConfirmPaymentRequest;
  paymentIntentFailKey = ActionTypes.PaymentIntentFail;
  confirmPaymentFailKey = ActionTypes.ConfirmPaymentFail;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#0077CC',
        color: '#1A1A1A',
        lineHeight: '60px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#949494'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    locale: this._translate.currentLang as any
  };

  onTopUp$ = new Subject<void>();
  onClose$ = new Subject<void>();

  currencyMap$ = this._store.pipe(
    select(currencyMap),
    shareReplay()
  );

  private _dialogState$ = this._store.pipe(
    select(getDialogState(this.dialogKey))
  );

  open$ = this._dialogState$.pipe(
    map(({ state }) => !!state)
  );

  paymentKind$ = this._dialogState$.pipe(
    map(({ meta }) => meta as {
      instanceKind: 'dashboard' | 'menu' | 'route';
      paymentKind: PaymentKinds;
      paymentIntentType: PaymentIntentTypes;
    }),
    map((meta) => !!meta ? meta.paymentKind : undefined)
  );

  /**
   * Getting a flag from the open dialog's meta-data to pass it to the processed
   * payment workflow. The reason is to compare it later with the same flag inside
   * the appropriate component instance to eliminate duplicated reactions because
   * there are more instances of the component.
   */
  instanceKind$ = this._dialogState$.pipe(
    map(({ meta }) => meta as {
      instanceKind: 'dashboard' | 'menu' | 'route';
      paymentKind: PaymentKinds;
      paymentIntentType: PaymentIntentTypes;
    }),
    map((meta) => !!meta ? meta.instanceKind : undefined)
  );

  clientInvoiceLiabilities$ = this._store.pipe(
    select(clientInvoiceLiabilities)
  );

  unpaidInvoices$ = this.clientInvoiceLiabilities$.pipe(
    map((invoiceLiabilities) => orderInvoices(invoiceLiabilities.unpaidInvoices, false))
  );

  client$ = this._store.pipe(
    select(activeUserClient)
  );

  currentLang$ = this._translate.onLangChange.pipe(
    startWith(this._translate.currentLang),
    map(() => this._translate.currentLang)
  );

  qrData$ = combineLatest([
    this.client$,
    this.clientInvoiceLiabilities$
  ]).pipe(
    map(([ client, invoiceLiabilities ]) => !!client && !!invoiceLiabilities
      ? getPaymentQR(
        /**
         * The iban account number is always defined.
         */
        invoiceLiabilities.bankAccount.iban,
        /**
         * The total invoice amount owed, less credit, if any, but only
         * if the result is greater than 0, otherwise 0.
         */
        invoiceLiabilities.bankTransferSummary.totalDue > 0
          ? +(invoiceLiabilities.bankTransferSummary.totalDue).toFixed(2)
          : 0,
        /**
         * The applied currency id is taken directly from a client record.
         */
        client.currencyId,
        /**
         * For a local payment the variable symbol value is used, otherwise null.
         */
        invoiceLiabilities.bankAccount.localPayment ? invoiceLiabilities.bankAccount.variableSymbol : null,
        /**
         * The swift bank code is always defined.
         */
        invoiceLiabilities.bankAccount.swift,
        /**
         * For a foreign payment the payment note value is used, otherwise null.
         */
        !invoiceLiabilities.bankAccount.localPayment ? `${invoiceLiabilities.bankAccount.paymentNote}` : null
      )
      : ''
    )
  );

  @ViewChildren(StripeCardComponent) cards!: QueryList<StripeCardComponent>;

  private _onActivePaymentCard$ = this._store.pipe(
    select(getActivePayment),
    filter((activePayment) => !!activePayment && activePayment.type === PaymentIntentTypes.Invoice)
  );

  private _onConfirmPayment$ = this._onActivePaymentCard$.pipe(
    filter((activePayment) => !!activePayment.secret),
    filter((activePayment) => activePayment.status === StripePaymentStatuses.IntentRequestSuccess),
    switchMap((activePayment) => {
      const cardData: ConfirmCardPaymentData = {
        payment_method: {
          card: this._stripeCardElement
        }
      };
      return this._stripeService.confirmCardPayment(activePayment.secret, cardData).pipe(
        switchMap((result) => {
          if (result.error) {
            return this._errorTranslation.get$(new HttpErrorResponse({
              error: {
                error: {
                  /**
                   * There is the customized error message for one of the declined codes.
                   * For any other declined code the general declined message should be used.
                   * If the error code means a declined payment is detected by the 'card_declined' value.
                  */
                  code: result.error.code === 'card_declined'
                    ? result.error.decline_code === StripeDeclineCodesCustom.INSUFFICIENT_FUNDS
                      ? `decline_code_${result.error.decline_code}`
                      : this.declineCodeOthers
                    : result.error.code,
                  message: result.error.message
                }
              },
              status: 400,
              statusText: result.error.type + ' / ' + result.error.param ? result.error.param : '',
              url: result.error['doc_url'] ? result.error['doc_url'] : ''
            })).pipe(
              withLatestFrom(this._translate.get('error.stripe_general_error', { code: result.error.code })),
              map(([ data, generalError ]) => {
                /**
                 * It's necessary to decide if the error code was or wasn't found in the pre-defined
                 * translation table to map the message to one of four supported languages.
                 * It the explicit error code was not found the returned value of the get$ function
                 * is the object {code: 'xxx', message: 'error.xxx'} and its message value is used
                 * for such a detection. The general error message is used then.
                 */
                if (data.message.split('.')[0] === 'error') {
                  data.message = generalError;
                }
                return data;
              }),
              map((data) => new ConfirmPaymentFail(data))
            );
          }
          return of(new ConfirmPaymentLocalSuccess(PaymentIntentTypes.Invoice, result.paymentIntent));
        }),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ConfirmPaymentFail(data)))
        )
      );
    })
  );

  onActivePayment$ = this._onActivePaymentCard$.pipe(
    withLatestFrom(this.instanceKind$),
    filter(([ activePayment, instanceKind ]) => activePayment.instanceKind === instanceKind),
    map(([ activePayment ]) => {
      if (!activePayment) {
        return false;
      } else if (
        activePayment.status === StripePaymentStatuses.IntentRequestInit ||
        activePayment.status === StripePaymentStatuses.PaymentRequestInit
      ) {
        return true;
      }
      return false;
    })
  );

  private _onTopUpAction$ = this.onTopUp$.pipe(
    withLatestFrom(
      this.instanceKind$,
      this.client$,
      this.clientInvoiceLiabilities$
    ),
    map(([ _, instanceKind, client, invoiceLiabilities ]) => new PaymentIntentRequest({
      instanceKind,
      status: StripePaymentStatuses.IntentRequestInit,
      clientId: client.id,
      type: PaymentIntentTypes.Invoice,
      amount: +(invoiceLiabilities.cardPaymentSummary.totalDue).toFixed(2)
    }))
  );

  private _onCloseAction$ = this.onClose$.pipe(
    map(() => new RemoveError([this.paymentIntentFailKey, this.confirmPaymentFailKey]))
  );

  private _clearContext(): void {
    this.isCardEntered = false;
    this._stripeCardElement = undefined;
  }

  constructor(
    private _store: Store<State>,
    private _translate: TranslateService,
    private _errorTranslation: ErrorTranslationService,
    private _stripeService: StripeService
  ) {
    super();

    // # Store Dispatcher
    merge(
      this._onTopUpAction$,
      this._onCloseAction$,
      this._onConfirmPayment$
    ).pipe(
      takeUntil(this._ngOnDestroy$)
    ).subscribe(this._store);
  }

  ngOnInit() {

    /**
     * They are used to clear up local variables when a dialog is closed. It is related
     * to the setting of the missing listening event on the <ngx-stripe-card> component
     * instance to detect when a client correctly enters all required card values,
     * and it is possible to initialize the payment.
     */
    this._dialogState$.pipe(
      filter(({ state }) => !state),
      distinctUntilChanged(),
      takeUntil(this._ngOnDestroy$)
    ).subscribe(() => {
      this._clearContext();
    });

  }

  ngAfterViewInit() {

    /**
     * NOTICE: Since ngx-stripe v10 there is already the 'change' @Output support.
     * The used version of <ngx-stripe-card> component contains a problem with the
     * missing 'change' output that allows it to detect when a client correctly enters
     * all required card values and when it is possible to initialize the payment.
     * That's why a new listening event is added on the <ngx-stripe-card> instance
     * component, which keeps the local variable 'isCardEntered' in sync with the
     * state of the component instance in GUI.
     */
    combineLatest([
      this.paymentKind$,
      this.cards.changes
    ]).pipe(
      filter(([ paymentKind, _ ]) => paymentKind === PaymentKinds.Online),
      delay(100),
      filter(([ _, cards ]) => cards && cards.first),
      takeUntil(this._ngOnDestroy$)
    ).subscribe(([ _, { first } ]) => {
      this._stripeCardElement = (first as StripeCardComponent).element;

      /**
       * Since ngx-stripe v10 it's not necessary to add the 'change' event.
       */

      /*
      this._stripeCardElement.on('change', (state) => {
        if (state && state.complete) {
          this.isCardEntered = state.complete;
        } else {
          this.isCardEntered = false;
        }
      });
      */
    });
  }

  cardState(state: StripeCardElementChangeEvent) {
    if (state && state.complete) {
      this.isCardEntered = state.complete;
    } else {
      this.isCardEntered = false;
    }
  }

}
