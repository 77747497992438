import { normalize, denormalize } from 'normalizr';
import {
  serverParkAccessListSchema,
  serverParkAccessSchema
} from '@app/schemas';
import { ServerParkAccessUserEntity } from './server-park-access-base.model';
import { ModuleTokens } from './server-park-access-base.constant';

export function normalizeList(data: ServerParkAccessUserEntity[]) {
  return normalize(data, serverParkAccessListSchema);
}

export function denormalizeList(
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serverParkAccessBaseEntities: any
) {
  return denormalize(
    ids,
    serverParkAccessListSchema,
    {
      [ModuleTokens.Name]: serverParkAccessBaseEntities
    }
  );
}

export function denormalizeEntity(
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serverParkAccessBaseEntities: any
) {
  return denormalize(
    id,
    serverParkAccessSchema,
    {
      [ModuleTokens.Name]: serverParkAccessBaseEntities
    }
  );
}
