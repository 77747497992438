import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, merge } from 'rxjs';
import {
  filter,
  map,
  tap,
  switchMap,
  takeUntil,
  first,
  catchError,
  pairwise,
  retryWhen,
  delay
} from 'rxjs/operators';
import {
  AuthActionTypes,
  Authorize,
  InvalidateAuthorization,
  RefreshTokenLocalSuccess,
  getStoredAuth
} from '@app/base/auth-base';
import { State } from '@app/models';
import { WebsocketsApi } from './websockets.api';
import {
  ActionTypes,
  Message,
  StatusChange,
  Send,
  Connected,
  Reconnect
} from './websockets.action';
import {
  ParsedFrame,
  WebsocketStatuses
} from './websockets.model';

@Injectable()
export class WebsocketsEffect {
  private _onAuthorize$ = merge(
    this._actions$.pipe(
      ofType<Authorize>(AuthActionTypes.Authorize),
      map(({ payload }) => payload.auth.accessToken)
    ),
    this._actions$.pipe(
      ofType<RefreshTokenLocalSuccess>(AuthActionTypes.RefreshTokenLocalSuccess),
      map((({ payload }) => payload))
    ),
    this._actions$.pipe(
      ofType<Reconnect>(ActionTypes.Reconnect),
      map((({ payload }) => payload))
    )
  );
  private _takeUntil$ = this._actions$.pipe(ofType<InvalidateAuthorization>(AuthActionTypes.InvalidateAuthorization));

  private _onAuthorizeConnect$ = createEffect(() => this._onAuthorize$.pipe(
    switchMap((token) => this._api
      .auth$(token)
      .pipe(
        tap((socketToken) => this._api.connect(socketToken.token)),
        map((socketToken) => socketToken.token),
        retryWhen((errors) => errors.pipe(delay(5000))),
        catchError(() => of(undefined))
      )
    ),
    filter((t) => !!t),
    switchMap(() => this._api
      .status$
      .pipe(
        filter((status) => status === WebsocketStatuses.CONNECTING),
        first(),
        map(() => new Connected())
      )
    )
  ));

  private _onMessage$ = createEffect(() => this._actions$.pipe(
    ofType<Connected>(ActionTypes.Connected),
    switchMap(() => this._api
      .messages$
      .pipe(
        takeUntil(
          merge(
            this._takeUntil$,
            // check if previous status was 1 (connect)
            // and new status is 0 (not connect)
            // which means connection was closed and needs to be
            // reconnected
            this._api.status$.pipe(
              pairwise(),
              filter(([ a, b ]) => a === 1 && b === 0),
              tap(() => {
                const lsAuth = getStoredAuth();

                if (lsAuth) {
                  const { auth } = getStoredAuth();
                  this._store.dispatch(new Reconnect(auth.accessToken));
                }

              })
            )
          )
        ),
        map((message) => {
          const parsed: ParsedFrame = JSON.parse(message);
          const parsedData = JSON.parse(parsed.data);

          return new Message({
            ...parsed,
            data: parsedData
          });
        })
      )
    )
  ));

  private _onStatusChange$ = createEffect(() => this._actions$.pipe(
    ofType(ActionTypes.StatusChange),
    switchMap(() => this._api
      .status$
      .pipe(
        takeUntil(this._takeUntil$),
        map((payload) => new StatusChange(payload))
      )
    )
  ));

  private _onSend$ = createEffect(() => this._actions$.pipe(
    ofType<Send>(ActionTypes.Send),
    tap((action) => this._api.send(action.payload))
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _api: WebsocketsApi,
    private _store: Store<State>
  ) {
  }
}
