import { MetaReducer, ActionReducer } from '@ngrx/store';
import { storeLogger } from 'ngrx-store-logger';

import { State } from '@app/models';
import { environment } from 'environments/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logger(reducer: ActionReducer<State>): any {
  return storeLogger({
    collapsed: true,
    filter: {
      blacklist: [ '@ngrx/store/update-reducers' ]
    }
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
? [
    logger
  ]
: [];
