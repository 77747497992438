import { Component, Input, ViewChild, OnInit, ViewContainerRef, ElementRef } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { progressGroupMap, ProgressMap } from '@zerops/fe/ngrx';
import { ObservableInput } from 'observable-input';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { State } from '@app/models';
import {
  getTicketEntityById,
  TicketsBaseActionTypes,
  TicketFile
} from '@app/base/tickets-base';
import { ScrollableComponent } from '@app/common/scrollable';
import { TicketsBaseApi } from '@app/base/tickets-base';
import { SatPopover, SatPopoverAnchoringService } from '@zerops/fe/popover';

@Component({
  selector: 'vshcz-ticket-detail',
  templateUrl: './ticket-detail.container.html',
  styleUrls: [ './ticket-detail.container.scss' ]
})
export class TicketDetailContainer extends BaseClass implements OnInit {

  // # Event Streams
  onFileDownload$ = new Subject<string>();

  // # Data
  // -- sync
  _loaded = false;
  attachments: TicketFile[];
  messageAddTempKey = TicketsBaseActionTypes.MessageAddTemp;
  messageAddTempMap: ProgressMap;
  avatarSourceMap = {
    USER: 'gravatar',
    ADMIN: 'image'
  };

  // -- angular
  @Input()
  height: number;

  @ObservableInput()
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('id')
  id$!: Observable<string>;

  @ViewChild(ScrollableComponent)
  scrollableRef: ScrollableComponent;

  @ViewChild(SatPopover, { static: true })
  attachementPopRef: SatPopover;

  // -- async
  data$ = this.id$.pipe(switchMap(
    (id) => this._store.pipe(select(getTicketEntityById(id)))
  ));
  messageAddTempMap$ = this._store.pipe(select(
    progressGroupMap(this.messageAddTempKey)
  ));

  constructor(
    private _ticketApi: TicketsBaseApi,
    private _anchoringService: SatPopoverAnchoringService,
    private _viewContainerRef: ViewContainerRef,
    private _store: Store<State>
  ) {
    super();

    this.onFileDownload$
      .pipe(
        switchMap((id) => this._ticketApi.attachment$(id))
      )
      .subscribe(({ token }) => window.location.replace(`/api/public/file/${token}`));

    // # Data Resolvers
    this.messageAddTempMap$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe((d) => this.messageAddTempMap = d);
  }

  scrollTo(offset: 'bottom' | number, duration?: number) {
    if (!this.scrollableRef) { return; }

    if (offset === 'bottom') {
      this.scrollableRef.scrollToBottom(duration);
      return;
    }

    this.scrollableRef.scrollTo(offset, undefined, duration);
  }

  ngOnInit() {
    setTimeout(() => {
      this._loaded = true;
    }, 300);
  }

  trackBy(index: number) {
    return index;
  }

  attachmentOpen({ element, files }: {
    element: HTMLElement,
    files: TicketFile[]
  }) {
    this.attachments = files;

    this._anchoringService.anchor(
      this.attachementPopRef,
      this._viewContainerRef,
      new ElementRef(element)
    );

    this._anchoringService.openPopover();
  }

}
