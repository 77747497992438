import { normalize, denormalize } from 'normalizr';
import { paymentsListSchema, paymentsSchema } from '@app/schemas';
import {
  PaymentEntity,
  PaymentsEntityNormalizedData,
  PaymentEntityLight
} from './payments-base.model';
import { ModuleTokens } from './payments-base.constant';
import { InvoicesBaseModuleTokens } from '../invoices-base';

export function normalizeList(data: PaymentEntity[] | PaymentEntityLight[]): PaymentsEntityNormalizedData {
  return normalize(data, paymentsListSchema);
}

export function denormalizeList(
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentsEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoicesEntities: any
) {
  return denormalize(
    ids,
    paymentsListSchema,
    {
      [ModuleTokens.Name]: paymentsEntities,
      [InvoicesBaseModuleTokens.Name]: invoicesEntities
    }
  ) as PaymentEntity[];
}

export function denormalizeEntity(
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentsEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoicesEntities: any
) {
  return denormalize(
    id,
    paymentsSchema,
    {
      [ModuleTokens.Name]: paymentsEntities,
      [InvoicesBaseModuleTokens.Name]: invoicesEntities
    }
  ) as PaymentEntity;
}

export function getPaymentQR(
  iban: string,
  amount: number,
  currencyId: string,
  variableSymbol?: string | null,
  swift?: string | null,
  message?: string | null
) {
  if (!iban || !amount || !currencyId) { return ''; }

  const acc = !!swift ? (iban + '+' + swift) : iban;
  const msg = !!message ? `*MSG:${message}` : '';
  const vs =  !!variableSymbol ? `*X-VS:${variableSymbol}` : '';

  return `SPD*1.0*ACC:${acc}*AM:${amount.toString()}*CC:${currencyId}${vs}${msg}`;
}
