import { DomainRecordStatuses } from './domain-record-base.constant';
import { DomainRecordSimpleBase } from './domains-base.model';

export class DomainRecordBaseState {
  recordsMap: { [key: string]: DomainRecordEntityBase; };

  constructor() {
    this.recordsMap = {};
  }
}

export type DomainRecordChangeStatus = 'DELETED' | 'UPDATED' | 'INSERTED' | 'ACTIVE';

export class DomainRecordsWithChangeStatus extends DomainRecordSimpleBase {
  _status: DomainRecordChangeStatus;
}

export class DomainRecordEntityLight {
  id: string;
  domainId: string;
  status: DomainRecordStatuses;
  name: string;
  class: string;
  type: string;
  originApplicationType: string;
  originUserFullName: string;
  created: string;
  lastUpdate: string;
  originalDomainRecordId: string;
}

export class DomainRecordEntityBase extends DomainRecordEntityLight {
  value: string;
}

export interface DomainRecordType {
  record: string;
  focus?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues: { [key: string]: any; };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationRules?: any; // { [key: string]: StateUpdateFns<any>; };
}
