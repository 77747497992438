import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { getStoredAuth } from '../auth-base.utils';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authData = getStoredAuth();

    if (authData && authData.auth.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authData.auth.accessToken}`
        }
      });
    }

    return next.handle(request);
  }
}

export const authHeaderInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthHeaderInterceptor,
  multi: true
};
