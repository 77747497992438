import { FormGroupState } from 'ngrx-forms';
import { HasNgrxForm } from '@app/common/ngrx-forms-extensions';
import { formState } from './search.forms';
import { ModuleTokens, SearchModes, SearchEntities } from './search.constant';

export class SearchState implements HasNgrxForm {
  forms = {
    ...formState(ModuleTokens.Name)()
  };

  suggest: SearchEntityMapped[];
  search: SearchEntity[];
  open: boolean;
  mode: SearchModes;

  constructor() {
    this.suggest = undefined;
    this.search = undefined;
    this.open = false;
    this.mode = SearchModes.Suggest;
  }
}

export class SearchFormValue {
  keyword: string;
}

export type SearchFormState = FormGroupState<SearchFormValue>;

export class SearchContents {
  parameter: string;
  value: string;
}

export class SearchEntity {
  entityId: string;
  entity: SearchEntities;
  score: number;
  highlight: SearchContents[];
  infoContent: SearchContents[];
  searchContent: SearchContents[];
}

export class SearchEntityMapped {
  entityId: string;
  entity: SearchEntities;
  score: number;
  highlight: SearchContents[];
  _highlight: { [key: string]: SearchContents; };
  infoContent: SearchContents[];
  _infoContent: { [key: string]: SearchContents; };
  searchContent: SearchContents[];
  _searchContent: { [key: string]: SearchContents; };
}

export class SearchEntityWithLink extends SearchEntityMapped {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _link: any[];
}
