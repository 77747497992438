<button
  mat-button
  type="button"
  class="__button"
  [class.has-no-label]="!label"
  [zpsSatPopoverAnchor]="popRef"
  (click)="!!popRef.toggle()"
  *ngIf="(files$ | async)?.length as filesLen">

  <mat-icon
    [matBadge]="filesLen"
    matBadgeColor="accent"
    matBadgeSize="small">
    attachment
  </mat-icon>

  <span *ngIf="label"> {{ label }}</span>

</button>

<vshcz-upload
  *ngIf="!(files$ | async)?.length"
  multiple
  (select)="onSelect$.next($event)">
  <button
    type="button"
    class="__button"
    [class.has-no-label]="!label"
    mat-button>

    <mat-icon>attachment</mat-icon>

    <span *ngIf="label"> {{ label }}</span>

  </button>
</vshcz-upload>

<!-- files -->
<zps-sat-popover
  #popRef
  hasBackdrop
  verticalAlign="end">
  <mat-card class="__pop-card  mat-elevation-z5">
    <mat-list>
      <mat-list-item
        class="__list-item"
        *ngFor="let file of (files$ | async); let i = index">
        <h4 mat-line>{{ file.meta?.name }}</h4>
        <p
          mat-line
          [innerHTML]="'common.files.fileSize' | translate: { size: file.meta?.size | bytes }">
        </p>
        <button
          type="button"
          (click)="!!onRemoveFromQueue$.next(file.uuid)"
          class="__remove"
          mat-icon-button>
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>

    <vshcz-upload
      multiple
      class="__more-upload"
      (select)="onSelect$.next($event)">

      <button
        class="__more-button"
        color="primary"
        type="button"
        mat-button>
        <mat-icon>add_circle_outline</mat-icon>
        <span translate="files.addMoreFiles"></span>
      </button>

    </vshcz-upload>

  </mat-card>
</zps-sat-popover>
