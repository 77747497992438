import { Action } from '@ngrx/store';
import { StateUpdateFns } from 'ngrx-forms';
import {
  NgrxFormExtensionModel,
  HasNgrxForm
} from '@app/common/ngrx-forms-extensions';
import {
  UserInfoFormValue,
  UserInfoFormState
} from './user-info-form.model';
import { formKey } from './user-info-form.constant';

const formModel = new NgrxFormExtensionModel<UserInfoFormValue>(formKey);

export const getFormKey = (id: string) => formModel.getKey(id);

export const formState = (id: string) => (
  defaultValues: Partial<UserInfoFormValue> = {},
  updateFns: StateUpdateFns<UserInfoFormValue> = {}
) => ({
  [getFormKey(id)]: formModel.createFormState(
    id,
    {
      countryCallingCode: 420,
      phoneNumber: undefined,
      smsNotifications: true,
      firstName: undefined,
      lastName: undefined,
      languageId: 'cs'
    },
    defaultValues,
    updateFns
  )
});

export const applyFormReducer = <S extends HasNgrxForm>(
  state: S,
  action: Action,
  id: string,
  defaultSettings?: StateUpdateFns<UserInfoFormValue>
) => formModel.reduceAction(state, action, id, defaultSettings);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const markAsSubmitted = (id: string, state: any) => formModel.markAsSubmitted(id, state);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const markAsUnsubmitted = (id: string, state: any) => formModel.markAsUnsubmitted(id, state);
export const formSelector = (id: string) => formModel.selectState<UserInfoFormState>(id);
export const formValueSelector = (id: string) => formModel.selectValue(id);
export const updateGroupAction = (
  id: string,
  value: Partial<UserInfoFormValue>
) => formModel.updateGroupValue(id, value);
