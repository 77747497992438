import {
  PermissionGuard,
  createRule
} from '@zerops/fe/permission';
import { NgrxNamedRoutes } from '@app/common/ngrx-router';
import { Roles } from '@app/base/auth-base';
import { CloudDNSGuard } from './services/clouddns.guard';

const _baseAuthorized = {
  permission: createRule(
    { only: [ Roles.Authorized ] },
    [ '/login' ]
  )
};

const _manager = {
  permission: createRule(
    { only: [ Roles.Manager ] },
    [ '/login' ]
  )
};

export const APP_ROUTES: NgrxNamedRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/routed/+login/login.module').then((m) => m.LoginModule),
    canActivate: [ PermissionGuard ],
    data: {
      permission: createRule(
        { only: [ Roles.Unauthorized ] },
        [ '/dashboard' ]
      )
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/routed/+dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [ PermissionGuard ],
    data: _baseAuthorized
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/routed/+account/account.module').then((m) => m.AccountModule),
    canActivate: [ PermissionGuard ],
    data: _baseAuthorized
  },
  {
    path: 'invoices',
    loadChildren: () => import('./modules/routed/+invoices/invoices.module').then((m) => m.InvoicesModule),
    canActivate: [ PermissionGuard ],
    data: {
      permission: createRule(
        {
          only: [
            Roles.Manager,
            Roles.Financial
          ]
        },
        [ '/login' ]
      )
    }
  },
  {
    path: 'payments',
    loadChildren: () => import('./modules/routed/+payments/payments.module').then((m) => m.PaymentsModule),
    canActivate: [ PermissionGuard ],
    data: {
      permission: createRule(
        {
          only: [
            Roles.Manager,
            Roles.Financial
          ]
        },
        [ '/login' ]
      )
    }
  },
  {
    path: 'payment',
    loadChildren: () => import('app/modules/routed/+payment/payment.module').then((m) => m.PaymentModule),
    canActivate: [ PermissionGuard ],
    data: {
      permission: createRule(
        {
          only: [
            Roles.Authorized
          ]
        },
        [ '/login' ]
      )
    }
  },
  {
    path: 'tickets',
    loadChildren: () => import('./modules/routed/+tickets/tickets.module').then((m) => m.TicketsModule),
    canActivate: [ PermissionGuard ],
    data: {
      hideSupport: true,
      permission: createRule(
        {
          only: [
            Roles.AuthorizedToSendTickets
          ]
        },
        [ '/login' ]
      )
    }
  },
  {
    path: 'racks',
    loadChildren: () => import('./modules/routed/+racks/racks.module').then((m) => m.RacksModule),
    canActivate: [ PermissionGuard ],
    data: {
      permission: createRule(
        {
          only: [
            Roles.Manager,
            Roles.Technical
          ]
        },
        [ '/login' ]
      )
    }
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/routed/+company/company.module').then((m) => m.CompanyModule),
    canActivate: [ PermissionGuard ],
    data: _manager
  },
  {
    path: 'services',
    loadChildren: () => import('./modules/routed/+services/services.module').then((m) => m.ServicesModule),
    canActivate: [ PermissionGuard ],
    data: {
      permission: createRule(
        {
          only: [
            Roles.Manager,
            Roles.Technical
          ]
        },
        [ '/login' ]
      )
    }
  },
  {
    path: 'client-services',
    loadChildren: () => import('./modules/routed/+client-services/client-services.module').then((m) => m.ClientServicesModule),
    canActivate: [ PermissionGuard ],
    data: {
      permission: createRule(
        {
          only: [
            Roles.Manager,
            Roles.Financial
          ]
        },
        [ '/login' ]
      )
    }
  },
  {
    path: 'servers-managed',
    loadChildren: () => import('./modules/routed/+servers-managed/servers-managed.module').then((m) => m.ServersManagedModule),
    canActivate: [ PermissionGuard ],
    data: _baseAuthorized
  },
  {
    path: 'servers-hosted',
    loadChildren: () => import('./modules/routed/+servers-hosted/servers-hosted.module').then((m) => m.ServersHostedModule),
    canActivate: [ PermissionGuard ],
    data: _baseAuthorized
  },
  {
    path: 'team',
    loadChildren: () => import('./modules/routed/+team/team.module').then((m) => m.TeamModule),
    canActivate: [ PermissionGuard ],
    data: _baseAuthorized
  },
  {
    path: 'domains',
    loadChildren: () => import('./modules/routed/+domains/domains.module').then((m) => m.DomainsModule),
    canActivate: [ PermissionGuard, CloudDNSGuard ],
    data: _baseAuthorized
  },
  {
    path: 'server-park-access',
    loadChildren: () => import('./modules/routed/+server-park-access/server-park-access.module').then((m) => m.ServerParkAccessModule),
    canActivate: [ PermissionGuard ],
    data: {
      permission: createRule(
        { only: [ Roles.ManagerWithSPAccess ] },
        [ '/dashboard' ]
      )
    }
  },
  {
    path: 'ticket',
    loadChildren: () => import('./modules/routed/+tickets-public/tickets-public.module').then((m) => m.TicketsPublicModule)
  },
  {
    path: 'invitation',
    loadChildren: () => import('./modules/routed/+invitation/invitation.module').then((m) => m.InvitationModule)
  },
  {
    path: 'forgotten-password',
    loadChildren: () => import('./modules/routed/+forgotten-password/forgotten-password.module').then((m) => m.ForgottenPasswordModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./modules/routed/+password-recovery/password-recovery.module').then((m) => m.PasswordRecoveryModule)
  },
  {
    path: 'external-auth',
    loadChildren: () => import('./modules/routed/+external-auth/external-auth.module').then((m) => m.ExternalAuthModule)

    // canActivate: [ PermissionGuard ],
    // data: {
    //   permission: createRule(
    //     { only: [ Roles.Authorized ] },
    //     [ '/dashboard' ]
    //   )
    // }
  },
  // login as client not well readable url
  {
    path: 'qmsxauweag',
    loadChildren: () => import('./modules/routed/+login-as-client/login-as-client.module').then((m) => m.LoginAsClientModule)
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/dashboard' }
];
