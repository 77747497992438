import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { extractErrorData, ApiError } from '@zerops/fe/ngrx';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorTranslationService {
  constructor(private _translate: TranslateService) { }

  get$(err: HttpErrorResponse, data?: { [key: string]: string; }) {
    const errData = extractErrorData(err);

    return this._translate
      .get(`error.${errData.code}`, data)
      .pipe(map((message): ApiError => ({
        ...errData,
        message
      })));
  }
}
