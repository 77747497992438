import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
// import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ScrollingModuleExperimental} from '@angular/cdk-experimental/scrolling';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// ML: The package ng2-truncate seems to be unused already.
// import { TruncateModule } from '@yellowspot/ng-truncate';
import {
  TranslateModule,
  TranslateCompiler
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AvatarModule } from '@app/common/avatar';
import { ScrollableModule } from '@app/common/scrollable';
import { TicketStateModule } from '@app/common/ticket-state';
import { TicketMessageModule } from '@app/common/ticket-message';
import { TicketItemModule } from '@app/common/ticket-item';
import { TicketsListContainer } from './tickets-list.container';
import { TicketsListEffect } from './tickets-list.effect';
import { ModuleTokens } from './tickets-list.constant';
import { reducer } from './tickets-list.reducer';

// TODO: root module
@NgModule({
  declarations: [ TicketsListContainer ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    EffectsModule.forFeature([ TicketsListEffect ]),
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    TranslateModule.forChild({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    MatButtonModule,
    MatTooltipModule,
    // TruncateModule,
    TicketStateModule,
    // ScrollDispatchModule,
    ScrollingModule,
    ScrollingModuleExperimental,
    ScrollableModule,
    AvatarModule,
    TicketMessageModule,
    TicketItemModule
  ],
  exports: [ TicketsListContainer ]
})
export class TicketsListModule {

}
