import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { NgrxFormsModule } from 'ngrx-forms';
import { PhoneFormFieldModule } from '@app/common/phone-form-field';
import { CountryFlagModule } from '@app/common/country-flag';
import { FormsModule } from '@app/common/forms';
import { NgrxFormsExtensionsModule } from '@app/common/ngrx-forms-extensions';
import { UserInfoFormComponent } from './user-info-form.component';

@NgModule({
  declarations: [ UserInfoFormComponent ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    NgrxFormsModule,
    TranslateModule.forChild(),
    PhoneFormFieldModule,
    CountryFlagModule,
    NgrxFormsExtensionsModule,
    FormsModule
  ],
  exports: [ UserInfoFormComponent ]
})
export class UserInfoFormModule { }
