import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './users-base.constant';
import { UsersBaseState } from './users-base.model';

const initialState = new UsersBaseState();

export function reducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) {
  return applyEntities<UsersBaseState>(
    state,
    [ ModuleTokens.Name ],
    action
  );
}
