import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogClose } from '@zerops/fe/dialog';
import { DialogKey } from './bulk-payment-invoices-dialog.constant';
import {
  InvoicesBaseActionTypes,
  ConfirmPaymentLocalSuccess,
  PaymentIntentTypes
} from '@app/base/invoices-base';

@Injectable()
export class PaymentInvoicesDialogEffect {

  onConfirmPaymentCloseDialog$ = createEffect(() => this._actions$.pipe(
    ofType<ConfirmPaymentLocalSuccess>(InvoicesBaseActionTypes.ConfirmPaymentLocalSuccess),
    filter(({ payload }) => payload.paymentIntentType === PaymentIntentTypes.Invoice),
    map(() => new DialogClose(DialogKey))
  ));

  constructor(
    private _actions$: Actions
  ) {}
}
