import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateCompiler } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TicketsListModule } from '@app/common/tickets-list';
import { TicketAddModule } from '@app/common/ticket-add';
import { TicketsFilterModule } from '@app/common/tickets-filter';
import { TicketDetailModule } from '@app/common/ticket-detail';
import { TicketTextFormModule } from '@app/common/ticket-text-form';
import { TicketCopyListModule } from '@app/common/ticket-copy-list';
import { SatPopoverModule } from '@zerops/fe/popover';
import { TicketRealizationDateModule } from '@app/common/ticket-realization-date';
import { TicketPriceOfferModule } from '@app/common/ticket-price-offer';
import { TicketsTriggerContainer } from './tickets-trigger.container';
import { TicketsTriggerEffect } from './tickets-trigger.effect';
import { reducer } from './tickets-trigger.reducer';
import { ModuleTokens } from './tickets-trigger.constant';

// TODO: temp
import { FormModule } from '@app/common/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AutosizeModule } from '@app/common/autosize';
import { FilesModule } from '@app/common/files';
import { ContactModule } from '@app/common/contact';
import { ContentTitleModule } from '@app/common/content-title';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { TicketRatingModule } from '@app/common/ticket-rating';
import { TicketStateModule } from '@app/common/ticket-state';
import { TicketAuthorizationModule } from '@app/common/ticket-authorization';
import { WatchTicketModule } from '@app/common/watch-ticket';
import { TicketTopicListModule } from './modules/ticket-topic-list';

@NgModule({
  declarations: [ TicketsTriggerContainer ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatTooltipModule,
    MatRippleModule,
    FlexLayoutModule,
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ TicketsTriggerEffect ]),
    TranslateModule.forChild({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    TicketsListModule,
    TicketsFilterModule,
    TicketAddModule,
    TicketDetailModule,
    TicketTextFormModule,
    SatPopoverModule,
    TicketCopyListModule,
    TicketTopicListModule,
    WatchTicketModule,
    TicketRealizationDateModule,
    TicketPriceOfferModule,

    // TODO: temp (decide which belongs here)
    FilesModule,
    FormModule,
    MatFormFieldModule,
    MatInputModule,
    AutosizeModule,
    ContactModule,
    ContentTitleModule,
    MatListModule,
    TicketRatingModule,
    MatDividerModule,
    TicketStateModule,
    TicketAuthorizationModule
  ],
  exports: [ TicketsTriggerContainer ]
})
export class TicketsTriggerModule {

}
