<span
  class="__symbol  __symbol--left"
  [style.font-size.px]="priceSize"
  [class.is-bold]="mode === 'bold-symbol' || mode === 'bold'"
  *ngIf="!!currencyMap
    && !!currencyMap[currencyId]
    && !!currencyMap[currencyId].displaySymbolBefore">{{ currencyMap[currencyId].symbol }}</span><!--

--><span
  class="__amount"
  [style.font-size.px]="priceSize"
  [class.is-bold]="mode === 'bold-price' || mode === 'bold'"><!--

  -->{{ amount | noDecimals | currency: currencyId : '' : '1.0-0' | trim }}<!--
  --><span
    class="__amount_suffix"
    [style.font-size.px]="symbolSize"
    [class.is-bold]="mode === 'bold-symbol' || mode === 'bold'">,{{ amount | decimals | trim }}</span>

</span>

<span
  class="__symbol  __symbol--right"
  [style.font-size.px]="symbolSize"
  [class.is-bold]="mode === 'bold-symbol' || mode === 'bold'"
  *ngIf="!!currencyMap
    && !!currencyMap[currencyId]
    && !currencyMap[currencyId].displaySymbolBefore">
  {{ currencyMap[currencyId].symbol }}
</span>
