import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';

export enum ActionTypes {
  EntityRequest = '[Domain Record Base] Entity Request',
  EntityFail = '[Domain Record Base] Entity Fail',
  EntityLocalSuccess = '[Domain Record Base] Entity Local Success',

  AddRequest = '[Domain Record Base] Add Request',
  AddFail = '[Domain Record Base] Add Fail',
  AddLocalSuccess = '[Domain Record Base] Add Local Success',

  UpdateRequest = '[Domain Record Base] Update Request',
  UpdateFail = '[Domain Record Base] Update Fail',
  UpdateLocalSuccess = '[Domain Record Base] Update Local Success',

  DeleteRequest = '[Domain Record Base] Delete Request',
  DeleteFail = '[Domain Record Base] Delete Fail',
  DeleteLocalSuccess = '[Domain Record Base] Delete Local Success',

  RestoreRequest = '[Domain Record Base] Restore Request',
  RestoreFail = '[Domain Record Base] Restore Fail',
  RestoreLocalSuccess = '[Domain Record Base] Restore Local Success'
}

export class EntityRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.EntityFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: { id: string; type: string; }) {
    this.progress = {
      add: {
        key: `${ActionTypes.EntityRequest}:${this.payload.id}`,
        type: 'local',
        group: ActionTypes.EntityRequest
      }
    };
  }
}

export class EntityFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.EntityFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }

}

export class EntityLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.EntityLocalSuccess;
  progress: ProgressActionMeta;

  // TODO: interface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public payload: any) {
    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${payload.id}`
    };
  }
}

export class AddRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.AddRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.AddFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.AddRequest,
      type: 'local'
    }
  };

  constructor(public payload: {
    domainId: string;
    type: string;
    data: { name: string; subdomain: boolean; };
  }) { }
}

export class AddFail implements Action, ErrorsAction {
  readonly type = ActionTypes.AddFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.AddRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.AddFail,
        type: 'local',
        data
      }
    };
  }
}

export class AddLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.AddLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.AddRequest
  };

  constructor(public payload: string) { }
}

export class UpdateRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.UpdateRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.UpdateFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.UpdateRequest,
      type: 'local'
    }
  };

  constructor(public payload: {
    id: string;
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    domainId: string;
  }) { }
}

export class UpdateFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.UpdateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.UpdateRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.UpdateFail,
        type: 'local',
        data
      }
    };
  }

}

export class UpdateLocalSuccess implements Action {
  readonly type = ActionTypes.UpdateLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.UpdateRequest
  };

  constructor(public payload: string) { }
}

export class DeleteRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.DeleteRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.DeleteFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.DeleteRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.DeleteRequest
      }
    };
  }
}

export class DeleteFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.DeleteFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: string;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: `${ActionTypes.DeleteRequest}:${id}`,
        type: 'local',
        data
      }
    };

    this.meta = data.message;

    this.progress = {
      remove: `${ActionTypes.DeleteRequest}:${id}`
    };

  }

}

export class DeleteLocalSuccess implements Action {
  readonly type = ActionTypes.DeleteLocalSuccess;
  progress: ProgressActionMeta;

  constructor(id: string) {
    this.progress = {
      remove: `${ActionTypes.DeleteRequest}:${id}`
    };
  }
}

export class RestoreRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.RestoreRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.RestoreFail
  };

  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.RestoreRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.RestoreRequest
      }
    };
  }
}

export class RestoreFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.RestoreFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: string;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.RestoreFail,
        type: 'local',
        data
      }
    };

    this.meta = data.message;

    this.progress = {
      remove: `${ActionTypes.RestoreRequest}:${id}`
    };
  }

}

export class RestoreLocalSuccess implements Action {
  readonly type = ActionTypes.RestoreLocalSuccess;
  progress: ProgressActionMeta;

  constructor(id: string) {
    this.progress = {
      remove: `${ActionTypes.RestoreRequest}:${id}`
    };
  }
}

export type Actions
  = EntityRequest
  | EntityFail
  | EntityLocalSuccess
  | AddRequest
  | AddFail
  | AddLocalSuccess
  | UpdateRequest
  | UpdateFail
  | UpdateLocalSuccess
  | DeleteRequest
  | DeleteFail
  | DeleteLocalSuccess
  | RestoreRequest
  | RestoreFail
  | RestoreLocalSuccess;
