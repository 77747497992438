import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DomainsBaseState, DomainEntity } from './domains-base.model';
import { ModuleTokens } from './domains-base.constant';
import { denormalizeEntity, denormalizeList } from './domains-base.utils';

export const slice = createFeatureSelector<DomainsBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const listIds = createSelector(slice, (s) => s.list);

export const getDomainEntityById = (id: string) => createSelector(
  entities,
  (ents) => {
    if (!ents) { return undefined; }

    return denormalizeEntity(
      id,
      ents[ModuleTokens.Name],
      ents[ModuleTokens.ActiveRecords],
      ents[ModuleTokens.LastRecords]
    ) as DomainEntity;
  }
);

export const listEntities = createSelector(
  listIds,
  entities,
  (ids, ents) => {

    if (!ids || !ents) { return []; }

    return denormalizeList(
      ids,
      ents[ModuleTokens.Name],
      ents[ModuleTokens.ActiveRecords],
      ents[ModuleTokens.LastRecords]
    ) as DomainEntity[];
});

export const soaRecordByDomainId = (id: string) => createSelector(
  slice,
  (s) => s.soaMap[id]
);

export const dnssecKeys = createSelector(
  slice,
  (s) => s ? s.dnssecKeys : undefined
);

export const dnssecKeyById = (id: string) => createSelector(
  dnssecKeys,
  (keys) => keys ? keys[id] : undefined
);
