import { Action } from '@zerops/fe/core';
import {
  ErrorsAction,
  ProgressAction,
  ErrorsActionMeta,
  ProgressActionMeta,
  ApiError
} from '@zerops/fe/ngrx';
import {
  UsersBaseModuleTokens,
  UserEntityNormalized,
  UserEntityEntities,
  UserEntityBase,
  normalizeUsersList
} from '@app/base/users-base';
import {
  ModuleTokens as ClientUserBaseModuleTokens
} from '@app/base/client-user-base/client-user-base.constant';
import { AuthEntity, ExternalAuthApiTypes, ExternalAuthTypes, ExternalAuthLoginValidateResponse } from '@app/base/auth-base';

export enum ActionTypes {
  Authorize = '[Auth Base] Authorize',
  SetLanguage = '[Auth Base] Set Language',
  SetActiveClient = '[Auth Base] Set Active Client',
  CheckSavedToken = '[Auth Base] Check Saved Token',
  InvalidateAuthorization = '[Auth Base] Invalidate Authorization',
  SetReferer = '[Auth Base] Set referer',
  ResetReferer = '[Auth Base] Reset referer',
  RefreshTokenLocalSuccess = '[Auth Base] Refresh Token Local Success',
  SendPasswordChangeRequest = '[Auth Base] Send Password Change Link Request',
  SendPasswordChangeFail = '[Auth Base] Send Password Change Link Fail',
  SendPasswordChangeLocalSuccess = '[Auth Base] Send Password Change Link Local Success',

  // -- external auth
  ExternalAuthConnectRequest = '[Auth Base] External Auth Connect Request',
  ExternalAuthConnectFail = '[Auth Base] External Auth Connect Fail',
  ExternalAuthConnectLocalSuccess = '[Auth Base] External Auth Connect Local Success',

  ExternalAuthLoginRequest = '[Auth Base] External Auth Login Request',
  ExternalAuthLoginFail = '[Auth Base] External Auth Login Fail',
  ExternalAuthLoginLocalSuccess = '[Auth Base] External Auth Login Local Success',

  ExternalAuthConnectValidateRequest = '[Auth Base] External Auth Connect Validate Request',
  ExternalAuthConnectValidateFail = '[Auth Base] External Auth Connect Validate Fail',
  ExternalAuthConnectValidateLocalSuccess = '[Auth Base] External Auth Connect Validate Success',

  ExternalAuthLoginValidateRequest = '[Auth Base] External Auth Login Validate Request',
  ExternalAuthLoginValidateFail = '[Auth Base] External Auth Login Validate Fail',
  ExternalAuthLoginValidateLocalSuccess = '[Auth Base] External Auth Login Validate Success',

  ExternalAuthDeleteRequest = '[Auth Base] External Auth Delete Request',
  ExternalAuthDeleteFail = '[Auth Base] External Auth Delete Fail',
  ExternalAuthDeleteLocalSuccess = '[Auth Base] External Auth Delete Success'

}

export class Authorize implements Action {
  readonly type = ActionTypes.Authorize;
  payload: {
    auth: AuthEntity;
    user: UserEntityNormalized,
    userId: string;
    clientId?: string;
    redirect: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entities: any
  };

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entities: any,
    userId: string,
    auth: AuthEntity,
    clientId?: string,
    redirect = true
  ) {

    const user: UserEntityNormalized = entities[UsersBaseModuleTokens.Name][userId];

    const firstClientUserClientId = user.clientUserList.length === 1
      ? entities[ClientUserBaseModuleTokens.Name][user.clientUserList[0]].clientId
      : undefined;

    this.payload = {
      auth,
      userId,
      user,
      entities,
      redirect,
      // client is either explicitly set by this action,
      // or, if there's only one client paired to the user, it's selected automatically.
      // if none of those have any value, it triggers a client selection dialog
      clientId: [clientId, firstClientUserClientId].find((value) => !!value)
    };
  }
}

export class SetLanguage implements Action {
  readonly type = ActionTypes.SetLanguage;

  constructor(public payload: string) { }
}

export class SetActiveClient implements Action {
  readonly type = ActionTypes.SetActiveClient;

  /**
    @param meta Should redirect?
  */
  constructor(public payload: string, public meta = false) { }
}

export class CheckSavedToken implements Action {
  readonly type = ActionTypes.CheckSavedToken;
}

export class InvalidateAuthorization implements Action {
  readonly type = ActionTypes.InvalidateAuthorization;

  // TODO: add meta with message? create special action for each?
  /**
    @param payload Whether invalidation happened automatically (from refresh token dispatch etc)
  */
  constructor(public payload = false) { }
}

export class SetReferer implements Action {
  readonly type = ActionTypes.SetReferer;

  constructor(public payload: string) { }
}

export class ResetReferer implements Action {
  readonly type = ActionTypes.ResetReferer;
}

export class RefreshTokenLocalSuccess implements Action {
  readonly type = ActionTypes.RefreshTokenLocalSuccess;

  /**
   * @param paylaod new token
   */
  constructor(public payload: string) { }
}

export class SendPasswordChangeRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.SendPasswordChangeRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.SendPasswordChangeFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.SendPasswordChangeRequest,
      type: 'local'
    }
  };
}

export class SendPasswordChangeFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.SendPasswordChangeFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SendPasswordChangeRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.SendPasswordChangeFail,
        type: 'local',
        data
      }
    };
  }

}

export class SendPasswordChangeLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.SendPasswordChangeLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.SendPasswordChangeRequest
  };
}

// -- external auth
export class ExternalAuthConnectRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthConnectRequest;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(public payload: ExternalAuthTypes) {

    this.progress = {
      add: {
        key: ActionTypes.ExternalAuthConnectRequest + payload,
        type: 'local'
      }
    };

    this.errors = {
      remove: ActionTypes.ExternalAuthConnectFail
    };

  }
}

export class ExternalAuthConnectFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthConnectFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(public payload: ApiError, meta: ExternalAuthTypes) {
    this.errors = {
      add: {
        key: ActionTypes.ExternalAuthConnectFail,
        type: 'local',
        data: payload
      }
    };

    this.progress = {
      remove: ActionTypes.ExternalAuthConnectRequest + meta
    };
  }
}

export class ExternalAuthConnectLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ExternalAuthConnectLocalSuccess;
  progress: ProgressActionMeta;

  constructor(public payload: string, meta: ExternalAuthTypes) {
    this.progress = {
      remove: ActionTypes.ExternalAuthConnectRequest + meta
    };
  }
}

export class ExternalAuthLoginRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthLoginRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ExternalAuthLoginFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ExternalAuthLoginRequest,
      type: 'local'
    }
  };

  constructor(public payload: ExternalAuthTypes) { }
}

export class ExternalAuthLoginFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthLoginFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ExternalAuthLoginRequest
  };

  constructor(public payload: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ExternalAuthLoginFail,
        type: 'local',
        data: payload
      }
    };
  }
}

export class ExternalAuthLoginLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ExternalAuthLoginLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ExternalAuthLoginRequest
  };

  constructor(public payload: string) { }
}

export class ExternalAuthConnectValidateRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthConnectValidateRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ExternalAuthConnectValidateFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ExternalAuthConnectValidateRequest,
      type: 'local'
    }
  };

  constructor(public payload: { type: ExternalAuthTypes; state: string; code: string; }) {}
}

export class ExternalAuthConnectValidateFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthConnectValidateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ExternalAuthConnectValidateRequest
  };

  constructor(public payload: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ExternalAuthConnectValidateFail,
        type: 'local',
        data: payload
      }
    };
  }
}

export class ExternalAuthConnectValidateLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ExternalAuthConnectValidateLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ExternalAuthConnectValidateRequest
  };
  payload: {
    entities: UserEntityEntities;
  };

  constructor(user: UserEntityBase) {
    const { entities } = normalizeUsersList([ user ]);
    this.payload = { entities };
  }
}

export class ExternalAuthLoginValidateRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthLoginValidateRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ExternalAuthLoginValidateFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ExternalAuthLoginValidateRequest,
      type: 'local'
    }
  };

  constructor(public payload: { type: ExternalAuthTypes; state: string; code: string; }) {}
}

export class ExternalAuthLoginValidateFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthLoginValidateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ExternalAuthLoginValidateRequest
  };

  constructor(public payload: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ExternalAuthLoginValidateFail,
        type: 'local',
        data: payload
      }
    };
  }
}

export class ExternalAuthLoginValidateLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ExternalAuthLoginValidateLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ExternalAuthLoginValidateRequest
  };
  payload: {
    entities: UserEntityEntities;
    auth: AuthEntity;
    userId: string;
    showHint: boolean;
  };

  constructor(res: ExternalAuthLoginValidateResponse) {
    const { entities, result } = normalizeUsersList([ res.user ]);
    this.payload = { entities, auth: res.auth, userId: result[0], showHint: !!res.showHint };
  }
}

export class ExternalAuthDeleteRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthDeleteRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ExternalAuthDeleteFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: ExternalAuthApiTypes) {
    this.progress = {
      add: {
        key: ActionTypes.ExternalAuthDeleteRequest + payload,
        type: 'local'
      }
    };
  }
}

export class ExternalAuthDeleteFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ExternalAuthDeleteFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(public payload: ApiError, meta: ExternalAuthApiTypes) {
    this.errors = {
      add: {
        key: ActionTypes.ExternalAuthDeleteFail,
        type: 'local',
        data: payload
      }
    };

    this.progress = {
      remove: ActionTypes.ExternalAuthDeleteRequest + meta
    };
  }
}

export class ExternalAuthDeleteLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ExternalAuthDeleteLocalSuccess;
  progress: ProgressActionMeta;
  payload: {
    entities: UserEntityEntities;
  };

  constructor(user: UserEntityBase, meta: ExternalAuthApiTypes) {
    const { entities } = normalizeUsersList([ user ]);
    this.payload = { entities };

    this.progress = {
      remove: ActionTypes.ExternalAuthDeleteRequest + meta
    };
  }
}

export type Actions
  = Authorize
  | SetLanguage
  | SetActiveClient
  | CheckSavedToken
  | InvalidateAuthorization
  | SetReferer
  | ResetReferer
  | RefreshTokenLocalSuccess
  | SendPasswordChangeRequest
  | SendPasswordChangeFail
  | SendPasswordChangeLocalSuccess

  // -- external auth
  | ExternalAuthConnectRequest
  | ExternalAuthConnectFail
  | ExternalAuthConnectLocalSuccess
  | ExternalAuthLoginRequest
  | ExternalAuthLoginFail
  | ExternalAuthLoginLocalSuccess

  | ExternalAuthConnectValidateRequest
  | ExternalAuthConnectValidateFail
  | ExternalAuthConnectValidateLocalSuccess
  | ExternalAuthLoginValidateRequest
  | ExternalAuthLoginValidateFail
  | ExternalAuthLoginValidateLocalSuccess

  | ExternalAuthDeleteRequest
  | ExternalAuthDeleteFail
  | ExternalAuthDeleteLocalSuccess;
