import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { coerceNumberProperty, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { map, distinctUntilChanged, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import animateScrollTo from 'animated-scroll-to';

@Component({
  selector: 'vshcz-scrollable',
  templateUrl: './scrollable.component.html',
  styleUrls: [ './scrollable.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollableComponent implements OnInit, OnDestroy {
  @Input()
  set height(v) {
    if (typeof v === 'number') {
      this.heightFormatted = v + 'px';
    } else {
      this.heightFormatted = v;
    }

    this._height = v;
  }

  @Input()
  set maxHeight(v) {
    if (typeof v === 'number') {
      this.maxHeightFormatted = v + 'px';
    } else {
      this.maxHeightFormatted = v;
    }

    this._maxHeight = v;
  }

  @Input()
  set minHeight(v) {
    if (typeof v === 'number') {
      this.minHeightFormatted = v + 'px';
    } else {
      this.minHeightFormatted = v;
    }

    this._minHeight = v;
  }

  @Input()
  set maxScrollDuration(v) {
    this._maxScrollDuration = coerceNumberProperty(v);
  }
  get maxScrollDuration() {
    return this._maxScrollDuration;
  }

  @Input()
  set enableEdges(v) {
    this._enableEdges = coerceBooleanProperty(v);
  }
  get enableEdges() {
    return this._enableEdges;
  }

  @ViewChild('areaRef', { static: true })
  areaRef: ElementRef;

  @ViewChild(CdkScrollable, { static: true })
  areaRefScrollable: CdkScrollable;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxHeightFormatted: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minHeightFormatted: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  heightFormatted: any;
  elementHeight: number;
  areaHeight: number;
  clientHeight: number;
  scrollTop: number;
  onTopEdge = false;
  onBottomEdge = true;
  private _enableEdges = false;
  private _maxHeight: number;
  private _minHeight: number;
  private _height: number;
  private _scrollTopSubscription$: Subscription;
  private _maxScrollDuration = 1500;

  constructor(
    private _el: ElementRef,
    private _cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // TODO: optimize
    if (this.enableEdges) {
      this._scrollTopSubscription$ = this.areaRefScrollable
        .elementScrolled()
        .pipe(
          map(() => this.areaRef.nativeElement.scrollTop),
          startWith(0),
          distinctUntilChanged()
        )
        .subscribe((scrollTop) => {
          this.scrollTop = scrollTop;
          this.areaHeight = this.areaRef.nativeElement.scrollHeight;
          this.clientHeight = this.areaRef.nativeElement.clientHeight;

          this.refreshEdges();
        });
    }

  }

  ngOnDestroy() {
    if (this._scrollTopSubscription$) {
      this._scrollTopSubscription$.unsubscribe();
    }
  }

  scrollToBottom(duration?: number) {
    if (duration === undefined) {
      duration = this._maxScrollDuration;
    }

    this.scrollTo(
      this.areaRef.nativeElement.scrollHeight + 1000,
      undefined,
      duration
    );
  }

  scrollToTop() {
    this.scrollTo(0);
  }

  scrollTo(
    offset: number,
    onComplete: () => void = () => undefined,
    duration?: number
  ) {
    animateScrollTo(
      offset,
      {
        element: this.areaRef.nativeElement,
        maxDuration: duration !== undefined
          ? duration
          : this._maxScrollDuration,
        minDuration: duration !== undefined
          ? 0
          : 250,
        onComplete
      }
    );
  }

  refreshEdges(hard = false) {
    if (hard) {
      this.onTopEdge = false;
      this.onBottomEdge = true;
      this.scrollTop = this.areaRef.nativeElement.scrollTop;
      this._cdRef.detectChanges();
    }

    if (this.scrollTop > 2) {
      if (!this.onTopEdge) {
        this.onTopEdge = true;
        this._cdRef.detectChanges();
      }
    } else {
      if (this.onTopEdge) {
        this.onTopEdge = false;
        this._cdRef.detectChanges();
      }
    }

    if ((this.scrollTop + this.clientHeight) < (this.areaHeight - 2)) {
      if (!this.onBottomEdge) {
        this.onBottomEdge = true;
        this._cdRef.detectChanges();
      }
    } else {
      if (this.onBottomEdge && this.areaHeight !== this.clientHeight) {
        this.onBottomEdge = false;
        this._cdRef.detectChanges();
      }
    }
  }

}
