import animateScrollTo from 'animated-scroll-to';

export const offset = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

export const scrollToErrorInput = (ofst: number, el: HTMLElement) => {
  animateScrollTo(ofst, {
    onComplete: (_isCanceledByUserAction: boolean) => {
      if (el) {
        el.focus();
      }
    }
  });
};

export interface HashMap<T> {
 [index: string]: T;
}

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

export function abbreviateNumber(number) {

    // what tier? (determines SI symbol)
    // eslint-disable-next-line no-bitwise
    const tier = Math.log10(number) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier === 0) { return number; }

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hashMap<T>(arr: any[], property: string) {
  return arr.reduce((obj, curr) => {
    obj[curr[property]] = curr;
    return obj;
  }, {}) as HashMap<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toBoolean(value: any): boolean {
  const stringValue = value != null ? value.toString().toLowerCase() : '';

  switch (stringValue) {
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
    case '':
      return false;
    default:
      console.warn('could not convert to boolean');
      return value;
  }
}
