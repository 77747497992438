import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgStringPipesModule, NgTrimPipeModule } from 'angular-pipes';
import { PriceComponent } from './price.component';
import { DecimalsPipe } from './decimals.pipe';
import { NoDecimalsPipe } from './nodecimals.pipe';

@NgModule({
  declarations: [
    PriceComponent,
    DecimalsPipe,
    NoDecimalsPipe
  ],
  imports: [
    CommonModule,
    NgStringPipesModule,
    NgTrimPipeModule
  ],
  exports: [ PriceComponent ]
})
export class PriceModule {
}
