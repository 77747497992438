import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CLOUDDNS_RECEIVER_ID } from '@app/common/clouddns-websockets';
import {
  DomainEntityBase,
  DomainEntity,
  DomainRecordSoa,
  DnsSecKeyEntityBase
} from './domains-base.model';

@Injectable({
  providedIn: 'root'
})
export class DomainsBaseApi {
  constructor(private _http: HttpClient) { }

  list$(clientId: string) {
    return this._http.post<{ items: DomainEntityBase[] }>(
      `/clouddns/domain/search`,
      {
        receiverId: CLOUDDNS_RECEIVER_ID,
        search: [
          {
            name: 'clientId',
            operator: 'eq',
            value: clientId
          }
        ],
        limit: 10000
      }
    );
  }

  registerSearchForEntity$(id: string, clientId: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(
      `/clouddns/domain/search`,
      {
        receiverId: CLOUDDNS_RECEIVER_ID,
        subscriptionName: 'clouddnsRecords',
        search: [
          {
            name: 'clientId',
            operator: 'eq',
            value: clientId
          },
          {
            name: 'id',
            operator: 'eq',
            value: id
          }
        ]
      }
    );
  }

  entity$(id: string) {
    return this._http.get<DomainEntity>(
      `/clouddns/domain/${id}`
    );
  }

  publish$(id: string, soaTtl?: number) {
    return this._http.put<DomainEntity>(
      `/clouddns/domain/${id}/publish`,
      { soaTtl }
    );
  }

  // TODO: interface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add$(data: any, clientId: string) {
    return this._http.post<DomainEntity>(
      `/clouddns/domain`,
      {
        ...data,
        clientId
      }
    );
  }

  delete$(id: string) {
    return this._http.delete<DomainEntity>(
      `/clouddns/domain/${id}`
    );
  }

  restore$(id: string) {
    return this._http.put<DomainEntity>(
      `/clouddns/domain/${id}/recover`,
      {}
    );
  }

  deactivate$(id: string) {
    return this._http.put<DomainEntity>(
      `/clouddns/domain/${id}/deactivate`,
      {}
    );
  }

  activate$(id: string) {
    return this._http.put<DomainEntity>(
      `/clouddns/domain/${id}/activate`,
      {}
    );
  }

  resetChanges$(id: string) {
    return this._http.put<DomainEntity>(
      `/clouddns/domain/${id}/reset-changes`,
      {}
    );
  }

  soaRequest$(id: string) {
    return this._http.get<DomainRecordSoa>(
      `/clouddns/domain/${id}/record-soa`
    );
  }

  soaUpdate$(id: string, payload: Partial<DomainRecordSoa>) {
    return this._http.put<DomainRecordSoa>(
      `/clouddns/domain/${id}/record-soa`,
      payload
    );
  }

  getDnssecKey$(id: string) {
    return this._http.get<{ cryptokey: DnsSecKeyEntityBase[] }>(
      `/clouddns/domain/${id}/public-key`
    );
  }
}
