import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NgrxRouterEffect } from './ngrx-router.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ NgrxRouterEffect ])
  ]
})
export class NgrxRouterModule {}
