import { Component, Input } from '@angular/core';
import { BaseClass } from '@zerops/fe/core';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, merge } from 'rxjs';
import {
  takeUntil,
  map,
  withLatestFrom,
  switchMap,
  startWith
} from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { State } from '@app/models';
import { UploadSelectItem } from '@app/common/upload';
import { AddToQueue, RefusedToQueue, RemoveFromQueue } from './files.action';
import { queueFiles } from './files.selector';

@Component({
  selector: 'vshcz-files',
  templateUrl: './files.container.html',
  styleUrls: [ './files.container.scss' ]
})
export class FilesContainer extends BaseClass {
  // # Event Streams
  onSelect$ = new Subject<UploadSelectItem[]>();
  onRemoveFromQueue$ = new Subject<string>();

  // # Data
  // -- angular
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('instanceId')
  @ObservableInput()
  instanceId$!: Observable<string>;

// eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('maxCount')
  @ObservableInput()
  maxCount$!: Observable<number>;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('maxSize')
  @ObservableInput()
  maxSize$!: Observable<number>;

  @Input()
  label: string;

  // -- async
  files$ = this.instanceId$.pipe(
    switchMap((instanceId) => this._store.pipe(select(queueFiles(instanceId))))
  );

  // # Action Streams
  private _selectAction$ = this.onSelect$.pipe(
    withLatestFrom(
      this.files$,
      this.instanceId$,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.maxCount$.pipe(startWith(undefined as any)),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.maxSize$.pipe(startWith(undefined as any))
    ),
    map(([
      files,
      currentFiles,
      instanceId,
      maxCount,
      maxSize
    ]) => {

      const totalFiles = files.length + currentFiles.length;
      const totalSize = this._getTotalUploadedFilesSize(currentFiles, files);

      if (maxCount && totalFiles > maxCount) {
        return new RefusedToQueue('count');
      }

      if (maxSize && totalSize > maxSize) {
        return new RefusedToQueue('size');
      }

      return new AddToQueue({
        instanceId,
        files
      });

    })
  );
  private _removeFromQueueAction$ = this.onRemoveFromQueue$.pipe(
    withLatestFrom(this.instanceId$),
    map(([ uuid, instanceId ]) => new RemoveFromQueue({
      instanceId,
      uuid
    }))
  );

  constructor(private _store: Store<State>) {
    super();

    merge(
      this._removeFromQueueAction$,
      this._selectAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);
  }

  private _getTotalUploadedFilesSize(
    current: UploadSelectItem[],
    added: UploadSelectItem[]
  ) {
    const currentSize = current.reduce((total, item) => {
      return total + item.meta.size;
    }, 0);

    const addedSize = added.reduce((total, item) => {
      total = total + item.meta.size;
      return total;
    }, 0);

    return currentSize + addedSize;
  }

}
