import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TicketsEntityLight, TicketsEntity, TicketTopicEntity } from './tickets-base.model';
import { TicketRatingFormValue } from '@app/common/ticket-rating/ticket-rating.model';

@Injectable({
  providedIn: 'root'
})
export class TicketsBaseApi {
  constructor(private _http: HttpClient) { }

  list$(clientId: string) {
    return this._http.get<{ ticketList: TicketsEntityLight[] }>(
      `/api/public/client/${clientId}/ticket`
    );
  }

  entity$(id: string) {
    return this._http.get<TicketsEntity>(
      `/api/public/ticket/${id}`
    );
  }

  message$(
    id: string,
    messageText: string,
    extId: string,
    attachments: string[] = []
  ) {
    return this._http.post<TicketsEntity>(
      `/api/public/ticket/${id}/message`,
      {
        messageText,
        attachments,
        extId
      }
    );
  }

  attachment$(id: string) {
    return this._http.get<{ token: string }>(
      `/api/public/ticket-message-attachment/${id}`
    );
  }

  unlock$(id: string) {
    return this._http.put<TicketsEntity>(
      `/api/public/ticket/${id}/unlock`,
      {}
    );
  }

  approvePriceOffer$(id: string, lastAdminTicketMessageId: string, clientId: string) {
    return this._http.put<TicketsEntity>(
      `/api/public/ticket/${id}/confirm-price-offer`,
      {
        lastAdminTicketMessageId,
        clientId
      }
    );
  }

  rejectPriceOffer$(id: string, clientId: string, messageText: string) {
    return this._http.put<TicketsEntity>(
      `/api/public/ticket/${id}/reject-price-offer`,
      {
        messageText,
        clientId
      }
    );
  }

  confirmRealizationDate$(id: string, clientId: string) {
    return this._http.put<TicketsEntity>(
      `/api/public/ticket/${id}/confirm-realization-date`,
      {
        clientId
      }
    );
  }

  rejectRealizationDate$(id: string, clientId: string, messageText: string) {
    return this._http.put<TicketsEntity>(
      `/api/public/ticket/${id}/reject-realization-date`,
      {
        messageText,
        clientId
      }
    );
  }

  updateCopyList$(id: string, copyList: string[]) {
    return this._http.put<TicketsEntity>(
      `/api/public/ticket/${id}/copy-list`,
      { copyList }
    );
  }

  ticketTopicList$(clientId: string) {
    return this._http.get<{ ticketTopic: TicketTopicEntity[]; }>(
      `/api/public/client/${clientId}/ticket-topic`
    );
  }

  rate$(
    id: string,
    clientId: string,
    rating: TicketRatingFormValue
  ) {
    return this._http.put<{ success: boolean }>(
      `/api/public/ticket/${id}/rate`,
      { clientId, ...rating }
    );
  }

  authorize$(id: string, clientId?: string) {
    return this._http.put<TicketsEntity>(
      `api/public/ticket/${id}/authorize`,
      {
        clientId: clientId ? clientId : undefined
      }
    );
  }

  secureCommunicationCheck$(email: string, clientId: string) {
    return this._http.post<{ success: boolean; }>(
      `/api/public/user/secure-communication-check`,
      { email, clientId }
    );
  }

}
