import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { Subject, combineLatest } from 'rxjs';
import {
  mergeMap,
  takeUntil,
  map,
  filter,
  distinctUntilChanged,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import keys from 'lodash-es/keys';
import orderBy from 'lodash-es/orderBy';
import { State } from '@app/models';
import {
  authActiveUserServicesActiveGrouped,
  identity,
  authActiveClientUser,
  activeManagedServicesAliases,
  authorType,
  cloudDnsRoleCode,
  authSlice
} from '@app/base/auth-base';
import { clientServicesListEntities } from '@app/base/client-services-base';
import { ServicesTypes, ServiceCategory } from '@app/base/services-base';
import { SetTicketTriggerState, TicketTriggerOpen } from '@app/common/tickets-trigger';
import { currencyMap } from '@app/common/settings';
import { environment } from 'environments/environment';
import { environment as environmentForDevel } from 'environments/environment.devel';
import { toBoolean } from 'utils';
import { FormControl } from '@angular/forms';

// Encoding UTF8 ⇢ base64
function b64EncodeUnicode(str: string) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(_, p1) {
    return String.fromCharCode(parseInt(p1, 16));
  }));
}

const localStorageKey = 'localhost_redirect';

@Component({
  selector: 'vshcz-services-card',
  templateUrl: './services-card.container.html',
  styleUrls: [ './services-card.container.scss' ]
})
export class ServicesCardContainer extends BaseClass {

  // # Event streams
  onContactSupportForNewService$ = new Subject<void>();

  // # Data
  // -- sync
  serviceTypeExternalMap = keys(ServicesTypes).reduce((obj, key) => {
    const item = ServicesTypes[key];
    obj[item.key] = item.type === ServiceCategory.External;
    return obj;
  }, {});
  enableCDN = toBoolean(environment.enableCDN);
  enableCloudMail = toBoolean(environment.enableCloudMail);
  isProductionEnv = environment.envName === 'prod';
  slideControl = new FormControl(this._getLocalhostRedirect());

  // -- async
  groupedServices$ = this._store.pipe(select(authActiveUserServicesActiveGrouped));
  activeManagedServices$ = this._store.pipe(
    select(activeManagedServicesAliases),
    map((s) => s.join(','))
  );
  graphsAndSettingsServices$ = this.groupedServices$.pipe(
    map((groupedServices) => groupedServices.filter(
      (group) => !this.serviceTypeExternalMap[group.key] && group.key !== 'CDN')
    )
  );
  latestClientServices$ = this._store.pipe(
    select(clientServicesListEntities),
    map((clientServices) => {

      if (clientServices && clientServices.length) {
        const filteredClientServices = clientServices.filter(
          (service) => service.clientZoneStatus !== 'BEING_ACTIVATED'
        );
        return orderBy(filteredClientServices, [ 'activationDate' ], [ 'desc' ]).slice(0, 5);
      }

      return [];
    })
  );
  currencyMap$ = this._store.pipe(
    select(currencyMap)
  );
  clientUser$ = this._store.pipe(
    select(authActiveClientUser),
    filter((user) => !!user)
  );
  authorType$ = this._store.pipe(select(authorType));
  cloudDnsRoleCode$ = this._store.pipe(select(cloudDnsRoleCode));
  showCloudDns$ = combineLatest([
    this.clientUser$,
    this.authorType$,
    this.cloudDnsRoleCode$
  ]).pipe(
    map(([ clientUser, author, code ]) => (clientUser.cloudDnsEnabled && author !== 'BACKOFFICE')
      || (clientUser.cloudDnsEnabled
        && author === 'BACKOFFICE'
        && (code === 'MODIFY_ALL_DNS' || code === 'MODIFY_CLIENT_DNS'))
    )
  );
  cdnServices$ = this.clientUser$.pipe(
    filter((d) => !!d),
    switchMap((clientUser) => this._store.pipe(select(authSlice)).pipe(
      filter((d) => !!d),
      map((auth) => ({ clientUser, auth })))
    ),
    map(({ clientUser, auth }) => (!!clientUser.cdnEnabled
      && !!clientUser.client
      && !!clientUser.client.connectedServiceList
      && !!clientUser.client.connectedServiceList.length)
      ? clientUser.client.connectedServiceList
        .filter(
          (itm) => itm.standardService
            && itm.standardService.isActive
            && itm.standardService.code === 'CDN'
            && itm.clientZoneStatus === 'ACTIVE'
        )
        .map((itm) => {
          const path = `${auth.accessToken}|${auth.refreshToken}|${itm.id}|${clientUser.id}`;
          const encodedPath = encodeURIComponent(b64EncodeUnicode(path));
          const forceRedirectToLocalhost = this._getLocalhostRedirect();
          return {
            url: `${(forceRedirectToLocalhost ? environmentForDevel : environment).cdnAuthRedirectUrl}/${encodedPath}`,
            service: itm
          };
        })
      : []
    ),
    distinctUntilChanged()
  );
  showCloudMail$ = this.clientUser$.pipe(
    map((clientUser) => !!clientUser.cloudMailEnabled
      && !!clientUser.client
      && !!clientUser.client.connectedServiceList
      && !!clientUser.client.connectedServiceList.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (itm: any) => itm.standardService && itm.standardService.isActive && itm.standardService.code === 'CLOUD_MAIL'
      )
    ),
    distinctUntilChanged()
  );
  cloudMailUrl$ = this.showCloudMail$.pipe(
    filter((d) => !!d),
    switchMap(() => this._store.pipe(select(authSlice))),
    filter((d) => !!d),
    withLatestFrom(this.clientUser$),
    map(([ d, clientUser ]) => {
      const service = clientUser.client.connectedServiceList.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (itm: any) => itm.standardService && itm.standardService.isActive && itm.standardService.code === 'CLOUD_MAIL'
      );
      const path = `${d.accessToken}|${service.id}`;
      const encodedPath = encodeURIComponent(b64EncodeUnicode(path));
      const forceRedirectToLocalhost = this._getLocalhostRedirect();
      return `${(forceRedirectToLocalhost ? environmentForDevel : environment).cloudMailAuthRedirectUrl}/${encodedPath}`;
    })
  );
  showGraphAndSettings$ = combineLatest([
    this.graphsAndSettingsServices$,
    this.showCloudDns$,
    this.showCloudMail$,
    this.cdnServices$
  ]).pipe(
    map(([ services, showCloudDns, showCloudMail, cdnServices ]) => showCloudDns
      || (cdnServices && cdnServices.length)
      || showCloudMail
      || (services && services.length)
    )
  );
  languageId$ = this._store.pipe(
    select(identity),
    map((user) => user && user.language
      ? user.language.id
      : undefined)
  );

  @Input()
  settingsLayout: string | number;

  @Input()
  financialLayout: string | number;

  @Input()
  showSettings: boolean;

  @Input()
  showFinancial: boolean;

  // -- angular
  @Output()
  contentUpdated = new EventEmitter<void>();

  @Output()
  contentClicked = new EventEmitter<void>();

  // # Action streams
  private _contactSupportForNewServiceAction$ = this.onContactSupportForNewService$.pipe(
    mergeMap(() => [
      new SetTicketTriggerState({
        state: 1,
        meta: 'SERVICE_NEW'
      }),
      new TicketTriggerOpen()
    ])
  );

  constructor(private _store: Store<State>) {
    super();

    // emit that content changed so menu can resize the pop
    combineLatest([
      this.latestClientServices$,
      this.groupedServices$
    ]).pipe(takeUntil(this._ngOnDestroy$)).subscribe(
      () => this.contentUpdated.emit()
    );

    // # Store Dispatcher
    this._contactSupportForNewServiceAction$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

    this.slideControl.valueChanges
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe((value) =>
        localStorage.setItem(localStorageKey, value ? '1' : '0')
      );
  }

  _trackBy(index: number) {
    return index;
  }

  private _getLocalhostRedirect() {
    const localStorageVar = localStorage.getItem(localStorageKey);
    return !this.isProductionEnv
      && parseInt(localStorageVar
          ? localStorageVar
          : '0',
        10
      ) === 1;
  }
}
