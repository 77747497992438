import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './services-base.constant';
import { ServicesBaseState } from './services-base.model';

const initialState = new ServicesBaseState();

export function reducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: any
) {
  return applyEntities<ServicesBaseState>(
    state,
    [ ModuleTokens.Name ],
    actions
  );
}
