import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class ApiInterceptorInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
    const endpoint = environment.apiUrl;
    if (
      !!endpoint
      && (
           request.url.startsWith('/api')
        || request.url.startsWith('/clouddns')
        || request.url.startsWith('/stream')
      )
      && !request.url.includes(endpoint)
    ) {
      request = request.clone({
        url: endpoint + request.url
      });
    }
    return next.handle(request);
  }

}

export const apiInterceptorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiInterceptorInterceptor,
  multi: true
};
