import {
  Component,
  OnInit,
  HostBinding,
  ViewChild,
  Input
} from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { Subject, merge } from 'rxjs';
import { map, takeUntil, withLatestFrom, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SatPopover } from '@zerops/fe/popover';
import { State } from '@app/models';
import { UserEntity } from '@app/base/users-base';
import {
  InvalidateAuthorization,
  identity,
  SendPasswordChangeRequest,
  authActiveUserClientId,
  SetActiveClient
} from '@app/base/auth-base';
import { ClientUserEntity } from '@app/base/client-user-base';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

const DEFAULT_TRANSITION  = '300ms cubic-bezier(0.25, 0.8, 0.25, 1)';

export const transformContent = trigger('transformContent', [
  transition(':enter', [
    style({ transform: 'translateY(-6px)' }),
    animate(DEFAULT_TRANSITION, style({ transform: 'translateY(0)' }))
  ]),
  transition(':leave', [
    animate(DEFAULT_TRANSITION, style({ opacity: 0, transform: 'translateY(3px)' }))
  ])
]);

interface ExtendedClientUserEntity extends ClientUserEntity {
  _translatedRoleName: string;
}

interface ExtendedUserEntity extends UserEntity {
  _activeUserClient: ExtendedClientUserEntity;
  _clientUserList: ExtendedClientUserEntity[];
}

@Component({
  selector: 'vshcz-logged-user',
  templateUrl: './logged-user.container.html',
  styleUrls: [ './logged-user.container.scss' ],
  animations: [ transformContent ]
})
export class LoggedUserContainer extends BaseClass implements OnInit {
  // # Event Streams
  onLogout$ = new Subject<void>();
  onChangePassword$ = new Subject<void>();
  onChangeClient$ = new Subject<string>();

  // # Data
  // -- sync
  popOpen = false;
  identity: ExtendedUserEntity;

  // -- angular
  @Input()
  set compact(v) {
    this._compact = coerceBooleanProperty(v);
  }
  get compact() {
    return this._compact;
  }

  // -- async
  identity$ = this._store.pipe(
    select(identity),
    filter((i) => !!i),
    withLatestFrom(
      this._store.pipe(select(authActiveUserClientId)),
      this._translate.get([
        'common.roles.MANAGER',
        'common.roles.TECHNICAL_USER',
        'common.roles.FINANCIAL_USER'
      ])
    ),
    map(([ idnt, activeClientId, translations ]) => {
      const projectedClientUserList = idnt.clientUserList.map(
        (c) => ({
          ...c,
          _translatedRoleName: translations[`common.roles.${c.roleCode}`]
        })
      );

      return {
        ...idnt,
        _clientUserList: projectedClientUserList,
        _activeUserClient: projectedClientUserList.find(
          (c) => c.clientId === activeClientId
        )
      };
    })
  );

  // -- angular
  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;

  @HostBinding('class.has-open-pop')
  get classIsOpen() {
    return this.popOpen;
  }

  // -- inner
  private _compact: boolean;

  // # Action Streams
  private _logoutAction$ = this.onLogout$.pipe(
    map(() => new InvalidateAuthorization(true))
  );
  private _changeClientAction$ = this.onChangeClient$.pipe(
    map((id) => new SetActiveClient(id, true))
  );
  private _changePasswordAction$ = this.onChangePassword$.pipe(
    map(() => new SendPasswordChangeRequest())
  );

  constructor(
    private _store: Store<State>,
    private _translate: TranslateService
  ) {
    super();

    // # Data Resolvers
    this.identity$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe((d) => this.identity = d);

    // # Action Dispatcher
    merge(
      this._logoutAction$,
      this._changeClientAction$,
      this._changePasswordAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

    super.ngOnInit();
  }

  openPop() {
    this.popOpen = true;
    this.popRef.open();
  }

  onPopClose() {
    this.popOpen = false;
  }
}
