<div
  fxLayout="row wrap"
  fxLayoutAlign="start start"
  fxLayoutAlign.lt-sm="center center"
  fxLayoutGap="20px grid">

  <div
    [fxFlex]="credit && credit > 0
      ? '157px'
      : '157px'"
    fxFlex.lt-sm="100"
    class="u-text--center">
    <ngx-qrcode
      class="__qr"
      [value]="qrData">
    </ngx-qrcode>
  </div>

  <div
    fxFlex
    fxFlex.lt-sm="100"
    [class.u-text--center]="media.isActive('lt-sm')">
    <h5
      class="__title __title--no-left-padding"
      [ngStyle]="{ 'padding-top.px': credit === 0 || notApplyCredit ? 12 : 0 }"
      [innerHtml]="(isFeePayment ? 'bulkPayment.overviewFeesTitle' : 'bulkPayment.overviewTitle') | translate: { count: unpaidCount }">
    </h5>

    <span class="__price">

      <vshcz-bulk-payment-price
        [notApplyCredit]="notApplyCredit"
        [currencyId]="currencyId"
        [currencyMap]="currencyMap"
        [credit]="credit"
        [total]="total">
      </vshcz-bulk-payment-price>

    </span>

    <button
      class="__dialog-button-transfer"
      mat-flat-button
      (click)="onPaymentDialogOpen$.next({
        instanceKind: instanceKind,
        key: isFeePayment ? feesDialogKey : invoicesDialogKey,
        paymentKind: paymentKinds.Transfer,
        paymentIntentType: isFeePayment ? paymentIntentTypes.Fee : paymentIntentTypes.Invoice
      })">
      <mat-icon class="__dialog-button-icon">assignment</mat-icon>
      <span translate="bulkPayment.dialogTriggerButton"></span>
    </button>

    <button
      *ngIf="cardPaymentEnabled"
      class="__dialog-button-card"
      mat-flat-button
      (click)="onPaymentDialogOpen$.next({
        instanceKind: instanceKind,
        key: isFeePayment ? feesDialogKey : invoicesDialogKey,
        paymentKind: paymentKinds.Online,
        paymentIntentType: isFeePayment ? paymentIntentTypes.Fee : paymentIntentTypes.Invoice
      })">
      <mat-icon class="__dialog-button-icon">credit_card</mat-icon>
      <span translate="bulkPayment.dialogCardTriggerButton"></span>
    </button>

  </div>

</div>
