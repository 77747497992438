import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgMathPipesModule, NgBytesPipeModule } from 'angular-pipes';
import { UploadModule } from '@app/common/upload';
import { SatPopoverModule } from '@zerops/fe/popover';
import { ModuleTokens } from './files.constant';
import { reducer } from './files.reducer';
import { FilesEffect } from './files.effect';
import { FilesContainer } from './files.container';

@NgModule({
  declarations: [ FilesContainer ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatListModule,
    MatCardModule,
    NgMathPipesModule,
    NgBytesPipeModule,
    StoreModule.forFeature(
      ModuleTokens.Name,
      reducer
    ),
    EffectsModule.forFeature([ FilesEffect ]),
    TranslateModule.forChild(),
    UploadModule,
    SatPopoverModule
  ],
  exports: [ FilesContainer ]
})
export class FilesModule {

}
