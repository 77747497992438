import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { progressByKey } from '@zerops/fe/ngrx';
import { getDialogState, DialogOpen } from '@zerops/fe/dialog';
import { Subject, Observable } from 'rxjs';
import {
  map,
  withLatestFrom,
  takeUntil,
  switchMap,
  filter,
  tap
} from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { State } from '@app/models';
import {
  TicketsBaseRateRequest,
  TicketsBaseActionTypes,
  TicketRating
} from '@app/base/tickets-base';
import { scrollToErrorInput, HashMap } from 'utils';
import { formState } from './ticket-rating.selector';

@Component({
  selector: 'vshcz-ticket-rating',
  templateUrl: './ticket-rating.container.html',
  styleUrls: ['./ticket-rating.container.scss'],
  preserveWhitespaces: true
})
export class TicketRatingContainer extends BaseClass {
  // # Form States
  formState$ = this._store.pipe(select(formState));

  // # Event Streams
  onRate$ = new Subject<TicketRating | void>();

  // # Data
  // -- sync
  rateRequestKey = TicketsBaseActionTypes.RateRequest;
  rateErrorKey = TicketsBaseActionTypes.RateFail;
  stars = new Array(4);
  ratingsMap: HashMap<number> = {};
  id: string;

  // -- angular
  @ObservableInput()
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('id')
  id$!: Observable<string>;

  @Input()
  active: boolean;

  // -- async
  rateRequestKey$ = this.id$.pipe(
    map((id) => `${this.rateRequestKey}:${id}`)
  );
  rateErrroKey$ = this.id$.pipe(
    map((id) => `${this.rateErrorKey}:${id}`)
  );
  dialogOpen$ = this._store.pipe(
    select(getDialogState(this.rateRequestKey)),
    map((s) => s.state)
  );
  rateRunning$ = this.rateRequestKey$.pipe(
    switchMap((key) => this._store.pipe(select(progressByKey(key)))),
    map((p) => !!p),
    // only show running in button when dialog is closed
    withLatestFrom(this.dialogOpen$),
    filter(([ _, open ]) => !open),
    map(([ r ]) => r)
  );

  // # Action Streams
  private _rateAction$ = this.onRate$.pipe(
    withLatestFrom(this.id$),
    tap(([ rating, id ]) => rating ? (this.ratingsMap[id] = rating) : undefined),
    map(([ rating, id ]) => !rating || (rating && rating === 4)
      ? new TicketsBaseRateRequest({ id, rating })
      : new DialogOpen(this.rateRequestKey)
    )
  );

  constructor(private _store: Store<State>) {
    super();

    // # Data Resolvers
    this.id$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe((d) => this.id = d);

    // # Action Dispatcher
    this._rateAction$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }

  scrollToError = scrollToErrorInput;

  resetRating() {
    delete this.ratingsMap[this.id];
  }
}
