import { NgModule } from '@angular/core';

import { FilterPopButtonModule } from './modules/filter-pop-button';
import { FilterPopSatPopoverModule } from './modules/filter-pop-popover';
import { FilterPopMultiselectModule } from './modules/filter-pop-multiselect';

@NgModule({
  imports: [
    FilterPopButtonModule,
    FilterPopSatPopoverModule,
    FilterPopMultiselectModule
  ],
  exports: [
    FilterPopButtonModule,
    FilterPopSatPopoverModule,
    FilterPopMultiselectModule
  ]
})
export class FilterPopModule {

}
