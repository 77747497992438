import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ServerParkAccessBaseState,
  ServerParkAccessUserEntity
} from './server-park-access-base.model';
import { ModuleTokens } from './server-park-access-base.constant';
import { denormalizeEntity } from './server-park-access-base.utils';

export const slice = createFeatureSelector<ServerParkAccessBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const getById = (id: string) => createSelector(
  entities,
  (ents) => denormalizeEntity(
    id,
    ents[ModuleTokens.Name]
  ) as ServerParkAccessUserEntity
);
