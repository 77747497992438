import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component } from '@angular/core';
import { State } from '@app/models';
import { Store } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { PermissionService } from '@zerops/fe/permission';
import { interval, Subject, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import differenceInMinutes from 'date-fns/esm/differenceInMinutes';
import uniqBy from 'lodash-es/uniqBy';
import { LocalStorageService } from '@app/services';
import { Roles } from '@app/base/auth-base';
import { KvmKeepaliveRequest } from './kvm-console-connection.action';
import { KvmConsoleTypes, KvmConsoleDataExtended } from '../kvm-settings/kvm-settings.model';
import { STORAGE_NAMESPACE, STORAGE_KEY, KvmConsoleConnectionService } from './kvm-console-connection.service';
import { KvmConsoleStartRequest } from '../kvm-settings/kvm-settings.action';

@Component({
  selector: 'vshcz-kvm-console-connection',
  templateUrl: './kvm-console-connection.container.html',
  styleUrls: [ './kvm-console-connection.container.scss' ],
  animations: [
    trigger('inOut', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translate3d(0, 4px, 0)'
        }),
        animate(200, style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)'
        }))
      ]),
      transition(':leave', [
        style({
          opacity: 1,
          transform: 'translate3d(0, 0, 0)'
        }),
        animate(200, style({
          opacity: 0,
          transform: 'translate3d(0, 4px, 0)'
        }))
      ])
    ])
  ]
})
export class KvmConsoleConnectionContainer extends BaseClass implements AfterViewInit {

  // Event Streams
  onKvmKeepalive$ = new Subject<string>();
  onKvmConsoleStart$ = new Subject<{ consoleType: KvmConsoleTypes, serverId: string; }>();

  // # Data
  // -- sync
  isExpanded = true;

  // -- angular

  // -- async
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeConsoles$ = this._localStorage.get$<any>(
    STORAGE_NAMESPACE,
    STORAGE_KEY
  ).pipe(
    map((d) => (!!d ? Object.values(d) : []) as KvmConsoleDataExtended[])
  );

  isAuthorized$ = this._permission.authorize({
    only: [ Roles.Authorized ]
  });

  // # Actions Streams
  private _kvmKeepaliveRequestAction$ = this.onKvmKeepalive$.pipe(
    map((key) => new KvmKeepaliveRequest(key))
  );

  private _kvmConsoleStartRequestAction$ = this.onKvmConsoleStart$.pipe(
    filter((d) => !!d),
    map(({ consoleType, serverId }) => new KvmConsoleStartRequest(consoleType, { serverId }))
  );

  constructor(
    private _store: Store<State>,
    private _kvmConsoleConnectionService: KvmConsoleConnectionService,
    private _localStorage: LocalStorageService,
    private _permission: PermissionService
  ) {
    super();

    // # Store Dispatcher
    merge(
      this._kvmKeepaliveRequestAction$,
      this._kvmConsoleStartRequestAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);
  }

  ngAfterViewInit() {
    interval(60000).pipe(
      withLatestFrom(this.activeConsoles$),
      distinctUntilChanged(),
      takeUntil(this._ngOnDestroy$),
      filter(([ _, activeConsoles ]) => !!activeConsoles && !!activeConsoles.length)
    ).subscribe(([ _, activeConsoles ]) => {
      uniqBy(activeConsoles, 'serverId').forEach((item) => {
        // Terminate keepalive connection longer than one day
        if (differenceInMinutes(new Date(), item.created) < 1440) {
          this.onKvmKeepalive$.next(item.keepaliveKey);
        } else {
          this._kvmConsoleConnectionService.removeConsole(item.consoleType, item.serverId);
        }
      });
    });
  }

  trackBy(index: number) {
    return index;
  }

  removeConsole(consoleType: KvmConsoleTypes, serverId: string) {
    this._kvmConsoleConnectionService.removeConsole(consoleType, serverId);
  }

}
