function getServerUrl(type: 'managed' | 'hosted') {
  const typeUrl = type === 'managed'
    ? '/servers-managed'
    : '/servers-hosted';

  return (id: string) => [ typeUrl, 'edit', id ];
}

const ENTITY_TRANSLATOR = {
  clientUser: (id: string) => [ '/team', 'edit', id ],
  clientPersonServerParkAccess: (id: string) => [ '/server-park-access', 'edit', id ],
  ticket: (id: string) => [ '/tickets', 'detail', id ],
  invoice: (id: string) => [ '/invoices', 'detail', id ],
  cloudManagedServer: getServerUrl('managed'),
  managedCluster: getServerUrl('managed'),
  managedServer: getServerUrl('managed'),
  m1ManagedCluster: getServerUrl('managed'),
  m1ManagedServer: getServerUrl('managed'),
  m2ManagedCluster: getServerUrl('managed'),
  m2ManagedServer: getServerUrl('managed'),
  windowsManagedCluster: getServerUrl('managed'),
  windowsManagedServer: getServerUrl('managed'),
  windowsManagedBasic: getServerUrl('managed'),
  windowsManagedCloud: getServerUrl('managed'),
  serverHosting: getServerUrl('hosted'),
  dedicatedServer: getServerUrl('hosted'),
  gpuDedicatedServer: getServerUrl('hosted'),
  vds: getServerUrl('hosted'),
  domain: (id: string) => [ '/domains', 'detail', id ]
};

export function searchLinkGenerator(
  entity: string,
  id: string
) {
  const entityLink = ENTITY_TRANSLATOR[entity];

  if (entityLink) {
    return entityLink(id);
  } else {
    console.warn('Non existant serch entity ', entity);
  }
}
