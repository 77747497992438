import { normalize, denormalize } from 'normalizr';
import orderBy from 'lodash-es/orderBy';
import { clientUserListSchema, clientUserSchema } from '@app/schemas';
import { ClientsBaseModuleTokens } from '@app/base/clients-base';
import { ServicesBaseModuleTokens } from '@app/base/services-base';
import {
  ModuleTokens as UsersBaseModuleTokens
} from '@app/base/users-base/users-base.constant';
import {
  ClientUserEntity,
  ClientUserEntityNormalizedData
} from './client-user-base.model';
import { ModuleTokens } from './client-user-base.constant';

export function normalizeList(data: ClientUserEntity[]): ClientUserEntityNormalizedData {
  return normalize(data, clientUserListSchema);
}

export function denormalizeEntity(
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientUserEnts: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  usersEnts: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientsEnts: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  servicesEnts: any
) {
  return denormalize(
    id,
    clientUserSchema,
    {
      [ModuleTokens.Name]: clientUserEnts,
      [UsersBaseModuleTokens.Name]: usersEnts,
      [ClientsBaseModuleTokens.Name]: clientsEnts,
      [ServicesBaseModuleTokens.Name]: servicesEnts
    }
  );
}

export function denormalizeList(
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientUserEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  usersEnts: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientsEnts: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  servicesEnts: any
) {
  return denormalize(
    ids,
    clientUserListSchema,
    {
      [ModuleTokens.Name]: clientUserEntities,
      [UsersBaseModuleTokens.Name]: usersEnts,
      [ClientsBaseModuleTokens.Name]: clientsEnts,
      [ServicesBaseModuleTokens.Name]: servicesEnts
    }
  );
}

const USER_STATUS_ORDER_MAP = {
  WAITING_AUTHORIZATION: 1,
  ACTIVE: 2,
  BEING_DELETED: 3
};

export function sortList(data: ClientUserEntity[]) {
  return orderBy(
    data,
    [
      (item) => USER_STATUS_ORDER_MAP[item.status],
      'fullName'
    ],
    [
      'asc',
      'asc'
    ]
  ) as ClientUserEntity[];
}
