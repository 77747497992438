<div
  class="__empty-state"
  [style.height.px]="height"
  *ngIf="((activeFilters$ | async)?.length && !(list$ | async)?.length); else itemsRef">

  <h2 class="mat-h1  __empty-state_title">
    <span translate="ticketList.emptyStateTitle"></span>
  </h2>
  <p>
    <span translate="ticketList.emptyStateDescPreLink"></span>
    <a href="#" (click)="!!onResetFilter$.next()">
      <span translate="ticketList.emptyStateDescLink"></span>
    </a>
    <span translate="ticketList.emptyStateDescPostLink"></span>
  </p>

  <img
    class="__empty-state_image"
    src="https://cdn.dribbble.com/users/1121009/screenshots/5270889/dribbble_23.jpg"
  />

</div>

<ng-template #itemsRef>

  <cdk-virtual-scroll-viewport
    class="__viewport"
    [style.height.px]="height"
    autosize>
    <div
      class="__item-parent"
      *cdkVirtualFor="let item of (list$ | async); let i = index; trackBy: trackBy;">

      <a
        href="#"
        matRipple
        (click)="!!selected.emit(item)"
        class="__item"
        [class.is-highlighted]="item.id === selectedId">
        <vshcz-ticket-item
          [data]="item"
          [more]="'ticketsCommon.numMore' | translate: { num: (item.publicMessageCount - 1) }">
        </vshcz-ticket-item>
      </a>

      <button
        class="__share"
        mat-icon-button
        [matTooltip]="'common.copyToUrl' | translate"
        (click)="copyLinkToClipboard(item)"
        *ngIf="showActions">
        <mat-icon class="__share_icon">filter_none</mat-icon>
      </button>

    </div>
  </cdk-virtual-scroll-viewport>

</ng-template>
