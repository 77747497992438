import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './invoices-base.constant';
import { InvoicesBaseState } from './invoices-base.model';
import { StripePaymentStatuses } from './invoices-base.constant';
import {
  Actions,
  ActionTypes
} from './invoices-base.action';

const initialState = new InvoicesBaseState();

export function reducer(
  state = initialState,
  action: Actions
): InvoicesBaseState {
  state = applyEntities<InvoicesBaseState>(
    state,
    [ ModuleTokens.Name ],
    action
  );

  if (action.type === ActionTypes.ListLocalSuccess) {
    return {
      ...state,
      list: action.payload.result
    };
  }

  if (action.type === ActionTypes.LiabilityInvoiceLocalSuccess) {
    return {
      ...state,
      invoiceLiabilities: action.payload
    };
  }

  if (action.type === ActionTypes.LiabilityFeeLocalSuccess) {
    return {
      ...state,
      feeLiabilities: action.payload
    };
  }

  if (action.type === ActionTypes.PaymentIntentRequest) {
    const { instanceKind, status, type, amount } = action.payload;
    return {
      ...state,
      activePayment: { instanceKind, status, type, amount }
    };
  }

  if (
    action.type === ActionTypes.PaymentIntentFail
  ) {
    const activePayment = {
      ...state.activePayment,
      status: StripePaymentStatuses.IntentRequestFailed
    };
    return {
      ...state,
      activePayment
    };
  }

  if (action.type === ActionTypes.PaymentIntentLocalSuccess) {
    const activePayment = {
      ...state.activePayment,
      status: StripePaymentStatuses.IntentRequestSuccess,
      secret: action.payload
    };
    return {
      ...state,
      activePayment
    };
  }

  if (action.type === ActionTypes.ConfirmPaymentRequest) {
    const activePayment = {
      ...state.activePayment,
      status: StripePaymentStatuses.PaymentRequestInit
    };
    return {
      ...state,
      activePayment
    };
  }

  if (
    action.type === ActionTypes.ConfirmPaymentFail
  ) {
    const activePayment = {
      ...state.activePayment,
      status: StripePaymentStatuses.PaymentRequestFailed
    };
    return {
      ...state,
      activePayment
    };
  }

  if (action.type === ActionTypes.ConfirmPaymentLocalSuccess) {
    const activePayment = {
      ...state.activePayment,
      status: StripePaymentStatuses.PaymentRequestSuccess
    };
    return {
      ...state,
      activePayment
    };
  }

  if (action.type === ActionTypes.ListRequest) {
    return {
      ...state,
      activePayment: undefined
    };
  }

  return state;
}
