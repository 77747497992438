import {
  trigger,
  style,
  animate,
  transition,
  AnimationTriggerMetadata
} from '@angular/animations';

export const searchAnimation: AnimationTriggerMetadata = trigger('search', [
  transition(
    ':enter',
    [
      style({
        opacity: 0,
        transform: 'translateY(6px)'
      }),
      animate(
        '200ms ease-in-out',
        style({
          opacity: 1,
          transform: 'translateY(0)'
        })
      )
    ]
  ),
  transition(
    ':leave',
    [
      animate(
        '200ms ease-in-out',
        style({
          opacity: 0,
          transform: 'translateY(3px)'
        })
      )
    ]
  )
]);
