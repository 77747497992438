import { Action, EntitiesMergeStrategy } from '@zerops/fe/core';
import {
  ErrorsAction,
  ErrorsActionMeta,
  ApiError,
  ProgressAction,
  ProgressActionMeta
} from '@zerops/fe/ngrx';
import * as uuidv4 from 'uuid/v4';
import { UserEntity } from '@app/base/users-base';
import { normalizeList, createTempTicketEntities } from './tickets-base.utils';
import {
  TicketsEntityLight,
  TicketsEntityNormalizedData,
  TicketsEntity,
  TicketsBaseEntityEntities,
  TicketTopicEntity,
  TicketRating
} from './tickets-base.model';
import { ModuleTokens } from './tickets-base.constant';

export enum ActionTypes {
  ListRequest = '[Tickets Base] List Request',
  ListFail = '[Tickets Base] List Fail',
  ListLocalSuccess = '[Tickets Base] List Local Success',

  TicketTopicListRequest = '[Tickets Base] Ticket Topic List Request',
  TicketTopicListFail = '[Tickets Base] Ticket Topic List Fail',
  TicketTopicListLocalSuccess = '[Tickets Base] Ticket Topic List Local Success',

  EntityRequest = '[Tickets Base] Entity Request',
  EntityFail = '[Tickets Base] Entity Fail',
  EntityLocalSuccess = '[Tickets Base] Entity Local Success',

  MessageRequest = '[Tickets Base] Message Request',
  MessageFail = '[Tickets Base] Message Fail',
  MessageLocalSuccess = '[Tickets Base] Message Local Success',
  MessageSuccess = '[Tickets Base] Message Success',
  MessageAddTemp = '[Tickets Base] Message Add Temp',

  UnlockRequest = '[Tickets Base] Unlock Request',
  UnlockFail = '[Tickets Base] Unlock Fail',
  UnlockLocalSuccess = '[Tickets Base] Unlock Local Success',

  UpdateCopyListCheckRequest = '[Tickets Base] Update Copy List Check Request',
  UpdateCopyListCheckLocalSuccess = '[Tickets Base] Update Copy List Check Local Success',

  UpdateCopyListRequest = '[Tickets Base] Update Copy List Request',
  UpdateCopyListDenied = '[Tickets Base] Update Copy List Denied',
  UpdateCopyListFail = '[Tickets Base] Update Copy List Fail',
  UpdateCopyListLocalSuccess = '[Tickets Base] Update Copy List Local Success',

  RateRequest = '[Tickets Base] Rate Request',
  RateFail = '[Tickets Base] Rate Fail',
  RateLocalSuccess = '[Tickets Base] Rate Local Success',

  ApprovePriceOfferRequest = '[Tickets Base] Approve Price Offer Request',
  ApprovePriceOfferFail = '[Tickets Base] Approve Price Offer Fail',
  ApprovePriceOfferLocalSuccess = '[Tickets Base] Approve Price Offer Local Success',

  RejectPriceOfferRequest = '[Tickets Base] Reject Price Offer Request',
  RejectPriceOfferFail = '[Tickets Base] Reject Price Offer Fail',
  RejectPriceOfferLocalSuccess = '[Tickets Base] Reject Price Offer Local Success',

  ConfirmRealizationDateRequest = '[Tickets Base] Confirm Realization Date Request',
  ConfirmRealizationDateFail = '[Tickets Base] Confirm Realization Date Fail',
  ConfirmRealizationDateLocalSuccess = '[Tickets Base] Confirm Realization Date Local Success',

  RejectRealizationDateRequest = '[Tickets Base] Reject Realization Date Request',
  RejectRealizationDateFail = '[Tickets Base] Reject Realization Date Fail',
  RejectRealizationDateLocalSuccess = '[Tickets Base] Reject Realization Date Local Success'
}

export class ListRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.ListFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.ListRequest,
      type: 'local'
    }
  };
}

export class ListFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.ListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.ListFail,
        type: 'global',
        data
      }
    };
  }
}

export class ListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ListLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.ListRequest
  };
  payload: TicketsEntityNormalizedData;

  constructor(data: TicketsEntityLight[]) {

    const dataManaged = data.map((ticket) => {
      if (ticket.clientZoneStatus !== 'WAITING_AUTHORIZATION') { return ticket; }

      return {
        ...ticket,
        ticketMessageList: [
          {
            attachments: [],
            display: 'USER',
            id: ticket.lastTicketMessageId,
            extId: ticket.lastTicketMessageId,
            ticketId: ticket.id,
            messageHiddenText: null,
            signature: ticket.lastTicketMessageAuthor.fullName,
            messageText: ticket.lastTicketMessageText,
            author: ticket.lastTicketMessageAuthor,
            created: ticket.created
          }
        ]
      };

    });

    this.payload = normalizeList(dataManaged);

  }
}

export class TicketTopicListRequest implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.TicketTopicListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.TicketTopicListFail
  };
  progress: ProgressActionMeta = {
    add: {
      key: ActionTypes.TicketTopicListRequest,
      type: 'local'
    }
  };
}

export class TicketTopicListFail implements Action, ErrorsAction, ProgressAction {
  readonly type = ActionTypes.TicketTopicListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta = {
    remove: ActionTypes.TicketTopicListRequest
  };

  constructor(data: ApiError) {
    this.errors = {
      add: {
        key: ActionTypes.TicketTopicListFail,
        type: 'global',
        data
      }
    };
  }
}

export class TicketTopicListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.TicketTopicListLocalSuccess;
  progress: ProgressActionMeta = {
    remove: ActionTypes.TicketTopicListRequest
  };

  constructor(public payload: TicketTopicEntity[]) { }
}

export class EntityRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.EntityFail
  };
  progress: ProgressActionMeta;

  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.EntityRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.EntityRequest
      }
    };
  }
}

export class EntityFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.EntityFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.EntityFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }

}

export class EntityLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.EntityLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: TicketsBaseEntityEntities };

  constructor(data: TicketsEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload = { entities };
    this.progress = {
      remove: `${ActionTypes.EntityRequest}:${id}`
    };
  }
}

export class MessageRequest implements Action {
  readonly type = ActionTypes.MessageRequest;
  meta = uuidv4();

  constructor(public payload: {
    ticketId: string;
    instanceId: string,
    messageText?: string;
    attachments?: string[];
  }) { }
}

export class MessageFail implements Action, ProgressAction {
  readonly type = ActionTypes.MessageFail;
  progress: ProgressActionMeta;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: { entities: any; };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: { err?: ApiError; _mergeStrategy: any; } = {
    _mergeStrategy: {
      ticketMessageList: EntitiesMergeStrategy.Remove
    }
  };

  constructor(tempId: string, ticketId: string, err?: ApiError) {
    if (err) {
      this.meta = {
        ...this.meta,
        err
      };
    }

    this.payload = {
      entities: {
        [ModuleTokens.Name]: {
          [ticketId]: {
            id: ticketId,
            ticketMessageList: [ tempId ]
          }
        }
      }
    };

    this.progress = {
      remove: `${ActionTypes.MessageAddTemp}:${tempId}`
    };
  }
}

export class MessageLocalSuccess implements Action {
  readonly type = ActionTypes.MessageLocalSuccess;
  progress: ProgressActionMeta;
  payload: {
    entities: TicketsBaseEntityEntities;
    result: string[];
  };
  meta = {
    _mergeStrategy: {
      ticketMessageList: EntitiesMergeStrategy.KeepNew
    }
  };

  constructor(data: TicketsEntity, tempId: string) {
    this.progress = {
      remove: `${ActionTypes.MessageAddTemp}:${tempId}`
    };
    const { entities, result } = normalizeList([ data ]);
    this.payload = { entities, result };
  }
}

export class MessageSuccess implements Action {
  readonly type = ActionTypes.MessageSuccess;
  payload: {
    entities: TicketsBaseEntityEntities;
    result: string[];
  };
  meta = {
    _mergeStrategy: {
      ticketMessageList: EntitiesMergeStrategy.MergeUniq,
      attachments: EntitiesMergeStrategy.KeepNew,
      copyList: EntitiesMergeStrategy.KeepNew
    }
  };

  constructor(data: TicketsEntity) {
    const { entities, result } = normalizeList([ data ]);
    this.payload = { entities, result };
  }
}

export class MessageAddTemp implements Action, ProgressAction {
  readonly type = ActionTypes.MessageAddTemp;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  progress: ProgressActionMeta;
  meta = {
    _mergeStrategy: EntitiesMergeStrategy.Append
  };

  constructor(
    ticketId: string,
    message: string,
    user: UserEntity,
    tempId: string
  ) {
    this.progress = {
      add: {
        key: `${ActionTypes.MessageAddTemp}:${tempId}`,
        type: 'local',
        group: ActionTypes.MessageAddTemp
      }
    };

    this.payload = {
      entities: createTempTicketEntities(ticketId, tempId, message, user)
    };

  }
}

export class UnlockRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.UnlockRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.UnlockFail
  };
  progress: ProgressActionMeta;

  /**
   * @param payload Ticket ID
   */
  constructor(public payload: string) {
    this.progress = {
      add: {
        key: `${ActionTypes.UnlockRequest}:${this.payload}`,
        type: 'local',
        group: ActionTypes.UnlockRequest
      }
    };
  }
}

export class UnlockFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.UnlockFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.UnlockFail,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.UnlockRequest}:${id}`
    };
  }

}

export class UnlockLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.UnlockLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: TicketsBaseEntityEntities };

  constructor(data: TicketsEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.UnlockRequest}:${id}`
    };
  }
}

export class UpdateCopyListRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.UpdateCopyListRequest;
  errors: ErrorsActionMeta = {
    remove: ActionTypes.UpdateCopyListFail
  };
  progress: ProgressActionMeta;
  payload: {
    id: string;
    data: string[]
    mail: string;
    isWatch?: boolean;
  };

  constructor(id: string, data: string[], mail: string, public meta: 'add' | 'remove', isWatch = false) {
    this.payload = { id, data, isWatch, mail };

    const watchingProgressKey = isWatch ? `:watch` : '';
    this.progress = {
      add: {
        key: `${ActionTypes.UpdateCopyListRequest}:${id}${watchingProgressKey}`,
        type: 'local',
        group: ActionTypes.UpdateCopyListRequest
      },
      remove: `${ActionTypes.UpdateCopyListCheckRequest}:${id}${watchingProgressKey}`
    };
  }
}

export class UpdateCopyListDenied implements Action, ProgressAction {
  readonly type = ActionTypes.UpdateCopyListDenied;
  progress: ProgressActionMeta;

  constructor(id: string, public payload: string) {
    this.progress = {
      remove: [
        `${ActionTypes.UpdateCopyListCheckRequest}:${id}`,
        `${ActionTypes.UpdateCopyListCheckRequest}:${id}:watch`,
        `${ActionTypes.UpdateCopyListRequest}:${id}`,
        `${ActionTypes.UpdateCopyListRequest}:${id}:watch`
      ]
    };
  }
}

export class UpdateCopyListFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.UpdateCopyListFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: ApiError;

  constructor(data: ApiError, id: string, isWatch: boolean) {
    this.meta = data;

    this.errors = {
      add: {
        key: ActionTypes.UpdateCopyListFail,
        type: 'local',
        data
      }
    };

    const watchingProgressKey = isWatch ? `:watch` : '';

    this.progress = {
      remove: `${ActionTypes.UpdateCopyListRequest}:${id}${watchingProgressKey}`
    };
  }

}

export class UpdateCopyListLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.UpdateCopyListLocalSuccess;
  progress: ProgressActionMeta;
  payload: { entities: TicketsBaseEntityEntities };

  meta = {
    _mergeStrategy: {
      copyList: EntitiesMergeStrategy.KeepNew
    }
  };

  constructor(data: TicketsEntity, id: string, isWatch: boolean) {
    const { entities } = normalizeList([ data ]);
    this.payload = { entities };
    const watchingProgressKey = isWatch ? `:watch` : '';
    this.progress = {
      remove: `${ActionTypes.UpdateCopyListRequest}:${id}${watchingProgressKey}`
    };
  }
}

export class UpdateCopyListCheckRequest implements Action, ProgressAction {
  readonly type = ActionTypes.UpdateCopyListCheckRequest;
  progress: ProgressActionMeta;
  payload: {
    id: string;
    data: string[]
    mail: string;
    isWatch?: boolean;
  };

  constructor(id: string, data: string[], mail: string, isWatch = false) {
    this.payload = { id, data, mail, isWatch };

    const watchingProgressKey = isWatch ? `:watch` : '';
    this.progress = {
      add: {
        key: `${ActionTypes.UpdateCopyListCheckRequest}:${id}${watchingProgressKey}`,
        type: 'local',
        group: ActionTypes.UpdateCopyListCheckRequest
      }
    };
  }
}

export class UpdateCopyListCheckLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.UpdateCopyListCheckLocalSuccess;
  progress: ProgressActionMeta;
  payload: {
    pass: boolean;
    payload: {
      id: string;
      data: string[]
      mail: string;
    };
  };
  constructor(
    pass: boolean,
    payload: {
      id: string;
      data: string[]
      mail: string;
    },
    isWatch = false
  ) {
    this.payload = { pass, payload };

    const watchingProgressKey = isWatch ? `:watch` : '';
    this.progress = {
      remove: `${ActionTypes.UpdateCopyListCheckRequest}:${payload.id}${watchingProgressKey}`
    };
  }
}

// rate
export class RateRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.RateRequest;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(public payload: { id: string, rating: TicketRating | void, clientId?: string }) {
    this.progress = {
      add: {
        key: `${ActionTypes.RateRequest}:${payload.id}`,
        type: 'local',
        group: ActionTypes.RateRequest
      }
    };
    this.errors = {
      remove: `${ActionTypes.RateFail}:${payload.id}`
    };
  }
}

export class RateFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.RateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(data: ApiError, id: string, public meta: {
    errorMessage: string;
    errorCode: number;
  }) {
    this.errors = {
      add: {
        key: `${ActionTypes.RateFail}:${id}`,
        type: 'local',
        data
      }
    };

    this.progress = {
      remove: `${ActionTypes.RateRequest}:${id}`
    };
  }
}

export class RateLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.RateLocalSuccess;
  progress: ProgressActionMeta;

  constructor(id: string) {
    this.progress = {
      remove: `${ActionTypes.RateRequest}:${id}`
    };
  }
}

// price offer
export class ApprovePriceOfferRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.ApprovePriceOfferRequest;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(public payload: string, public meta: {
    lastAdminTicketMessageId: string;
    clientId: string;
  }) {
    this.progress = {
      add: {
        key: `${ActionTypes.ApprovePriceOfferRequest}:${payload}`,
        type: 'local',
        group: ActionTypes.ApprovePriceOfferRequest
      }
    };
    this.errors = {
      remove: `${ActionTypes.RateFail}:${payload}`
    };
  }
}

export class ApprovePriceOfferFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.ApprovePriceOfferFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: ApiError;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.ApprovePriceOfferFail,
        type: 'local',
        data
      }
    };
    this.meta = data;
    this.progress = {
      remove: `${ActionTypes.ApprovePriceOfferRequest}:${id}`
    };
  }
}

export class ApprovePriceOfferLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ApprovePriceOfferLocalSuccess;
  progress: ProgressActionMeta;

  payload: { entities: TicketsBaseEntityEntities };

  constructor(data: TicketsEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.ApprovePriceOfferRequest}:${id}`
    };
  }
}

export class RejectPriceOfferRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.RejectPriceOfferRequest;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(public payload: string, public meta: {
    messageText: string;
    clientId: string;
  }) {
    this.progress = {
      add: {
        key: `${ActionTypes.RejectPriceOfferRequest}:${payload}`,
        type: 'local',
        group: ActionTypes.RejectPriceOfferRequest
      }
    };
    this.errors = {
      remove: `${ActionTypes.RejectPriceOfferFail}:${payload}`
    };
  }
}

export class RejectPriceOfferFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.RejectPriceOfferFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: ApiError;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.RejectPriceOfferFail,
        type: 'local',
        data
      }
    };
    this.meta = data;
    this.progress = {
      remove: `${ActionTypes.RejectPriceOfferRequest}:${id}`
    };
  }
}

export class RejectPriceOfferLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.RejectPriceOfferLocalSuccess;
  progress: ProgressActionMeta;

  payload: { entities: TicketsBaseEntityEntities };

  constructor(data: TicketsEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.RejectPriceOfferRequest}:${id}`
    };
  }
}

// realization date
export class ConfirmRealizationDateRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.ConfirmRealizationDateRequest;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(public payload: string, public meta: {
    clientId: string;
  }) {
    this.progress = {
      add: {
        key: `${ActionTypes.ConfirmRealizationDateRequest}:${payload}`,
        type: 'local',
        group: ActionTypes.ConfirmRealizationDateRequest
      }
    };
    this.errors = {
      remove: `${ActionTypes.RateFail}:${payload}`
    };
  }
}

export class ConfirmRealizationDateFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.ConfirmRealizationDateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: ApiError;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.ConfirmRealizationDateFail,
        type: 'local',
        data
      }
    };
    this.meta = data;
    this.progress = {
      remove: `${ActionTypes.ConfirmRealizationDateRequest}:${id}`
    };
  }
}

export class ConfirmRealizationDateLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.ConfirmRealizationDateLocalSuccess;
  progress: ProgressActionMeta;

  payload: { entities: TicketsBaseEntityEntities };

  constructor(data: TicketsEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.ConfirmRealizationDateRequest}:${id}`
    };
  }
}

export class RejectRealizationDateRequest implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.RejectRealizationDateRequest;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;

  constructor(public payload: string, public meta: {
    messageText: string;
    clientId: string;
  }) {
    this.progress = {
      add: {
        key: `${ActionTypes.RejectRealizationDateRequest}:${payload}`,
        type: 'local',
        group: ActionTypes.RejectRealizationDateRequest
      }
    };
    this.errors = {
      remove: `${ActionTypes.RejectRealizationDateFail}:${payload}`
    };
  }
}

export class RejectRealizationDateFail implements Action, ProgressAction, ErrorsAction {
  readonly type = ActionTypes.RejectRealizationDateFail;
  errors: ErrorsActionMeta;
  progress: ProgressActionMeta;
  meta: ApiError;

  constructor(data: ApiError, id: string) {
    this.errors = {
      add: {
        key: ActionTypes.RejectRealizationDateFail,
        type: 'local',
        data
      }
    };
    this.meta = data;
    this.progress = {
      remove: `${ActionTypes.RejectRealizationDateRequest}:${id}`
    };
  }
}

export class RejectRealizationDateLocalSuccess implements Action, ProgressAction {
  readonly type = ActionTypes.RejectRealizationDateLocalSuccess;
  progress: ProgressActionMeta;

  payload: { entities: TicketsBaseEntityEntities };

  constructor(data: TicketsEntity, id: string) {
    const { entities } = normalizeList([ data ]);
    this.payload =  { entities };
    this.progress = {
      remove: `${ActionTypes.RejectRealizationDateRequest}:${id}`
    };
  }
}

export type Actions
  = ListRequest
  | ListFail
  | ListLocalSuccess
  | TicketTopicListRequest
  | TicketTopicListFail
  | TicketTopicListLocalSuccess
  | EntityRequest
  | EntityFail
  | EntityLocalSuccess
  | MessageRequest
  | MessageFail
  | MessageSuccess
  | MessageLocalSuccess
  | UpdateCopyListFail
  | UpdateCopyListLocalSuccess
  | UpdateCopyListRequest
  | UnlockRequest
  | UnlockFail
  | UnlockLocalSuccess
  | RateRequest
  | RateFail
  | RateLocalSuccess
  | ApprovePriceOfferRequest
  | ApprovePriceOfferFail
  | ApprovePriceOfferLocalSuccess
  | RejectPriceOfferRequest
  | RejectPriceOfferFail
  | RejectPriceOfferLocalSuccess
  | ConfirmRealizationDateRequest
  | ConfirmRealizationDateFail
  | ConfirmRealizationDateLocalSuccess
  | RejectRealizationDateRequest
  | RejectRealizationDateFail
  | RejectRealizationDateLocalSuccess;
