import {
  Component,
  Output,
  EventEmitter,
  Input} from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { DEFAULT_TRANSLATION_KEYS } from './ticket-text-form.translations';
import {
  TicketTextFormTranslations,
  TicketTextFormState
} from './ticket-text-form.model';
import { TicketStatuses } from '../tickets';

@Component({
  selector: 'vshcz-ticket-text-form',
  templateUrl: 'ticket-text-form.component.html',
  styleUrls: [ 'ticket-text-form.component.scss' ]
})
export class TicketTextFormComponent {

  ticketStatuses = TicketStatuses;

  @Input()
  state: TicketTextFormState;

  @Input()
  formTranslations: TicketTextFormTranslations = DEFAULT_TRANSLATION_KEYS;

  @Input()
  enableSendOnReturn = false;

  @Output()
  sizeChange = new EventEmitter<number>();

  @Output()
  enter = new EventEmitter<void>();

  onKeypress(e: KeyboardEvent) {
    if (e.keyCode === ENTER && !e.shiftKey && this.enableSendOnReturn) {
      this.enter.emit();
      e.preventDefault();
      return false;
    }
  }
}
