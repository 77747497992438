import { Action } from '@ngrx/store';
import { StateUpdateFns } from 'ngrx-forms';
import {
  NgrxFormExtensionModel,
  HasNgrxForm
} from '@app/common/ngrx-forms-extensions';
import {
  TicketAuthorizationFormValue,
  TicketAuthorizationFormState
} from './ticket-authorization.model';
import { formKey } from './ticket-authorization.constant';

const formModel = new NgrxFormExtensionModel<TicketAuthorizationFormValue>(formKey);

export const getFormKey = (id: string) => formModel.getKey(id);

export const formState = (id: string) => (
  defaultValues: Partial<TicketAuthorizationFormValue> = {},
  updateFns: StateUpdateFns<TicketAuthorizationFormValue> = {}
) => ({
  [getFormKey(id)]: formModel.createFormState(
    id,
    {
      clientId: undefined
    },
    defaultValues,
    updateFns
  )
});

export const applyFormReducer = <S extends HasNgrxForm>(
  state: S,
  action: Action,
  id: string,
  defaultSettings?: StateUpdateFns<TicketAuthorizationFormValue>
) => formModel.reduceAction(state, action, id, defaultSettings);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const markAsSubmitted = (id: string, state: any) => formModel.markAsSubmitted(id, state);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const markAsUnsubmitted = (id: string, state: any) => formModel.markAsUnsubmitted(id, state);
export const formSelector = (id: string) => formModel.selectState<TicketAuthorizationFormState>(id);
export const formValueSelector = (id: string) => formModel.selectValue(id);
export const updateGroupAction = (
  id: string,
  value: Partial<TicketAuthorizationFormValue>
) => formModel.updateGroupValue(id, value);
export const updateFormGroupState = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  id: string,
  updateFns: StateUpdateFns<TicketAuthorizationFormValue>
) => formModel.updateGroupState(state, id, updateFns);
