import { Component, ViewChild, ElementRef, HostBinding, HostListener } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { progressByKey } from '@zerops/fe/ngrx';
import { Subject, merge, BehaviorSubject, combineLatest } from 'rxjs';
import {
  map,
  takeUntil,
  filter,
  withLatestFrom,
  tap,
  startWith,
  distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import * as uuidv4 from 'uuid/v4';
import { TicketDetailContainer } from '@app/common/ticket-detail';
import { TranslateService } from '@ngx-translate/core';
import {
  TicketsBaseMessageRequest,
  TicketsBaseUnlockRequest,
  ticketTopicListGrouped,
  ticketTopicList,
  TicketsEntity,
  TicketsBaseUpdateCopyListCheckRequest,
  TicketsBaseActionTypes,
  AttachedPriceOfferStates
} from '@app/base/tickets-base';
import { State } from '@app/models';
import {
  authActiveUserNormalizedClient,
  authActiveClientUser,
  identity
} from '@app/base/auth-base';
import { ticketAddFormValue, TicketAddModuleTokens } from '@app/common/ticket-add';
import { SatPopover } from '@zerops/fe/popover';
import { TicketStatuses } from '@app/common/tickets/tickets.constant';
import { ClientUserRoles } from '@app/base/client-user-base';
import {
  ticketsTriggerAnimation,
  ticketsTriggerCloseButton
} from './tickets-trigger.animation';
import { TicketsBaseUpdateCopyListRequest } from '@app/base/tickets-base';
import { activeUserClientId } from '@app/base/auth-base/auth-base.selector';
import { FilesEmptyQueue } from '@app/common/files';
import {
  state,
  detailData,
  open,
  ticketDetailTicketTextFormState
} from './tickets-trigger.selector';
import { TicketsTriggerStates } from './tickets-trigger.constant';
import {
  SetState,
  Reset,
  Open,
  Close
} from './tickets-trigger.action';

@Component({
  selector: 'vshcz-tickets-trigger',
  templateUrl: './tickets-trigger.container.html',
  styleUrls: [ './tickets-trigger.container.scss' ],
  animations: [ ticketsTriggerAnimation, ticketsTriggerCloseButton ]
})
export class TicketsTriggerContainer extends BaseClass {
  // # Form States
  ticketUpdateTextFormState$ = this._store.pipe(select(ticketDetailTicketTextFormState));

  // # Event Streams
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSetState$ = new Subject<{ state: TicketsTriggerStates; meta: any; }>();
  onSendMessage$ = new Subject<string>();
  onUnlock$ = new Subject<void>();
  onReset$ = new Subject<void>();
  onToggle$ = new Subject<void>();
  onClose$ = new Subject<void>();
  onCardHover$ = new Subject<boolean>();
  onToggleWatchTicket$ = new Subject<void>();

  // # Data
  // -- sync
  height: number;
  detail: TicketsEntity;
  headerHeight = 76;
  detailMessageHeight = 74;
  ticketRaitingHeight = 111;
  ticketPriceOfferHeight = 102;
  ticketRealizationDateHeight = 72;
  states = TicketsTriggerStates;
  ticketStatuses = TicketStatuses;
  updateCopyListRequestKey = TicketsBaseActionTypes.UpdateCopyListRequest;
  clientUserRoles = ClientUserRoles;
  attachedPriceOfferStates = AttachedPriceOfferStates;

  // -- angular
  @ViewChild('submitFormRef')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef: ElementRef<any>;

  @ViewChild(TicketDetailContainer)
  ticketDetailRef: TicketDetailContainer;

  @HostBinding('class.is-mobile')
  get classIsMobile() {
    return this.media.isActive('lt-md');
  }

  // -- async
  viewportHeight$ = this._ruler
    .change(100)
    .pipe(
      map(() => this._ruler.getViewportSize().height),
      startWith(this._ruler.getViewportSize().height)
    );
  uploadIntanceId$ = new BehaviorSubject<string>('');
  currentLang$ = this._translate.onLangChange.pipe(
    startWith(this._translate.currentLang),
    map(() => this._translate.currentLang)
  );
  state$ = this._store.pipe(
    select(state),
    // set new upload instance id every time user sets
    // state to `Detail`
    tap((s) => {
      if (s === this.states.Detail) {
        this.uploadIntanceId$.next(uuidv4());
      }
    })
  );
  height$ = combineLatest([
    this.state$,
    this.viewportHeight$
  ]).pipe(
    map(([ curState, height ]) => {
      switch (curState) {
        case this.states.Dashboard:
          return 480;

        case this.states.List:
        case this.states.Detail:
          return height - this._defaultPopOffset;

        case this.states.Add:
          return 480 + this.headerHeight;

        default:
          console.warn('Unknown tickets trigger state');
          return 600;
      }
    })
  );
  open$ = this._store.pipe(select(open));
  detail$ = this._store.pipe(select(detailData));
  identity$ = this._store.pipe(select(identity));
  watchLoadingKey$ = this.detail$.pipe(
    filter((data) => !!data && !!data.id),
    map((data) => `${this.updateCopyListRequestKey}:${data.id}:watch`)
  );
  watchLoading$ = this.watchLoadingKey$.pipe(
    switchMap((key) => this._store.pipe(select(progressByKey(key))))
  );
  activeClientId$ = this._store.pipe(select(activeUserClientId));
  activeClientUser$ = this._store.pipe(select(authActiveClientUser));
  userIsWatchingTicket$ = this.detail$.pipe(
    filter((d) => !!d),
    map((d) => ({ d, copyList: d.copyList || [] })),
    withLatestFrom(
      this.identity$.pipe(
        filter((d) => !!d),
        map((d) => d.email)
      )
    ),
    map(([ { d, copyList }, mail ]) => d.assignedUserEmail === mail || copyList.includes(mail))
  );
  closeShown$ = this.onCardHover$.pipe(
    distinctUntilChanged(),
    map((shown) => shown && !this.media.isActive('lt-md'))
  );
  ticketTopicsGroupsAuthorized$ = combineLatest([
    this._store.pipe(select(ticketTopicListGrouped)),
    this.activeClientUser$
  ]).pipe(
    map(([ groups, user ]) => {
      const hasServices =  user && user.client && user.client.connectedServiceList
        ? !!user.client.connectedServiceList.find((service) => service.clientZoneStatus === 'ACTIVE')
        : false;
      let grps = groups;

      if (!user || !grps || !grps.length) { return []; }

      // skip filtering if user is Technical and company has no services
      if (!hasServices && user.roleCode === ClientUserRoles.TechnicalUser) { return []; }

      // if company has no services, filter out technical group
      if (!hasServices) {
        grps = grps.filter((group) => group.key !== 'TECHNICAL');
      }

      // if user is manager return all groups
      if (user.roleCode === ClientUserRoles.Manager) {
        return grps;
      }

      // if user is Financial user, return only groups for finance
      if (user.roleCode === ClientUserRoles.FinancialUser) {
        return grps.filter((group) => group.key !== 'TECHNICAL');
      }

      // user must be Technical (and company has active services)
      // => return only groups for technical
      return grps.filter((group) => group.key === 'TECHNICAL');
    })
  );

  ticketTopicsGrouped$ = combineLatest([
    this.currentLang$,
    this.ticketTopicsGroupsAuthorized$
  ]).pipe(
    map(([ lang, groups ]) => groups.map((group) => ({
      ...group,
      // add artificial `_name` property to each item
      // that contains name by current language
      items: group.items.map((itm) => ({
        ...itm,
        _name: itm.name[lang]
      }))
    })
  )));
  selectedTopicId$ = this._store.pipe(
    select(ticketAddFormValue),
    map((values) => values.ticketTopicId)
  );
  selectedAddTopic$ = combineLatest([
    this.currentLang$,
    this.selectedTopicId$,
    this._store.pipe(select(ticketTopicList))
  ]).pipe(
    map(([ lang, topicId, topics ]) => {
      // find currently selected add topic and return name by current language
      if (!topicId) { return undefined; }
      const topic = topics.find(((t) => t.id === topicId));
      return topic ? topic.name[lang] : undefined;
    })
  );
  activeClientSalesman$ = this._store.pipe(
    select(authActiveUserNormalizedClient),
    filter((c) => !!c),
    map((client) => !!client.salesman
      ? ({
        ...client.salesman,
        countryCallingCode: '+' + (client.salesman.countryCallingCode.replace('+', ''))
      })
      : undefined
    )
  );

  // # Action Streams
  private _setStateAction$ = this.onSetState$.pipe(
    map((data) => new SetState(data))
  );
  private _unlockAction$ = this.onUnlock$.pipe(
    withLatestFrom(this.detail$),
    map(([ _, detail ]) => new TicketsBaseUnlockRequest(detail.id))
  );
  private _sendMessageAction$ = this.onSendMessage$.pipe(
    withLatestFrom(
      this.uploadIntanceId$,
      this.ticketUpdateTextFormState$
    ),
    filter(([ _, __, { isValid }]) => isValid),
    map(([ id, instanceId, { value } ]) => new TicketsBaseMessageRequest({
      instanceId,
      ticketId: id,
      messageText: value.ticketMessage
    })),
    // create a new temp instance id for next message
    tap(() => this.uploadIntanceId$.next(uuidv4()))
  );
  private _resetAction$ = this.onReset$.pipe(
    map(() => new Reset())
  );
  private _toggleAction$ = this.onToggle$.pipe(
    withLatestFrom(this.open$),
    map(([ _, isOpen ]) => {
      if (isOpen) {
        return new Close();
      } else {
        return new Open();
      }
    })
  );
  private _closeAction$ = this.onClose$.pipe(
    withLatestFrom(this.open$),
    filter(([ _, isOpen ]) => isOpen),
    map(() => new Close())
  );
  private _toggleWatchTicketAction$ = this.onToggleWatchTicket$.pipe(
    withLatestFrom(
      this.identity$,
      this.userIsWatchingTicket$,
      this.detail$
    ),
    map(([ _, user, userIsWatchingTicket, detail ]) => {
      if (!!user && !!detail) {

        if (userIsWatchingTicket) {
          return new TicketsBaseUpdateCopyListRequest(
            detail.id,
            detail.copyList.filter((m) => m !== user.email),
            user.email,
            'remove',
            true
          );
        } else {
          return new TicketsBaseUpdateCopyListCheckRequest(
            detail.id,
            [ ...detail.copyList, user.email ],
            user.email,
            true
          );
        }

      }
    })
  );
  private _resetFileQueueAction$ = this.selectedTopicId$.pipe(
    distinctUntilChanged(),
    map(() => new FilesEmptyQueue(TicketAddModuleTokens.Name))
  );

  private _defaultPopOffset = 150;

  constructor(
    public media: MediaObserver,
    private _store: Store<State>,
    private _translate: TranslateService,
    private _ruler: ViewportRuler
  ) {
    super();

    // # Data Resolvers
    this.height$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe((height) => this.height = height);

    this.detail$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe((data) => this.detail = data);

    // scroll to bottom of detail when we are on detail
    // and ticketmessages are loaded
    this.detail$
      .pipe(
        filter((d) => !!(d
          && d.ticketMessageList
          && d.ticketMessageList.length)
        ),
        takeUntil(this._ngOnDestroy$)
      )
      .subscribe((_d) => {
        // intentional to trigger immediately on new message add
        if (this.ticketDetailRef) {
          this.ticketDetailRef.scrollTo('bottom', 0);
        }

        setTimeout(() => {
          if (this.ticketDetailRef) {
            this.ticketDetailRef.scrollTo('bottom', 0);
          }
        });
      });

    // # Store Dispatcher
    merge(
      this._setStateAction$,
      this._sendMessageAction$,
      this._unlockAction$,
      this._resetAction$,
      this._toggleAction$,
      this._closeAction$,
      this._toggleWatchTicketAction$,
      this._resetFileQueueAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }

  setSizes(_taSize: number) {
    if (this.formRef) {
      setTimeout(() => {
        this.detailMessageHeight = this.formRef.nativeElement.clientHeight;
      });
    }
  }

  preventFocusRestore(popover: SatPopover) {
    popover['_previouslyFocusedElement'] = null;
  }

  @HostListener('document:keydown.esc')
  onDocumentKeydownEsc() {
    this.onClose$.next();
  }

}
