import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Directive,
  ContentChild,
  TemplateRef,
  OnChanges
} from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { Selection } from './filter-pop-multiselect.model';
import { FilterPopItem } from '../../filter-pop.model';

interface ItemGroup {
  key?: string;
  items: FilterPopItem[];
}

@Directive({
  selector: '[vshczFilterPopMultiselectItem]'
})
export class FilterPopMultiselectItemDirective { }

@Directive({
  selector: '[vshczFilterPopMultiselectGroup]'
})
export class FilterPopMultiselectGroupDirective { }

@Component({
  selector: 'vshcz-filter-pop-multiselect',
  templateUrl: './filter-pop-multiselect.component.html',
  styleUrls: [ './filter-pop-multiselect.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPopMultiselectComponent implements AfterViewInit, OnChanges {
  // # Data
  // -- sync
  groupedItems: ItemGroup[];

  // -- angular
  @Input()
  maxHeight: number;

  @Input()
  set items(v) {
    this._items = v;

    if (v) {
      const map = {};

      this.groupedItems = v.reduce((arr, item) => {
        const g = item.group ? item.group : '_';

        if (map[g] === undefined) {
          map[g] = arr.length;

          arr.push({
            key: g,
            items: []
          });
        }

        arr[map[g]].items.push(item);

        return arr;

      }, []);

    } else {
      this.groupedItems = undefined;
    }
  }
  get items() {
    return this._items;
  }

  @Input()
  checkboxPosition = 'after';

  @Input()
  set selected(v) {
    this._selected = v;

    this._selectOptions(this._selected);
  }

  get selected() {
    return this._selected;
  }

  @Output()
  selection = new EventEmitter<Selection>();

  @ViewChild(MatSelectionList, { static: true })
  selectionRef: MatSelectionList;

  @ContentChild(FilterPopMultiselectItemDirective, { read: TemplateRef, static: true })
  set itemTemplate(v) {
    this._itemTemplate = v;
  }
  get itemTemplate() { return this._itemTemplate; }

  @ContentChild(FilterPopMultiselectGroupDirective, { read: TemplateRef, static: true })
  set groupTemplate(v) {
    this._groupTemplate = v;
  }
  get groupTemplate() { return this._groupTemplate; }

  private _selected: string[] = [];
  private _items: FilterPopItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _itemTemplate: TemplateRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _groupTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    this._selectOptions(this._selected);
  }

  ngOnChanges() {
    this._selectOptions(this._selected);
  }

  onSelect(e: MatSelectionListChange) {
    this.selection.emit({
      value: e.options[0].value,
      state: e.options[0].selected
    });
  }

  trackBy(index: number) {
    return index;
  }

  private _selectOptions(values: string[]) {
    if (this.selectionRef && this.selectionRef.options) {
      setTimeout(() => {
        this.selectionRef.options.forEach((itemRef) => {
          if (values.indexOf(itemRef.value) >= 0) {
            if (!itemRef.selected) {
              itemRef.toggle();
            }
          } else {
            if (itemRef.selected) {
              itemRef.toggle();
            }
          }
        });
      });
    }
  }
}
