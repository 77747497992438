import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import * as RouterAction from './ngrx-router.action';

@Injectable()
export class NgrxRouterEffect {

  private _onNavigate$ = createEffect(() => this._actions$.pipe(
    ofType<RouterAction.Go>(RouterAction.ActionTypes.Go),
    map((action) => action.payload),
    tap(({ path, query: queryParams, extras }) => this._router.navigate(
      path,
      { queryParams, ...extras }
    ))
  ), { dispatch: false });

  private _onNavigateBack$ = createEffect(() => this._actions$.pipe(
    ofType<RouterAction.Back>(RouterAction.ActionTypes.Back),
    tap(() => this._location.back())
  ), { dispatch: false });

  private _onNavigateForward$ = createEffect(() => this._actions$.pipe(
    ofType<RouterAction.Forward>(RouterAction.ActionTypes.Forward),
    tap(() => this._location.forward())
  ), { dispatch: false });

  constructor(
    private _router: Router,
    private _actions$: Actions,
    private _location: Location
  ) { }
}
