import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { getDialogState } from '@zerops/fe/dialog';
import { Observable, Subject, merge } from 'rxjs';
import { ObservableInput } from 'observable-input';
import {
  switchMap,
  map,
  filter,
  withLatestFrom,
  takeUntil
} from 'rxjs/operators';
import {
  getTicketEntityById,
  TicketsBaseUpdateCopyListRequest,
  TicketsBaseActionTypes,
  TicketsBaseUpdateCopyListCheckRequest
} from '@app/base/tickets-base';
import { State } from '@app/models';
import { authActiveUserNormalizedClient, identity } from '@app/base/auth-base';
import { emailFormGenerateTranslationKeys } from '@app/common/email-form';
import { ticketCopyListFormState } from './ticket-copy-list.selector';

@Component({
  selector: 'vshcz-ticket-copy-list',
  templateUrl: './ticket-copy-list.container.html',
  styleUrls: [ './ticket-copy-list.container.scss' ]
})
export class TicketCopyListContainer extends BaseClass {
  // # Form States
  formState$ = this._store.pipe(select(ticketCopyListFormState));

  // # Event Streams
  onRemove$ = new Subject<string>();
  onCheck$ = new Subject<void>();
  onAdd$ = new Subject<void>();

  // # Data
  // -- sync
  transKeys = emailFormGenerateTranslationKeys('ticketCopyList');
  addRequestKey = TicketsBaseActionTypes.UpdateCopyListRequest;
  checkRequestKey = TicketsBaseActionTypes.UpdateCopyListCheckRequest;
  addErrorKey = TicketsBaseActionTypes.UpdateCopyListFail;

  // -- angular
  @ObservableInput()
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('id')
  id$!: Observable<string>;

  // -- async
  addRequestKey$ = this.id$.pipe(
    filter((id) => !!id),
    map((id) => `${this.addRequestKey}:${id}`)
  );
  addCheckRequestKey$ = this.id$.pipe(
    filter((id) => !!id),
    map((id) => [
      `${this.addRequestKey}:${id}`,
      `${this.checkRequestKey}:${id}`
    ])
  );
  addErrorKey$ = this.id$.pipe(
    filter((id) => !!id),
    map((id) => `${this.addErrorKey}:${id}`)
  );
  ticket$ = this.id$.pipe(switchMap((id) => this._store.pipe(
    select(getTicketEntityById(id)),
    filter((t) => !!t)
  )));
  data$ = this.id$.pipe(switchMap((id) => this._store.pipe(
    select(getTicketEntityById(id)),
    filter((t) => !!t),
    map((ticket) => ticket.copyList)
  )));
  clientUserClient$ = this._store.pipe(
    select(authActiveUserNormalizedClient),
    filter((c) => !!c)
  );
  secureCommunicationDialogOpen$ = this._store.pipe(
    select(getDialogState(this.checkRequestKey)),
    map((s) => s.state)
  );
  companyName$ = this._store.pipe(
    select(authActiveUserNormalizedClient),
    filter((c) => !!c),
    map((c) => c.companyName)
  );
  identity$ = this._store.pipe(select(identity));

  // # Action Streams
  private _checkAction$ = this.onCheck$.pipe(
    withLatestFrom(
      this.formState$,
      this.data$,
      this.id$,
      this.formState$.pipe(map((s) => s.value.email))
    ),
    filter(([ _, state ]) => state.isValid),
    map(([ _, __, data, id, mail ]) => new TicketsBaseUpdateCopyListCheckRequest(
      id,
      [ ...data, mail ],
      mail
    ))
  );
  private _addAction$ = this.onAdd$.pipe(
    withLatestFrom(
      this.formState$,
      this.data$,
      this.id$,
      this.formState$.pipe(map((s) => s.value.email))
    ),
    map(([ _, __, data, id, mail ]) => new TicketsBaseUpdateCopyListRequest(
      id,
      [ ...data, mail ],
      mail,
      'add'
    ))
  );
  private _removeAction$ = this.onRemove$.pipe(
    withLatestFrom(this.data$, this.id$),
    map(([ removedMail, data, id ]) => new TicketsBaseUpdateCopyListRequest(
      id,
      data.filter((m) => m !== removedMail),
      removedMail,
      'remove'
    ))
  );

  constructor(private _store: Store<State>) {
    super();

    // # Action Dispatcher
    merge(
      this._checkAction$,
      this._addAction$,
      this._removeAction$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }

}
