import { normalize, denormalize } from 'normalizr';
import { unbox, ValidationErrors } from 'ngrx-forms';
import { domainSchema, domainListSchema } from '@app/schemas';
import {
  DomainEntityNormalizedData,
  DomainEntityLight,
  DomainEntity,
  DomainRecordSimpleBase
} from './domains-base.model';
import { ModuleTokens } from './domains-base.constant';
import orderBy from 'lodash-es/orderBy';
import { DomainRecordChangeStatus } from './domain-record-base.model';

export function normalizeList(data: DomainEntity[] | DomainEntityLight[]): DomainEntityNormalizedData {
  return normalize(data, domainListSchema);
}

export function denormalizeEntity(
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domainsEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domainActiveRecordsEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domainLastRecordsEntities: any
) {
  return denormalize(
    id,
    domainSchema,
    {
      [ModuleTokens.Name]: domainsEntities,
      [ModuleTokens.ActiveRecords]: domainActiveRecordsEntities,
      [ModuleTokens.LastRecords]: domainLastRecordsEntities
    }
  );
}

export function denormalizeList(
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domainsEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domainActiveRecordsEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  domainLastRecordsEntities: any
) {
  return denormalize(
    ids,
    domainListSchema,
    {
      [ModuleTokens.Name]: domainsEntities,
      [ModuleTokens.ActiveRecords]: domainActiveRecordsEntities,
      [ModuleTokens.LastRecords]: domainLastRecordsEntities
    }
  );
}

export function inRange(x: number, y: number) {
  if ((x === null || y === null) || (x === undefined || y === undefined)) {
    throw new Error(`The inRange Validation function requires the x & y parameters to be a non-null numbers!`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value: number): ValidationErrors | { inRange: any } => {
    value = unbox(value);

    if (value === null || value === undefined) {
      return {};
    }

    if (value >= x && value <= y && Number.isInteger(value)) {
      return {};
    }

    return {
      inRange: {
        x,
        y,
        actual: value as number
      }
    };
  };
}

export const getLastDomainRecordListWithChanges = (
  lastDomainRecordList: DomainRecordSimpleBase[],
  activeDomainRecordMap: { [key: string]: DomainRecordSimpleBase; },
  checkForDeleted = false
) => {
  const active = activeDomainRecordMap;

  let listWithChanges = lastDomainRecordList.map((item) => {
    let _status: DomainRecordChangeStatus;
    let _activeRecord: { value: string, name: string; };

    if (item.status === 'DELETED') {
      _status = 'DELETED';
    } else if (!item.originalDomainRecordId) {
      _status = 'INSERTED';
    } else if (item.originalDomainRecordId) {
      const activeEntity = activeDomainRecordMap[item.originalDomainRecordId];

      if ((!!activeEntity && item.value !== activeEntity.value)
        || (!!activeEntity && item.name !== activeEntity.name)) {
        _status = 'UPDATED';
        _activeRecord = {
          value: activeEntity.value,
          name: activeEntity.name
        };
      } else {
        _status = 'ACTIVE';
      }
    }

    if (checkForDeleted) {
      if (active[item.originalDomainRecordId]) {
        delete active[item.originalDomainRecordId];
      }
    }

    return {
      ...item,
      _status,
      _activeRecord
    };

  });

  if (checkForDeleted) {
    const deletedArr = Object.values(active).map((item) => (
      {
        ...item,
        _status: 'DELETED',
        _activeRecord: undefined
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )) as any[];
    listWithChanges = listWithChanges.concat(deletedArr);
  }

  return orderBy(listWithChanges, [ 'type', 'name', 'value' ], [ 'asc', 'asc', 'asc' ]);
};
