import { Actions } from 'ngrx-forms';
import { applyEntities } from '@zerops/fe/core';
import { ModuleTokens } from './client-user-base.constant';
import { ClientUserBaseState } from './client-user-base.model';
import {
  Actions as ClientUserBaseActions,
  ActionTypes
} from './client-user-base.action';

const initialState = new ClientUserBaseState();

export function reducer(
  state = initialState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: ClientUserBaseActions | Actions<any>
): ClientUserBaseState {
  state = applyEntities<ClientUserBaseState>(
    state,
    [ ModuleTokens.Name ],
    action
  );

  if (action.type === ActionTypes.ListLocalSuccess) {
    return {
      ...state,
      list: action.payload.result
    };
  }

  if (action.type === ActionTypes.AddToList) {
    return {
      ...state,
      list: [
        action.payload,
        ...state.list
      ]
    };
  }

  return state;
}
