import { NgModule,  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SatPopoverModule } from '@zerops/fe/popover';
import { TicketStateModule } from '@app/common/ticket-state';
import {
  FilterPopButtonComponent,
  FilterPopButtonGroupDirective,
  FilterPopButtonItemDirective
} from './filter-pop-button.component';

@NgModule({
  declarations: [
    FilterPopButtonComponent,
    FilterPopButtonGroupDirective,
    FilterPopButtonItemDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    SatPopoverModule,
    TicketStateModule
  ],
  exports: [
    FilterPopButtonComponent,
    FilterPopButtonGroupDirective,
    FilterPopButtonItemDirective
  ]
})
export class FilterPopButtonModule {

}
