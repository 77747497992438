import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  map,
  switchMap,
  withLatestFrom,
  catchError,
  mergeMap
} from 'rxjs/operators';
import { State } from '@app/models';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId } from '../auth-base';
import {
  ListRequest,
  ActionTypes,
  ListLocalSuccess,
  ListFail,
  EntityRequest,
  EntityLocalSuccess,
  EntityFail,
  GraphRequest,
  GraphLocalSuccess,
  GraphFail,
  IpRangeRequest,
  IpRangeLocalSuccess,
  IpRangeFail
} from './servers-base.action';
import { ServersBaseApi } from './servers-base.api';

@Injectable()
export class ServersBaseEffect {

  private _onEntityRequest$ = createEffect(() => this._actions$.pipe(
    ofType<EntityRequest>(ActionTypes.EntityRequest),
    switchMap(({ payload }) => this._api
      .entity$(payload)
      .pipe(
        map((response) => new EntityLocalSuccess(response, payload)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new EntityFail(data, payload)))
        )
      )
    )
  ));

  private _onGraphRequest$ = createEffect(() => this._actions$.pipe(
    ofType<GraphRequest>(ActionTypes.GraphRequest),
    mergeMap(({ payload }) => this._api
      .graph$(payload.id, payload.graphType, payload.graphInterval)
      .pipe(
        map((response) => new GraphLocalSuccess(
          response.serverGraphList,
          payload.id,
          payload.graphType
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new GraphFail(data, payload.id)))
        )
      )
    )
  ));

  private _onListRequest$ = createEffect(() => this._actions$.pipe(
    ofType<ListRequest>(ActionTypes.ListRequest),
    withLatestFrom(this._store.pipe(select(authActiveUserClientId))),
    switchMap(([ _, clientId ]) => this._api
      .list$(clientId)
      .pipe(
        map(({ serverList }) => new ListLocalSuccess(serverList)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new ListFail(data)))
        )
      )
    )
  ));

  private _onIpRangeRequest$ = createEffect(() => this._actions$.pipe(
    ofType<IpRangeRequest>(ActionTypes.IpRangeRequest),
    switchMap(({ payload }) => this._api
      .ipRange$(payload)
      .pipe(
        map((response) => new IpRangeLocalSuccess(response)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new IpRangeFail(data)))
        )
      )
    )
  ));

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: ServersBaseApi,
    private _errorTranslation: ErrorTranslationService
  ) { }
}
