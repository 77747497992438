import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { authActiveUserClientId } from '@app/base/auth-base';
import { State } from '@app/models';
import {
  FilesUploadRequest,
  FilesUploadSingleFail,
  FilesActionTypes,
  FilesUploadEmpty,
  FilesAllUploadLocalSuccess,
  FilesEmptyQueue
} from '@app/common/files';
import { ClearUploadedFiles } from '@app/common/files/files.action';
import { TicketAddApi } from './ticket-add.api';
import {
  ActionTypes,
  AddRequest,
  AddLocalSuccess,
  AddFail,
  Reset
} from './ticket-add.action';
import { ticketAddFormValue } from './ticket-add.selector';
import { ModuleTokens } from './ticket-add.constant';

@Injectable()
export class TicketAddEffect {

  private _onAddRequest$ = this._actions$.pipe(
    ofType<AddRequest>(ActionTypes.AddRequest)
  );

  private _onTicketAddUpload$ = createEffect(() => this._onAddRequest$.pipe(
    map(() => new FilesUploadRequest(ModuleTokens.Name))
  ));

  private _onTicketAddUploadFail$ = createEffect(() => this._onTicketAddUpload$.pipe(
    switchMap(({ payload }) => this._actions$.pipe(
      ofType<FilesUploadSingleFail>(FilesActionTypes.UploadSingleFail),
      filter((action) => action.payload.instanceId === payload),
      map(({ meta }) => meta)
    )),
    map((meta) => new AddFail(meta))
  ));

  private _onTicketAddRequest$ = createEffect(() => this._onAddRequest$.pipe(
    switchMap(() => this._actions$.pipe(
      ofType<FilesAllUploadLocalSuccess | FilesUploadEmpty>(
        FilesActionTypes.UploadAllLocalSuccess,
        FilesActionTypes.UploadEmpty
      ),
      filter(({ payload }) => payload.instanceId === ModuleTokens.Name),
      map((action) => action.type === FilesActionTypes.UploadAllLocalSuccess
        ? action.payload.fileIds
        : []
      )
    )),
    withLatestFrom(
      this._store.pipe(select(ticketAddFormValue)),
      this._store.pipe(select(authActiveUserClientId))
    ),
    switchMap(([ attachments, formVals, clientId ]) => this._api
      .add$(
        {
          ...formVals,
          attachments
        },
        clientId
      )
      .pipe(
        map((response) => new AddLocalSuccess(response)),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new AddFail(data)))
        )
      )
    )
  ));

  // reset uploaded files on add success
  private _onAddLocalSuccess$ = createEffect(() => this._actions$.pipe(
    ofType<AddLocalSuccess>(ActionTypes.AddLocalSuccess),
    map(() => new ClearUploadedFiles(ModuleTokens.Name))
  ));

  // empty upload queue when resetting state
  private _onReset$ = createEffect(() => this._actions$.pipe(
    ofType<Reset>(ActionTypes.Reset),
    map(() => new FilesEmptyQueue(ModuleTokens.Name))
  ));

  constructor(
    private _actions$: Actions,
    private _store: Store<State>,
    private _api: TicketAddApi,
    private _errorTranslation: ErrorTranslationService
  ) { }
}
