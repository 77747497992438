export class WebsocketsState {
  reconnecting: boolean;

  constructor() {
    this.reconnecting = false;
  }
}

export interface ParsedFrame {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any;
}

export enum WebsocketStatuses {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}
