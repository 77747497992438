import { Injectable } from '@angular/core';
import { Observable, Subject, fromEvent, merge, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  globalNamespace = 'vsh:';

  onSet$ = new Subject<void>();
  storage$ = merge(
    timer(0).pipe(take(1)),
    fromEvent(window, 'storage'),
    this.onSet$
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(namespace: string, key: string, value: any) {
    localStorage.setItem(
      this.globalNamespace + namespace + `:` + key,
      JSON.stringify(value)
    );

    this.onSet$.next();
  }

  update<T>(namespace: string, key: string, update: (value: T) => T, defaultValue?: T): T {
    const newValue = update(this.get(namespace, key, defaultValue));
    this.set(namespace, key, newValue);
    return newValue;
  }

  getNamespace<T>(namespace: string): { [key: string ]: T } {
    const result = {};
    for (let index = 0; index < localStorage.length; index++) {
      const prefix = this.globalNamespace + namespace + ':';
      const key = localStorage.key(index);
      const valueKey = key.substr(prefix.length, key.length);
      if (key.startsWith(prefix)) {
        try {
          result[valueKey] = <T>JSON.parse(localStorage.getItem(key));
        } catch (e) {
        }
      }
    }
    return result;
  }

  get<T>(namespace: string, key: string, defaultValue?: T): T {
    const value = localStorage.getItem(this.globalNamespace + namespace + `:` + key);
    if (!value) {
      return defaultValue;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      return defaultValue;
    }
  }

  get$<T>(namespace: string, key: string, defaultValue?: T): Observable<T> {

    return this.storage$.pipe(
      map(() => localStorage.getItem(this.globalNamespace + namespace + `:` + key)),
      map((v) => {
        if (!v) {
          return defaultValue;
        }
        try {
          return JSON.parse(v);
        } catch (e) {
          return defaultValue;
        }
      })
    );
  }
}
