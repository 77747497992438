<div
  class="__search"
  @search
  cdkTrapFocus
  *ngIf="(open$ | async) as open">
  <div class="__search_content">

    <button
      class="__close-button"
      type="button"
      (click)="onSearchClose$.next()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>

    <vshcz-wrap class="__wrap">

      <vshcz-form class="__form">
        <vshcz-form-field last class="__form-field-holder">
          <mat-form-field
            class="__form-field"
            color="accent"
            appearance="standard">

            <mat-icon class="__input-prefix-icon" matPrefix>search</mat-icon>

            <input
              matInput
              autocomplete="off"
              [placeholder]="'search.inputPlaceholder' | translate"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
              (blur)="onBlur()"
              (focus)="onFocus()"
              [ngrxEnableFocusTracking]="true"
              [ngrxFormControlState]="(formState$ | async)?.controls.keyword"
              type="text"
            />

          </mat-form-field>
        </vshcz-form-field>

        <div class="__form-actions">

          <ng-container *ngIf="(searchNotRunning$ | async); else searchRunningRef">
            <button
              *ngIf="keywordFilled$ | async"
              class="__search-button"
              (click)="onSearch$.next()"
              type="button"
              mat-icon-button>
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </ng-container>

          <ng-template #searchRunningRef>
            <mat-spinner
              class="__search-spinner"
              color="accent"
              [diameter]="22">
            </mat-spinner>
          </ng-template>

        </div>

      </vshcz-form>

    </vshcz-wrap>

    <div [ngSwitch]="mode$ | async">

      <!-- search -->
      <div *ngSwitchCase="modes.Suggest">

        <vshcz-empty-state
          class="__empty-state"
          *ngIf="(suggestEmptyStateShown$ | async) else hasSuggestResultsRef"
          [heading]="'search.emptySuggestStateHeading' | translate"
          [desc]="'search.emptyStateDesc' | translate: { keyword: keyword$ | async } ">
        </vshcz-empty-state>

        <ng-template #hasSuggestResultsRef>
          <vshcz-wrap class="__wrap" *ngIf="(suggests$ | async) as suggests">

            <div class="__hint" *ngIf="suggests.length">

              <span translate="search.navigation.start"></span>

              <vshcz-key-chip><mat-icon>keyboard_arrow_down</mat-icon>

              </vshcz-key-chip><span translate="search.navigation.or"></span>

              <vshcz-key-chip><mat-icon>keyboard_arrow_up</mat-icon></vshcz-key-chip>

              <span translate="search.navigation.middle"></span>

              <vshcz-key-chip>return <mat-icon>keyboard_return</mat-icon></vshcz-key-chip>

              <span translate="search.navigation.end"></span>

            </div>

            <div class="__results">
              <div *ngFor="let item of suggests; let index = index; trackBy: trackBy">
                <vshcz-search-item
                  [currencyMap]="currencyMap"
                  (clicked)="onSearchClose$.next()"
                  [entityTranslations]="entitiesTranslations"
                  [isActive]="focused && index === activeIndex"
                  [data]="item">
                </vshcz-search-item>
              </div>
            </div>

          </vshcz-wrap>
        </ng-template>

      </div>

      <!-- search -->
      <div *ngSwitchCase="modes.Search">
        <vshcz-empty-state
          class="__empty-state"
          *ngIf="(searchEmptyStateShown$ | async) else hasSearchResultsRef"
          [heading]="'search.emptyStateHeading' | translate"
          [desc]="'search.emptyStateDesc' | translate: { keyword: keyword$ | async }">
        </vshcz-empty-state>

        <ng-template #hasSearchResultsRef>
          <vshcz-wrap>
            <vshcz-search-table
              class="__table"
              (sorted)="sort$.next($event)"
              (clicked)="onSearchClose$.next()"
              [entityTranslations]="entitiesTranslations"
              [displayedColumns]="displayedSearchColumns"
              [data]="searches$ | async">
            </vshcz-search-table>
          </vshcz-wrap>
        </ng-template>
      </div>

    </div>

  </div>
</div>
