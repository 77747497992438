<button
  mat-button
  class="__button"
  [class.is-active]="!!items.length"
  [class.has-reset-disabled]="disableReset"
  [class.is-open]="!!anchorForRef.isOpen()"
  [zpsSatPopoverAnchor]="anchorForRef"
  type="button"
  (click)="anchorForRef.toggle()">

  <div class="__item">
    <ng-content></ng-content>

    <!-- inactive -->
    <span  *ngIf="!items.length; else activeRef">
      <span [innerHTML]="placeholder"></span>
      <mat-icon class="__select-arrow">arrow_drop_down</mat-icon>
    </span>

    <!-- active -->
    <ng-template #activeRef>

      <span>{{ prefix }} </span>

      <!-- has group handler -->
      <ng-container *ngIf="groupTemplate">
        <ng-template [ngTemplateOutlet]="groupTemplate"></ng-template>
      </ng-container>

      <!-- does not have group handler -->
      <ng-container *ngIf="!groupTemplate">

        <strong class="__filter-pop-active-button-item" *ngFor="let item of items; let last = last">
          <ng-template
            [ngTemplateOutletContext]="{ $implicit: item }"
            [ngTemplateOutlet]="itemTemplate"></ng-template><span *ngIf="!last">, </span>
        </strong>

        <strong class="__extras" *ngIf="extras"> +{{ extras }}</strong>

      </ng-container>

    </ng-template>
  </div>
</button>

<button
  mat-icon-button
  (click)="!!reset.emit()"
  *ngIf="items.length && !disableReset"
  class="__reset-button">
  <mat-icon class="__reset-button_icon">highlight_off</mat-icon>
</button>
