<div
  fxLayout="row wrap"
  fxLayoutAlign="center stretch">

  <div [fxFlex]="mode === 'vertical' ? 100 : '250px'">
    <div
      class="__company"
      *ngIf="(company$ | async) as company">
      <vshcz-avatar
        [size]="100"
        [web]="company.webUrl"
        [source]="'clearbit'"
        class="__company_avatar">
      </vshcz-avatar>

      <ng-template #companyButtonRef>
        <button
          mat-button
          class="__company_button"
          type="button"
          [zpsSatPopoverAnchor]="popRef"
          (click)="popRef.open(); companySelectToggle.emit(true)"
          *ngIf="(userCompanies$ | async)?.length > 1">

          <h2
            class="__company_button_title"
            [matTooltip]="company.companyName"
            mat-button>
            {{ company.companyName }}
          </h2>

          <mat-icon style="vertical-align: middle;">arrow_drop_down</mat-icon>

        </button>
      </ng-template>

      <ng-template #companyNameRef>
        <h2
          class="__company_name"
          [matTooltip]="company.companyName">
          {{ company.companyName }}
        </h2>
      </ng-template>

      <ng-container [ngTemplateOutlet]="(userCompanies$ | async)?.length > 1
        ? companyButtonRef
        : companyNameRef">
      </ng-container>

      <div
        class="__company_actions"
        *ngIf="isManager$ | async">

        <div>
          <a
            class="__company_edit-button"
            (click)="contentClicked.emit()"
            [routerLink]="[ '/company' ]"
            mat-stroked-button
            color="accent">
            <span translate="companyCard.editButton"></span>
          </a>
        </div>

        <div>
          <a
            class="__company_edit-button  __company_edit-button--access"
            [routerLink]="[ '/server-park-access/list' ]"
            mat-button
            (click)="contentClicked.emit()"
            *ngIf="company.hasAccessToServerPark">
            <mat-icon
              color="accent"
              class="__company_edit-button--access_icon">
              perm_contact_calendar
            </mat-icon>
            <span translate="companyCard.serverParkAccess"></span>
          </a>
        </div>

      </div>
    </div>
  </div>

  <div [fxFlex]="mode === 'vertical' ? 100 : '1px'" class="__separator"></div>

  <div [fxFlex]="mode === 'vertical' ? 100 : ''">
    <div class="__content">

      <div class="__info-item">

       <h5 class="c-button-title  __sub-title">

          <span
            class="c-button-title__text"
            translate="companyCard.teamMembers">
          </span>

          <div class="c-button-title__buttons">

            <a
              mat-stroked-button
              color="accent"
              (click)="contentClicked.emit()"
              class="[ c-button-title__button  c-button-title__button--icon ]  __team-add-button"
              [routerLink]="[ '/team', 'add' ]"
              *ngIf="isManager$ | async">
              <mat-icon>add</mat-icon>
            </a>

            <a
              [routerLink]="[ '/team', 'list' ]"
              mat-stroked-button
              (click)="contentClicked.emit()"
              color="accent"
              class="c-button-title__button">
              <span translate="companyCard.seeList"></span>
            </a>

          </div>

        </h5>

        <vshcz-avatar-group
          class="__team"
          [max]="8"
          (itemClicked)="contentClicked.emit(); onRoute$.next([ '/team', 'edit', $event ])"
          (overflowClicked)="onRoute$.next([ '/team', 'list' ])"
          [data]="avatarGroupData$ | async">
        </vshcz-avatar-group>

     </div>

     <!-- <div class="__info-item" >
        <h5 class="c-button-title  __sub-title">
          <span
            class="c-button-title__text"
            translate="companyCard.youAndMe">
          </span>
        </h5>

        <p [innerHTML]="'companyCard.bio' | translate: {
          days: 378,
          serviceCount: 4,
          instances: 35,
          monthlySpend: '16850 czk'
        }">
        </p>
     </div> -->

    </div>
  </div>

</div>

<zps-sat-popover
  horizontalAlign="center"
  verticalAlign="below"
  #popRef
  hasBackdrop
  (afterClose)="companySelectToggle.emit(false)"
  (backdropClicked)="popRef.close()">
  <mat-card class="__pop-card  mat-elevation-z7">
    <vshcz-company-select
      class="__company-select"
      [clientUserList]="userCompanies$ | async"
      [activeId]="activeCompanyId$ | async"
      (companySelect)="onChangeClient$.next($event); popRef.close(); contentClicked.emit()">
    </vshcz-company-select>
  </mat-card>
</zps-sat-popover>
