import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://6143fc2c95884286a3b13589c3ebdd7c@sentry-ng.vshosting.cz/5',
  release: environment.release,
  environment: environment.envName
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  handleError(error) {
    Sentry.captureException(error.originalError || error);
    // throw error;
  }
}

export const sentryErrorProvider = [{
  provide: ErrorHandler,
  useClass: SentryErrorHandler
}];
