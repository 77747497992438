<div
  class="__layout"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="12px grid">

  <div fxFlex="none" class="__item">
    <vshcz-avatar
      class="__avatar"
      source="gravatar"
      [mail]="mail"
      size="40">
    </vshcz-avatar>
  </div>

  <div fxFlex="200px" class="__item">

    <div class="__row">
      <h3 class="__name">{{ fullName }}</h3>
    </div>

    <div class="__row">

      <span class="__company">{{ company }}</span>

      <span class="__position">, {{ position }}</span>

    </div>

  </div>

</div>
