import { normalize, denormalize } from 'normalizr';
import orderBy from 'lodash-es/orderBy';
import { invoicesListSchema, invoicesSchema } from '@app/schemas';
import {
  FeeLight,
  InvoiceEntity,
  InvoiceEntityLight
} from './invoices-base.model';
import {
  ModuleTokens,
  INVOICE_ORDER_PRIORITY_MAP,
  FEE_ORDER_PRIORITY_MAP
} from './invoices-base.constant';

export function normalizeList(data: InvoiceEntity[] | InvoiceEntityLight[]) {
  return normalize(data, invoicesListSchema);
}

export function denormalizeList(
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoicesEntities: any
) {
  return denormalize(
    ids,
    invoicesListSchema,
    {
      [ModuleTokens.Name]: invoicesEntities
    }
  );
}

export function denormalizeEntity(
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoicesEntities: any
) {
  return denormalize(
    id,
    invoicesSchema,
    {
      [ModuleTokens.Name]: invoicesEntities
    }
  );
}

export const orderInvoices = <T extends InvoiceEntity | InvoiceEntityLight>(
  invoices: T[],
  orderByDue = true,
  dueDateDesc: 'asc' | 'desc' = 'desc'
) => {
  const orderKeys = orderByDue
    ? [ 'dueDate' ]
    : [ 'number' ];
  const orderOrder: Array<'asc' | 'desc'> = orderByDue
    ? [ dueDateDesc ]
    : [ 'desc' ];

  return orderBy(
    invoices,
    [ (i) => INVOICE_ORDER_PRIORITY_MAP[i.status], ...orderKeys ],
    [ 'desc', ...orderOrder ]
  ) as T[];

};

export const orderFees = <T extends FeeLight>(
  fees: T[],
  orderByDue = true,
  dueDateDesc: 'asc' | 'desc' = 'desc'
) => {
  const orderKeys = [ 'number' ];
  const orderOrder: Array<'asc' | 'desc'> = orderByDue
    ? [ dueDateDesc ]
    : [ 'desc' ];

  return orderBy(
    fees,
    [ (i) => FEE_ORDER_PRIORITY_MAP[i.type], ...orderKeys ],
    [ 'desc', ...orderOrder ]
  ) as T[];

};
