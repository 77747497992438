import { normalize, denormalize } from 'normalizr';
import {
  ModuleTokens as ClientUserBaseModuleTokens
} from '@app/base/client-user-base/client-user-base.constant';
import { ClientsBaseModuleTokens } from '@app/base/clients-base';
import { ServicesBaseModuleTokens } from '@app/base/services-base';
import { userListSchema, userSchema } from '@app/schemas';
import { UserEntity, UserLightEntity } from './users-base.model';
import { ModuleTokens } from './users-base.constant';

export function normalizeList(data: UserEntity[] | UserLightEntity[]) {
  return normalize(data, userListSchema);
}

export function denormalizeEntity(
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  usersEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientUserEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientsEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  servicesEntities: any
) {
  return denormalize(
    id,
    userSchema,
    {
      [ModuleTokens.Name]: usersEntities,
      [ClientUserBaseModuleTokens.Name]: clientUserEntities,
      [ClientsBaseModuleTokens.Name]: clientsEntities,
      [ServicesBaseModuleTokens.Name]: servicesEntities
    }
  );
}

export function denormalizeList(
  ids: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  usersEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientUserEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clientsEntities: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  servicesEntities: any
) {
  return denormalize(
    ids,
    userListSchema,
    {
      [ModuleTokens.Name]: usersEntities,
      [ClientUserBaseModuleTokens.Name]: clientUserEntities,
      [ClientsBaseModuleTokens.Name]: clientsEntities,
      [ServicesBaseModuleTokens.Name]: servicesEntities
    }
  );
}
