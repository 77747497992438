import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CountryFlagModule } from '@app/common/country-flag';
import { PhoneFormSelectOptionComponent } from './phone-form-select-option.component';

@NgModule({
  declarations: [ PhoneFormSelectOptionComponent ],
  imports: [
    FlexLayoutModule,
    CountryFlagModule
  ],
  exports: [ PhoneFormSelectOptionComponent ]
})
export class PhoneFormSelectOptionModule {

}
