import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogModule } from '@zerops/fe/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { PriceModule } from '../price';
import { BulkPaymentOverviewComponent } from './bulk-payment-overview.component';
import { BulkPaymentPriceModule } from '../bulk-payment-price';

@NgModule({
  declarations: [ BulkPaymentOverviewComponent ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    DialogModule,
    TranslateModule.forChild(),
    NgxQRCodeModule,
    PriceModule,
    BulkPaymentPriceModule
  ],
  exports: [ BulkPaymentOverviewComponent ]
})
export class BulkPaymentOverviewModule {
}
