import {
  trigger,
  style,
  animate,
  transition,
  AnimationTriggerMetadata
} from '@angular/animations';

export const ticketsTriggerAnimation: AnimationTriggerMetadata = trigger('ticketsTrigger', [
  transition(
    ':enter',
    [
      style({
        opacity: 0,
        transform: 'translateY(6px)'
      }),
      animate(
        '200ms ease-in-out',
        style({
          opacity: 1,
          transform: 'translateY(0)'
        })
      )
    ]
  ),
  transition(
    ':leave',
    [
      animate(
        '200ms ease-in-out',
        style({
          opacity: 0,
          transform: 'translateY(3px)'
        })
      )
    ]
  )
]);

export const ticketsTriggerCloseButton: AnimationTriggerMetadata = trigger('ticketsTriggerCloseButton', [
  transition(
    ':enter',
    [
      style({
        opacity: 0,
        transform: 'scale(0.6)'
      }),
      animate(
        '200ms 2s ease-in-out',
        style({
          opacity: 1,
          transform: 'scale(1)'
        })
      )
    ]
  ),
  transition(
    ':leave',
    [
      animate(
        '200ms ease-in-out',
        style({
          opacity: 0,
          transform: 'scale(0.6)'
        })
      )
    ]
  )
]);
