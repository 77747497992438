import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { SatPopover } from '@zerops/fe/popover';

@Component({
  selector: 'vshcz-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: [ './confirm.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmComponent {

  @Input()
  confirmButton: string;

  @Input()
  confirmIcon: string;

  @Input()
  confirmColor = 'primary';

  @Input()
  closeButton: string;

  @Input()
  heading: string;

  @Output()
  confirm = new EventEmitter<void>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  close = new EventEmitter<void>();

  @ViewChild(SatPopover, { static: true })
  popRef: SatPopover;
}
