import { TicketAddFormTranslations } from './ticket-add-form.model';

const _prefix = 'ticket-add-form_';

export const DEFAULT_TRANSLATION_KEYS: TicketAddFormTranslations = {
  labels: {
    title: _prefix + 'labels_title',
    ticketMessage: _prefix + 'labels_ticketMessage'
  },
  hints: {
    title: _prefix + 'hints_title',
    ticketMessage: _prefix + 'hints_ticketMessage'
  },
  errors: {
    title: {
      required: _prefix + 'errors_title_required'
    },
    ticketMessage: {
      required: _prefix + 'errors_ticketMessage_required'
    }
  }
};
