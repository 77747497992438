import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from '@app/common/avatar/avatar.module';
import { AvatarCardComponent } from './avatar-card.component';

@NgModule({
  declarations: [ AvatarCardComponent ],
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    AvatarModule
  ],
  exports: [ AvatarCardComponent ]
})
export class AvatarCardModule { }
