<div *ngIf="active" class="__price-offer">

  <span
    class="__price-offer-text"
    translate="ticketBase.attachedPriceOrderText">
  </span>

  <div>

    <vshcz-progress-item
      class="__price-offer-progress"
      [active]="priceOfferApproveLoading$ | async"
      [spinnerSize]="16">
      <button
        class="__price-offer-button"
        mat-button
        type="button"
        (click)="onApprovePriceOffer$.next()">
        <span translate="ticketBase.approve"></span>
      </button>
    </vshcz-progress-item>

    <vshcz-progress-item
      class="__price-offer-progress"
      [active]="priceOfferRejectLoading$ | async"
      [spinnerSize]="16">
      <button
        class="__price-offer-button"
        mat-button
        type="button"
        [zpsSatPopoverAnchor]="rejectPriceOfferPopRef"
        (click)="!!rejectPriceOfferPopRef.toggle()">
        <span translate="ticketBase.reject"></span>
      </button>
    </vshcz-progress-item>

  </div>

</div>

<!-- reject price offer pop -->
<zps-sat-popover
  #rejectPriceOfferPopRef
  hasBackdrop
  [autoFocus]="true"
  horizontalAlign="start"
  verticalAlign="below">
  <mat-card class="__pop mat-elevation-z5" *ngIf="(priceOfferRejectFormState$ | async) as formState">

    <h4 class="__pop-title" translate="ticketBase.rejectPriceOfferPopTitle"></h4>

    <vshcz-form
      *ngIf="formState?.controls"
      #rejectFormRef
      (submit)="onRejectPriceOffer$.next()"
      [state]="formState"
      preventEnterSubmit>

      <vshcz-price-offer-reject-form
        [state]="formState">
      </vshcz-price-offer-reject-form>

      <vshcz-progress-error
        [errorCloseLabel]="'common.close' | translate"
        [errorRetryLabel]="'common.retry' | translate"
        [errorShowDetailsLabel]="'common.showMore' | translate"
        [errorHideDetailsLabel]="'common.hideMore' | translate"
        [errorKey]="rejectPriceOfferFailKey$ | async"
        (retry)="rejectFormRef.triggerSubmit()"
        [progressKey]="rejectPriceOfferRequestKey$ | async"
        denseButton>
        <button
          mat-raised-button
          color="warn"
          type="submit"
          class="__button"
          [class.is-disabled]="!formState.controls.messageText.value">
          <mat-icon>cancel</mat-icon>&nbsp;
          <span translate="ticketBase.reject"></span>
        </button>
      </vshcz-progress-error>

    </vshcz-form>

  </mat-card>
</zps-sat-popover>

